import { apiTags } from "app/store/apiTags";
import { baseQuery } from "../../app/baseQuery";
import { baseApi } from 'app/baseApi';

const baseUrl = 'vendor';

export const vendorApi = baseApi.injectEndpoints({
    reducerPath: 'vendorApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getAllVendors: builder.query({
            query: () => `${baseUrl}`,
            providesTags: [apiTags.ACCESS, apiTags.VENDOR]
        }),
        getVendorsByClaims: builder.query({
            query: () => `${baseUrl}/byClaims`,
        }),
        getVendorsByLotId: builder.query({
            query: (lotId) => `${baseUrl}/getVendorsByLotId?lotId=${lotId}`,
        }),
        getVendorsLotsByLotId: builder.query({
            query: (lotId) => `${baseUrl}/getVendorsLotByLotId?lotId=${lotId}`,
        }),
    }),
});

export const { useGetAllVendorsQuery, useGetVendorsByClaimsQuery, useGetVendorsByLotIdQuery,  useGetVendorsLotsByLotIdQuery} = vendorApi;
