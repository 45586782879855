export const invoicePermissions = {
    EDIT_TRANSPORTATION_INVOICE: {
        key: 'invoices.edit.transportation',
        name: 'Edit Transportation Invoice',
    },
    APPROVE_DELETE_TRANSPORTATION_INVOICE: {
        key: 'invoices.approve_delete.transportation',
        name: 'Approve and delete Transportation Invoice',
    },
    READ_TRANSPORTATION_INVOICE: {
        key: 'invoices.read.transportation',
        name: 'Read Transportation Invoice',
    },
    EDIT_STORAGE_INVOICE: {
        key: 'invoices.edit.storage',
        name: 'Edit Storage Invoice',
    },
    APPROVE_DELETE_STORAGE_INVOICE: {
        key: 'invoices.approve_delete.storage',
        name: 'Approve and delete Storage Invoice',
    },
    READ_STORAGE_INVOICE: {
        key: 'invoices.read.storage',
        name: 'Read Storage Invoice',
    },
    EDIT_MECHANICAL_INVOICE: {
        key: 'invoices.edit.mechanical',
        name: 'Edit Mechanical Invoice',
    },
    APPROVE_DELETE_MECHANICAL_INVOICE: {
        key: 'invoices.approve_delete.mechanical',
        name: 'Approve and delete Mechanical Invoice',
    },
    READ_MECHANICAL_INVOICE: {
        key: 'invoices.read.mechanical',
        name: 'Read Mechanical Invoice',
    }
};