import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper
} from '@material-ui/core';
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { hasPermission } from 'components/Authorize/authorizeUtil';
import { TabsWrapper } from 'components/TabsWrapper/TabsWrapper';
import { selectUserId } from 'features/user/userSlice';
import { IconKey, IconLot, IconUser } from 'icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetClaimsByUserIdQuery } from '../../usersSlice';
import { useGetUserLotRelationsForUserByManagerQuery, useUpdateUserInformationManagerMutation } from "../userManagementSlice";
import UserTasksList from './UserTasks/UserTasksList';
import usersPageStyle from './UsersPageStyle';
import UserInformation from './userInformation/UserInformation';
import UserLotRelations from './userLotRelations/UserLotRelations';
import EditUserPermissions from './userPermissions/EditUserPermissions';
import UserRestrictions from './userRestrictions/UserRestrictions';
import { userManagementPermissions } from "../../../../components/Authorize/permissions/userManagementPermissions";
import NotificationProvider from "components/NotificationProvider";
import { useGetUserTaskConfigsByUserIdQuery } from "./UserTasks/userTaskConfigSlice";

const useStyles = usersPageStyle;

const EditUserDialog = ({ user, roles, clients, refetchUsers, lots, onCancel }) => {
    const [updateInformation] = useUpdateUserInformationManagerMutation()
    const classes = useStyles();
    lots = lots || [];
    clients = clients || [];
    const [userInfo, setUserInfo] = useState({});
    const [errorMessages, setErrorMessages] = useState({});
    const userId = useSelector(selectUserId);

    let {
        data: userLotRelations,
        isLoading: isLoadingRelations
    } = useGetUserLotRelationsForUserByManagerQuery(userId, { skip: !userId });
    let {
        data: userClaims,
    } = useGetClaimsByUserIdQuery(user?.id, { skip: !user?.id });

    let { data: userTasks, 
        isLoading: isLoadingUserTasks } 
    =   useGetUserTaskConfigsByUserIdQuery(user?.id, { skip:!user?.id })

    userLotRelations = userLotRelations || [];
    userClaims = userClaims?.data || [];
    userTasks = userTasks?.data || [];

    const [relations, setRelations] = useState(userLotRelations);

    useEffect(() => {
        setRelations(userLotRelations);
    }, [isLoadingRelations]);

    const handleUserChange = (updatedUserInfo) => {
        setUserInfo(updatedUserInfo);
    };

    const validateDataBeforeSend = () => {
        setErrorMessages({});
        const updatedMessages = { ...errorMessages };
        let isValid = true;
        if (!userInfo.roleId) {
            updatedMessages.role = "Please select a role";
            isValid = false;
        } else {
            updatedMessages.role = null
        }
        if (!userInfo.fullName) {
            updatedMessages.fullName = "Please enter a full name";
            isValid = false;
        } else {
             updatedMessages.fullName = null
        }

        if (!userInfo.userTypeId || userInfo.userTypeId === "" || userInfo.userTypeId === 0) {
            updatedMessages.userType = "Please select a user type";
            isValid = false;
        } else {
            updatedMessages.userType = null
        }

        setErrorMessages(updatedMessages);
        return isValid;
    }

    const handleSave = async () => {
        const objToSend = {
            userId: user?.id,
            roleId: userInfo.roleId,
            fullName: userInfo.fullName,
            lockoutEnabled: userInfo.lockoutEnabled,
            isBlackList: userInfo.isBlackList,
            userType: parseInt(userInfo.userTypeId ?? 1)
        }

        const result = await updateInformation(objToSend);

        if (!result?.error) {
            NotificationProvider.success('Saved successfully');
            refetchUsers();
            onCancel();
        } else {
            NotificationProvider.error('Failed to save');
        }
    };


    const tabs = [];
    if (hasPermission(userManagementPermissions.USER_INFORMATION.key)) {
        tabs.push({
            label: <><IconUser width={25} height={25} className={classes.labelIcon} />User Information</>,
            component: <UserInformation user={user} roles={roles} userClaims={userClaims}
                refetchUsers={refetchUsers}
                onCancel={onCancel}
                relations={userLotRelations}
                handleSave={handleSave}
                handleUserChange={handleUserChange}
                validateDataBeforeSend={validateDataBeforeSend}
                errorMessages={errorMessages}></UserInformation>
        });
    }

    if (hasPermission(userManagementPermissions.LOT_RELATIONS.key)) {
        tabs.push({
            label: <><IconLot width={25} height={25} className={classes.labelIcon} />Lot Relations</>,
            component: <Paper className={classes.paper}>
                <UserLotRelations userId={user?.id} lots={lots} relations={relations} refetchUsers={refetchUsers}
                    setRelations={setRelations}
                    onCancel={onCancel} />
            </Paper>
        });
    }

    if (hasPermission(userManagementPermissions.ACCESS_LOT_CLIENT.key)) {
        tabs.push(
            {
                label: <><IconKey width={25} height={25} className={classes.labelIcon} />Access</>,
                component: <UserRestrictions user={user} userClaims={userClaims} lots={lots} clients={clients}
                    refetchUsers={refetchUsers}
                    userLotRelations={userLotRelations}></UserRestrictions>
            });
    }
    if (hasPermission(userManagementPermissions.TASK.key)) {
        tabs.push(
            {
                label: <><AlarmOnOutlinedIcon width={25} height={25} className={classes.labelIcon} />Tasks</>,
                component: <Paper className={classes.componentWrapper}><UserTasksList userId={user?.id} userTasks={userTasks} isLoadingUserTasks={isLoadingUserTasks} /></Paper>
            });
    }

    if (hasPermission(userManagementPermissions.PERMISSIONS.key)) {
        tabs.push({
            label: <><VerifiedUserOutlinedIcon width={25} height={25} className={classes.labelIcon} />Permissions</>,
            component: <EditUserPermissions userId={user?.id} refetchUsers={refetchUsers} onCancel={onCancel}
                rolesData={roles} user={user} />
        })
    }

    return (
        <Dialog open={!!user?.id}
            onClose={() => onCancel()} aria-labelledby="form-dialog-title" fullWidth
            maxWidth='lg'>
            <DialogTitle id="form-dialog-title">
                <Grid container direction='row' alignItems='center' spacing={2}>
                    <Grid item>
                        <IconUser width={40} height={40} className={classes.titleIcon} />
                    </Grid>
                    <Grid item>
                        Edit User
                        <div className={classes.subTitle}>{user?.email}</div>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <div className={classes.editUserDialogContent}>
                    <TabsWrapper tabs={tabs} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    onCancel()
                }} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}


export default EditUserDialog;
