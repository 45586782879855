import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import accordionCoreStyles from "./accordionCoreStyles";

const AccordionCore = ({
  children,
  headerActions,
  headerActionsPosition = "flex-end",
  headerText,
  headerTextIcon,
  className,
  header,
  open = false
}) => {

  const classes = accordionCoreStyles();
  const [accordionOpen, setAccordionOpen] = useState(open);

  useEffect(() => {
    setAccordionOpen(open)
  }, [open])

  return (<Accordion className={className ?? classes.accordionBackground} expanded={accordionOpen}>
    <AccordionSummary
      expandIcon={
        <ExpandMoreIcon
          style={{ cursor: 'pointer' }}
          onClick={() => setAccordionOpen(!accordionOpen)} />
      }
      sx={{ cursor: 'unset !important' }}
      aria-controls="panel1-content"
      id="panel1-header"
    >
      {header ? <div className={classes.accordionHeader} onClick={() => setAccordionOpen(!accordionOpen)}>{header}</div> :
        <div className={classes.accordionHeader} onClick={() => setAccordionOpen(!accordionOpen)}>
          <div >
            {headerTextIcon}
          </div>
          <div>
            <span className={classes.accordionHeaderText}> {headerText ?? "---"}</span>
          </div>
        </div>}
      <Grid container direction="row" alignItems="center" justifyContent={headerActionsPosition}>
        {headerActions}
      </Grid>
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion>);
}
export default AccordionCore;