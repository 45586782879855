import { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationProvider from "../../../../components/NotificationProvider";
import { invoiceStatusDropdownStyle } from "./InvoiceStatusDropdownStyle";
import { NoteContent } from "features/notes/NoteEditor";
import { Select, Button, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useAddNoteMutation } from '../../../notes/notesSlice';

const InvoiceStatusDropdown = ({ invoiceId
    , invoice
    , filled = true
    , isDisabled = false
    , showConfirmationFor = null
    , noteEntityType = null
    , confirmationTitle = "Reject"
    , invoiceStatusList 
    , updateMutation }) => {
    const classes = invoiceStatusDropdownStyle();
    const [addNote] = useAddNoteMutation();
    let [updateStatus, { isLoading: isLoadingUpdateStatus  }] = updateMutation();
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [previousStatus, setPreviousStatus] = useState(0);
    const [note, setNote] = useState("");
    const [disableConfirmButton, setDisableConfirmButton] = useState(true);

    const onChangeNote = (field, value) => {
        setNote(value);
        setDisableConfirmButton(value === "" || value === "<br>");
    }

    const onSave = async () => {
        const result = await updateStatus({
            id: invoiceId,
            statusId: invoice.statusId,
        });
        setOpenConfirmation(false);
        if (result?.error) {
            invoice.statusId = previousStatus;
            NotificationProvider.error(`Failed to update status`)
        } else {
            if (noteEntityType && openConfirmation) {
                await addNote({
                    content: `<b>${confirmationTitle}ed reason:</b> ${note}`,
                    entityType: noteEntityType,
                    entityId: invoiceId,
                    showInReport: false,
                    viewByClient: false
                });
            }

            NotificationProvider.success(`Successfully updated status`);
        }
        setNote("");
        setDisableConfirmButton(true);
    }

    const onCancel = () => {
        setNote("");
        setOpenConfirmation(false);
        setDisableConfirmButton(true);
        invoice.statusId = previousStatus;
    }

    const onStatusChange = async (event) => {
        setPreviousStatus(invoice.statusId);
        invoice.statusId = event.target.value;
        if (showConfirmationFor && event.target.value === showConfirmationFor) {
            setOpenConfirmation(true);
        } else {
            await onSave();
        }
    }

    const renderValue = (selected) => {
        const color = invoiceStatusList?.find(s => s.id == selected)?.color;
        return <div className={`${classes.selectItem} ${filled ? 'filled' : ''}`} style={filled ? { backgroundColor: color } : { color }}>
            {invoiceStatusList?.find(s => s.id == selected)?.name}
        </div>
    };

    return (
        isLoadingUpdateStatus? "Saving...": <>
            <Dialog open={openConfirmation}>
                <DialogTitle>
                    {confirmationTitle} Invoice
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mb: 2 }} >
                        Are you sure you want to {confirmationTitle.toLocaleLowerCase()} the invoice? The invoice will not be billed through ParQ and will need to be billed manually.<br /><br />
                        Please provide a note explaining the reason.
                    </Box>
                    <NoteContent
                        onChange={onChangeNote}
                        hideViewByClient={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button disabled={(disableConfirmButton && !isLoadingUpdateStatus) || (!disableConfirmButton && isLoadingUpdateStatus)} onClick={onSave} variant="contained" color='primary'>
                        {isLoadingUpdateStatus ? 'Saving...' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Select
                native={false}
                className={classes.select}
                value={invoice?.statusId}
                onChange={onStatusChange}
                variant="outlined"
                inputProps={{
                    className: classes.select,
                }}
                MenuProps={{
                    className: classes.selectMenu,
                    style: { padding: 0 }
                }}
                IconComponent={ExpandMoreIcon}
                renderValue={renderValue}
                disabled={isDisabled }
            >
                {invoiceStatusList?.map((value, key) => (
                    <div
                        className={classes.statusItem}
                        style={{ color: value.color }}
                        id={key} key={key} value={value.id}>
                        {value.name}
                    </div>
                ))}
            </Select>
        </>
    )
}
export default InvoiceStatusDropdown;