import { Button, IconButton, Tooltip } from "@material-ui/core";
import moment from "moment";
import Papa from "papaparse";
import { useState } from "react";
import CsvDownload from "../CsvDownload";
import { exportButtonStyle } from "./style";

const ExportButton = ({
    fileLabel,
    data,
    mapExportData,
    getData,
    vin = null,
    label = 'Export CSV',
    btnStyle = 'contained',
    icon = <img src="/icons/icon-download.svg" />,
    disabled = false,
    onFinish,
    className,
    color="primary"
}) => {
    const classes = exportButtonStyle();
    const [csvExport, setCsvExport] = useState('');

    const getFileName = () => {
        const timestamp = moment().format("YYYYDDMMhmmss");
        const fileNameMiddleStamp = vin ? '-' + vin : '';
        return `${fileLabel}${fileNameMiddleStamp}-${timestamp}.csv`;
    };

    const exportCsv = async () => {
        let dataToExport = data;
        if (getData) {
            dataToExport = await getData();
        }
        let csvData = dataToExport?.map(mapExportData);
        //let csvData = getData ? (await getData())?.map(mapExportData) : data.map(mapExportData);
        const csv = Papa.unparse(csvData);
        const file = new Blob([csv], { type: 'text/csv' });
        setCsvExport(URL.createObjectURL(file));
        if (onFinish) { onFinish() };
    };

    return (
        <>
            <CsvDownload href={csvExport} fileName={getFileName()} />
            {label == '' ?
                <Tooltip title='Export Data'>
                    <IconButton
                        size="small"
                        disabled={disabled}
                        variant={btnStyle}
                        color="primary"
                        className={className ?? classes.actionButton}
                        onClick={() => exportCsv()} >{icon}
                    </IconButton>
                </Tooltip> :
                <Button disabled={disabled} variant={btnStyle} color={color} className={className ?? classes.actionButton} startIcon={icon} onClick={() => exportCsv()}>
                    {label}
                </Button>
            }
        </>
    );
};

export default ExportButton;
