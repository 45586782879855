import { Button, Grid, Paper } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput';
import LoadingSpinner from "components/LoadingSpinner";
import NotificationProvider from 'components/NotificationProvider';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useAddTaxRateMutation, useUpdateTaxRateMutation } from '../TaxRateSlice';
import { TaxRateStyle } from "../TaxRateStyle";

const OneOnOneForm = ({ taxRateToEdit }) => {

    const classes = TaxRateStyle();

    let { data: lots, error: lotsError, isLoading: isLoadingLots } = useGetLotsQuery();
    lots = lots || [];

    const [addTaxRate] = useAddTaxRateMutation();
    const [editTaxRate] = useUpdateTaxRateMutation();
    const [openDialog, setOpenDialog] = useState(true);
    const [taxRate, setTaxRate] = useState(taxRateToEdit || null);
    const [showMandatoryError, setShowMandatoryError] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setTaxRate(taxRateToEdit || null);
        setOpenDialog(true);
    }, [taxRateToEdit])

    const onChange = (key, val) => {
        setTaxRate({ ...taxRate, [key]: val });
    };

    const isValid = () => {
        return !!(
            taxRate?.lotId &&
            taxRate?.mechanicalRate &&
            taxRate?.storageRate 
        );
    }

    const handleSave = async () => {
        const validate = isValid();
        if (!validate) {
            setShowMandatoryError(true);
            return;
        }
        setShowMandatoryError(false);
        setIsSaving(true);

        let res = null;

        if (taxRate?.id) {
            res = await editTaxRate(taxRate);
        }
        else
            res = await addTaxRate(taxRate);

        setIsSaving(false);
        if (!res?.error) {
            NotificationProvider.success('Saved successfully');
        } else {
            NotificationProvider.error('Failed to save');
        }

        history.push(`/taxrate`);
        setOpenDialog(false);
        setTaxRate(null);
    }

    const handleClose = () => {
        setOpenDialog(false);
        setTaxRate(null);
        setShowMandatoryError(false);
        history.push(`/taxrate`);

    }

    const isLoading = () => isLoadingLots;

    let form = (
        <Grid container>
            <Grid item xs={12}>

                <div className={classes.input}>
                    <CustomInput
                        id="loId"
                        label="Lot"
                        value={taxRate?.lotId}
                        elementType="dropdown"
                        onChange={val => onChange("lotId", +val)}
                        values={lots}
                        showEmpty
                        required
                        touched
                        valid={!(showMandatoryError && !taxRate?.lotId)}
                    />
                </div>

                <div className={classes.input}>
                    <CustomInput
                        id="mechanicalRate"
                        label="Mechanical Rate"
                        value={taxRate?.mechanicalRate}
                        elementType="input"
                        numberOnly
                        onChange={val => onChange("mechanicalRate", +val)}
                        required
                        touched
                        valid={!(showMandatoryError && !taxRate?.mechanicalRate)}
                    />
                </div>

                <div className={classes.input}>
                    <CustomInput
                        id="storageRate"
                        label="Storage Rate"
                        value={taxRate?.storageRate}
                        elementType="input"
                        numberOnly
                        onChange={val => onChange("storageRate", +val)}
                        required
                        touched
                        valid={!(showMandatoryError && !taxRate?.storageRate)}
                    />
                </div>

            </Grid>
        </Grid>
    );

    return (
        <>
            {
                isLoading() ? <LoadingSpinner loading={isLoading()} /> :
                    <Paper variant='outlined' className={classes.formContainer}>
                        <Dialog open={openDialog} onClose={() => handleClose()} aria-labelledby="form-dialog-title" maxWidth='md' fullWidth>
                            <DialogTitle id="form-dialog-title">{'Tax Rate'}</DialogTitle>
                            <DialogContent>
                                <form id="createTaxRateForm" className={`${classes.form} ${isLoading() && classes.disabledForm}`}>
                                    {form}
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleClose()} color="primary" disabled={isSaving}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleSave}
                                    color="primary"
                                    variant="contained"
                                    disabled={isSaving}
                                >
                                    {isSaving ? 'Saving...' : 'Save'}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Paper>
            }
        </>
    );
};

export default OneOnOneForm;
