import { Grid } from "@material-ui/core";
import GraphHeader from "features/landing/components/WorkorderDashboard/GraphHeader/GraphHeader";
import { useEffect, useState, useMemo } from 'react';
import { BarChart, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer } from 'recharts';

const ActiveUsersPerClient = ({ users, clients }) => {
    const usersFilter = users || [];
    const [chartValue, setChartValue] = useState([]);

    const chartData = useMemo(() => {
        if (!clients || !usersFilter) return [];
        const usersArray = usersFilter.filter(user => user?.accessClient?.length > 0);

        return clients.map(client => {
            let count = 0;
            usersArray.forEach(user => {
                const usersFound = user.accessClient.filter(x => x.id === client.id);
                if (usersFound.length > 0) {
                    count += 1;
                }
            });
            return {
                ...client,
                Quantity: count
            };
        });
    }, [clients, usersFilter]); 

    useEffect(() => {
        if (chartData) {
            setChartValue(chartData);
        }
    }, [chartData]); // Cuando chartData cambia, actualiza chartValue

    return (
        <Grid style={{ height: 350 }}>
            <Grid item>
                <GraphHeader
                    title={'Active Users Per Client'}
                />
            </Grid>
            <Grid item style={{ height: 300 }}>
                <ResponsiveContainer>
                    <BarChart
                        width={800}
                        height={350}
                        data={chartValue}
                        layout='horizontal'
                        barSize={15}
                        cx={"50%"}
                        cy={"50%"}
                        innerRadius={70}
                        outerRadius={90}
                    >
                        <YAxis />
                        <XAxis dataKey="name"/>
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Quantity" nameKey="name" fill="#8516c9"/>
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
        </Grid>
    );
};

export default ActiveUsersPerClient;
