import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, FormControlLabel, Grid, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getErrorsFromResponse } from "app/errorHandling";
import { useGetCheckInForClientQuery } from "features/clients/clientConfigs/CheckIn/CheckInConfigSlice";
import { DataPointType } from "features/clients/clientConfigs/CheckIn/DataPointType";
import { SectionType } from "features/clients/clientConfigs/CheckIn/SectionType";
import { useGetDriversQuery } from "features/drivers/driversSlice";
import { licensePlateTypesList } from 'features/vehicles/vehicleConsts';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import CustomInput from "../../components/CustomInput";
import NotificationProvider from '../../components/NotificationProvider';
import FormatService from '../../services/formatService';
import VehicleService from '../../services/vehicleService';
import { useGetClientsQuery } from "../clients/clientSlice";
import { useGetLotsQuery } from "../lots/lotSlice";
import { useGetVendorsByClaimsQuery } from '../vendors/vendorSlice';
import {
    useGetConditionReportSectionsQuery,
    useUpdateAssetConditionReportMutation,
    useUpdateConditionReportMutation
} from "./ConditionReportSlice";
import VinDecode from "./VinDecode/VinDecodePopup";
import VehicleMatches from "./components/VehicleMatches/VehicleMatches";
import formStyles from "./formStyles";
import {
    useGetVehicleDetailsExtendedQuery,
    useGetVehicleDetailsQuery,
    useGetVehicleTypesQuery
} from "./vehicleSlice";

const useStyles = formStyles;

const ConditionReportPopup = ({ handleClose, asset, updateAsset }) => {

    const [pageNumber, setPageNumber] = useState(1);
    const [updateConditionReport] = useUpdateConditionReportMutation();
    const [updateAssetConditionReport] = useUpdateAssetConditionReportMutation();
    
    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetVendorsByClaimsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: lots, error: lotError, isLoading: isLoadingLots, isSuccess: isLotsSuccess } = useGetLotsQuery();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes, isSuccess: isVehicleTypesSuccess } = useGetVehicleTypesQuery();
    let { data: vehicleDetails, error: vehicleDetailsError, isLoading: isLoadingVehicleDetails, isSuccess: isVehicleDetailsSuccess, refetch: refetchDetails } = useGetVehicleDetailsQuery(asset.id, { skip: !asset.id });
    let { data: conditionReportSections, error: ConditionReportSectionsError, isLoading: isLoadingConditionReportSections, isSuccess: isConditionReportSectionsSuccess, refetch: refetchConditionReportSections } = useGetConditionReportSectionsQuery(asset.id);
    
    vehicleTypes = vehicleTypes || [];
    vendors = vendors || [];
    clients = clients || [];
    vehicleDetails = vehicleDetails || {};
    conditionReportSections = conditionReportSections || {};


    const classes = useStyles();
    let [editedAsset, setEditedAsset] = useState(asset);
    let [isSaving, setIsSaving] = useState(false);
    let [isSavingSections, setIsSavingSections] = useState(false);
    const [error, setError] = useState(null);
    const [mandatoryFields, setMandatoryFields] = useState({});
    let [existingImages, setExistingImages] = useState();

    let [conditionReport, setConditionReport] = useState([]);

    let {
        data: vehicleDetailsExtended,
        error: VehicleDetailExtendedError,
        isLoading: isLoadingVehicleDetailExtended,
    } = useGetVehicleDetailsExtendedQuery(editedAsset.id);
    vehicleDetailsExtended = vehicleDetailsExtended || [];

    let { 
        data: clientsCheckIn,
        error: clientsCheckInError,
        isLoading: isLoadingClientsCheckIn 
    } = useGetCheckInForClientQuery(vehicleDetails?.clientId);


    clientsCheckIn = clientsCheckIn?.data ?? [];

    let { data: drivers, error: driverError, isLoading: isLoadingDrivers } = useGetDriversQuery();

    drivers = drivers?.map(d => ({
        ...d,
        name: `${d?.firstName} ${d?.lastName}`
    }))

    drivers = drivers?.filter(d => d.vendorId == editedAsset.driverCarrierId);

    useEffect(() => {
        if (!existingImages) {
            setExistingImages(vehicleDetails?.images);
        }
    }, [vehicleDetails])


    useEffect(() => {
        if (vehicleDetails?.id) {
            setEditedAsset(vehicleDetails);
        }
    }, [vehicleDetails?.id])

    useEffect(() => {
        setConditionReport(conditionReportSections);
    }, [conditionReportSections])

    const clientAccounts = _.flatten(clients.map(c => c.clientAccounts));
    const clientAccount = clientAccounts?.find(c => c.id === editedAsset.customerId);
    const [decodedData, setDecodedData] = useState({});
    const [openDecoded, setOpenDecoded] = useState(false);
    const [colorDecoded, setColorDecoded] = useState(false);
    const [colorDecodedType, setColorDecodedType] = useState(false);
    const isLoading = () => isLoadingConditionReportSections || isLoadingClients || isLoadingVehicleTypes || isLoadingLots || isLoadingVehicleDetails || isLoadingVendors;

    let loadingData = {
        yesOrNotQuestions: [
            {id: "Yes", name: "Yes"},
            {id: "No", name: "No"}
        ],
        levelNotLevelOptions: [
            {id: "Level", name: "Level"},
            {id: "Not Level", name: "Not Level"}
        ],
        tankLevelOptions: [
            {id: "Full", name: "Full"},
            {id: "Low", name: "Low"},
            {id: "Empty", name: "Empty"}
        ],
        yesOrNotOrNAQuestions: [
            {id: "Yes", name: "Yes"},
            {id: "No", name: "No"},
            {id: "N/A", name: "N/A"}
        ],
        washerFluidIf: [
            {id: "Full", name: "Full"},
            {id: "Low", name: "Low"},
            {id: "Empty", name: "Empty"},
        ],
        TireTreadMeasurements: [
            {id: "1/32 in", name: "1/32 in"},
            {id: "1/16 in", name: "1/16 in"},
            {id: "3/32 in", name: "3/32 in"},
            {id: "1/8 in", name: "1/8 in"},
            {id: "5/32 in", name: "5/32 in"},
            {id: "3/16 in", name: "3/16 in"},
            {id: "7/32 in", name: "7/32 in"},
            {id: "1/4 in", name: "1/4 in"},
            {id: "9/32 in", name: "9/32 in"},
            {id: "5/16 in", name: "5/16 in"},
            {id: "11/32 in", name: "11/32 in"},
            {id: "3/8 in", name: "3/8 in"},
            {id: "13/32 in", name: "13/32 in"}
        ],
        BrakeMeasurements: [
            {id: "1/32 in", name: "1/32 in"},
            {id: "1/16 in", name: "1/16 in"},
            {id: "3/32 in", name: "3/32 in"},
            {id: "1/8 in", name: "1/8 in"},
            {id: "5/32 in", name: "5/32 in"},
            {id: "3/16 in", name: "3/16 in"},
            {id: "7/32 in", name: "7/32 in"},
            {id: "1/4 in", name: "1/4 in"},
            {id: "9/32 in", name: "9/32 in"},
            {id: "5/16 in", name: "5/16 in"},
            {id: "11/32 in", name: "11/32 in"},
            {id: "3/8 in", name: "3/8 in"},
            {id: "13/32 in", name: "13/32 in"},
            {id: "7/16 in", name: "7/16 in"},
            {id: "15/32 in", name: "15/32 in"},
            {id: "1/2 in", name: "1/2 in"},
            {id: "17/32 in", name: "17/32 in"},
            {id: "9/16 in", name: "9/16 in"}
        ]
    }

    let damageResponses = [
        "Yes",
        "1/4 in",
        "1/32 in",
        "1/16 in",
        "3/32 in",
        "1/8 in",
        "5/32 in",
        "3/16 in",
        "7/32 in",
        "1/32 in",
        "1/16 in",
        "3/32 in"
    ];

    function scrollToTop() {
        const element = document.getElementById('topPage');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const loadFrontOfVehicleData = async() => {
        let list = [];
        list.push(conditionReport.frontOfVehicle.bodyDamage);
        list.push(conditionReport.frontOfVehicle.windshieldDamage);
        list.push(conditionReport.frontOfVehicle.damagePhoto);
        list.push(conditionReport.frontOfVehicle.windshieldWipersWorking);
        list.push(conditionReport.frontOfVehicle.hoodOperational);
        list.push(conditionReport.frontOfVehicle.driverSideEngine);
        list.push(conditionReport.frontOfVehicle.frontFacingEngine);
        list.push(conditionReport.frontOfVehicle.passengerSideEngine);
        list.push(conditionReport.frontOfVehicle.hoseDamage);
        list.push(conditionReport.frontOfVehicle.oilLeakage);
        list.push(conditionReport.frontOfVehicle.batteryWireCorrosion);
        list.push(conditionReport.frontOfVehicle.coolantOverflow);
        list.push(conditionReport.frontOfVehicle.washerFluidFillIf);
        list.push(conditionReport.frontOfVehicle.driverSideHeadlightTurnSignal);
        list.push(conditionReport.frontOfVehicle.passengerHeadlightTurnSignal);
        list.push(conditionReport.frontOfVehicle.additionalFrontLighting);
        list.push(conditionReport.frontOfVehicle.fullFrontalImage);
        return list;
    }

    const loadFrontDriverSideOfVehicleData = async() => {
        let list = [];
        list.push(conditionReport.frontDriverSideOfVehicle.doorOperational);
        list.push(conditionReport.frontDriverSideOfVehicle.driverFrontSideviewMirror);
        list.push(conditionReport.frontDriverSideOfVehicle.driverSideFrontWindow);
        list.push(conditionReport.frontDriverSideOfVehicle.tireDamage);
        //list.push(conditionReport.frontDriverSideOfVehicle.driverSideFrontLowTirePressure);
        list.push(conditionReport.frontDriverSideOfVehicle.driverSideFrontTireTreadMeasurement);
        list.push(conditionReport.frontDriverSideOfVehicle.driverSideFrontBrakeMeasurement);
        list.push(conditionReport.frontDriverSideOfVehicle.bodyDamage);
        return list;
    }

    const RearDriverSideOfVehicleData = async() => {
        let list = [];
        list.push(conditionReport.rearDriverSideOfVehicle.doorOperational);
        list.push(conditionReport.rearDriverSideOfVehicle.driverSideRearWindow);
        list.push(conditionReport.rearDriverSideOfVehicle.tireDamage);
        //list.push(conditionReport.rearDriverSideOfVehicle.driverSideRearLowTirePressure);
        list.push(conditionReport.rearDriverSideOfVehicle.driverSideRearTireTreadMeasurement);
        list.push(conditionReport.rearDriverSideOfVehicle.driverSideRearBrakeMeasurement);
        list.push(conditionReport.rearDriverSideOfVehicle.bodyDamage);
        return list;
    }

    const RearOfVehicleData = async() => {
        let list = [];
        list.push(conditionReport.rearOfVehicle.rearGlassDamage);
        list.push(conditionReport.rearOfVehicle.doorsTrunckOperational);
        list.push(conditionReport.rearOfVehicle.reverseLightsWorking);
        list.push(conditionReport.rearOfVehicle.rearDriverSideHeadLightTurnSignal);
        list.push(conditionReport.rearOfVehicle.rearPassenserSideHeadlightTurnSignal);
        list.push(conditionReport.rearOfVehicle.rearWipersWorking);
        list.push(conditionReport.rearOfVehicle.spareTire);
        list.push(conditionReport.rearOfVehicle.bodyDamage);
        return list;
    }

    const RearPassengerSideOfVehicleData = async() => {
        let list = [];
        list.push(conditionReport.rearPassengerSideOfVehicle.doorOperational);
        list.push(conditionReport.rearPassengerSideOfVehicle.passenserSideRearWindow);
        list.push(conditionReport.rearPassengerSideOfVehicle.tireDamage);
        //list.push(conditionReport.rearPassengerSideOfVehicle.passengerSideRearLowTirePressure);
        list.push(conditionReport.rearPassengerSideOfVehicle.passengerSideRearTireTreadMeasurement);
        list.push(conditionReport.rearPassengerSideOfVehicle.passengerSideFrontBrakeMeasurement);
        list.push(conditionReport.rearPassengerSideOfVehicle.bodyDamage);
        return list;
    }

    const FrontPassengerSideOfVehicleData = async() => {
        let list = [];
        list.push(conditionReport.frontPassengerSideOfVehicle.doorOperational);
        list.push(conditionReport.frontPassengerSideOfVehicle.passengerFrontSideviewMirror);
        list.push(conditionReport.frontPassengerSideOfVehicle.passengerFrontWindow);
        list.push(conditionReport.frontPassengerSideOfVehicle.tireDamage);
        //list.push(conditionReport.frontPassengerSideOfVehicle.passengerSideFrontLowTirePressure);
        list.push(conditionReport.frontPassengerSideOfVehicle.passengerSideFrontTireTreadMeasurement);
        list.push(conditionReport.frontPassengerSideOfVehicle.passengerSideRearBrakeMeasurement);
        list.push(conditionReport.frontPassengerSideOfVehicle.bodyDamage);
        list.push(conditionReport.frontPassengerSideOfVehicle.frontPassengerSideImage);
        return list;
    }

    const InteriorDataPointsData = async() => {
        let list = [];
        list.push(conditionReport.interiorDataPoints.rearviewMirror);
        list.push(conditionReport.interiorDataPoints.mainDomeLightWorking);
        list.push(conditionReport.interiorDataPoints.rearLightWorking);
        list.push(conditionReport.interiorDataPoints.radioWorking);
        list.push(conditionReport.interiorDataPoints.defrosterWorking);
        list.push(conditionReport.interiorDataPoints.heatWorking);
        list.push(conditionReport.interiorDataPoints.dashDamage);
        list.push(conditionReport.interiorDataPoints.seatDamage);
        list.push(conditionReport.interiorDataPoints.floorCarpetDamage);
        list.push(conditionReport.interiorDataPoints.seatBeltIssue);
        list.push(conditionReport.interiorDataPoints.airConditioningWorking);
        list.push(conditionReport.interiorDataPoints.odometer);
        list.push(conditionReport.interiorDataPoints.fuelLevel);
        list.push(conditionReport.interiorDataPoints.checkEngineLight);
        list.push(conditionReport.interiorDataPoints.otherServiceLight);
        list.push(conditionReport.interiorDataPoints.vehicleStartsOperate);
        list.push(conditionReport.interiorDataPoints.rearDriverSideSeatDamage);
        list.push(conditionReport.interiorDataPoints.rearDriverSideFloorDamage);
        list.push(conditionReport.interiorDataPoints.rearDriverSideSeatBeltDamage);
        list.push(conditionReport.interiorDataPoints.rearPassengerSideSeatDamage);
        list.push(conditionReport.interiorDataPoints.rearPassengerSideFloorDamage);
        list.push(conditionReport.interiorDataPoints.rearPassengerSideSeatBeltDamage);
        return list;
    }

    const LoadMiscData = async() => {
        let list = [];
        list.push(conditionReport.misc.vinPlate);
        list.push(conditionReport.misc.batteryStrenght);
        list.push(conditionReport.misc.dotInspectionDate);
        list.push(conditionReport.misc.dotInspectionSticker);
        list.push(conditionReport.misc.brakeNoise);
        list.push(conditionReport.misc.keysHandedOver);
        list.push(conditionReport.misc.fobsHandedOver);
        list.push(conditionReport.misc.hasLicensePlate);
        list.push(conditionReport.misc.licensePlate);
        list.push(conditionReport.misc.licensePlateState);
        list.push(conditionReport.misc.licensePlateType);
        return list;
    }

    const loadAssetData = async() => {
        return {
            id: asset?.id,
            vin: editedAsset?.descriptor,
            assetId: editedAsset?.assetId,
            year: editedAsset?.year,
            make: editedAsset?.make,
            model: editedAsset?.model,
            color: editedAsset?.color,
            mileage: editedAsset?.mileage,
            assetTypeId: editedAsset.vehicleTypeId,
            ClientAccountId: clientAccount.id,
            HasLicensePlate: editedAsset?.hasLicensePlate,
            LicensePlate:  editedAsset?.licensePlate,
            LicensePlateState: editedAsset?.licensePlateState,
            LicensePlateType: editedAsset?.licensePlateType,
            HasRegistration: editedAsset?.hasRegistration,
            RegistrationYear: editedAsset?.registrationYear,
            RegistrationMonth: editedAsset?.registrationMonth,
            RegistrationExpired: editedAsset?.registrationExpired,
            DriverCarrierId: editedAsset?.driverCarrierId,
            DriverId: editedAsset?.driverId,
        };
    }

    const SaveConditionReport = async(val) => {
        //setIsSaving(true);
        setIsSavingSections(true);

        // TODO VALIDATE FORM FIELD EMPTIES

        let conditionReportData = {};
        let result;
        if(pageNumber === 1){
            conditionReportData.Asset = await loadAssetData();
            result = await updateAssetConditionReport(conditionReportData);
        }
        else{
            conditionReportData.Section = await getSectionToSave();
            result = await updateConditionReport(conditionReportData);
            if(pageNumber === 9)
            {
                conditionReportData.Asset = await loadAssetData();
                result = await updateAssetConditionReport(conditionReportData);
            }
        }

        if (result && !result.error) {
            refetchConditionReportSections();
            NotificationProvider.success("Condition Report data was saved successfully");
        } else {
            NotificationProvider.error(`Failed to save the condition report data. ${getErrorsFromResponse(result)}`);
        }

        if(val === paginationData.SaveAndPrevious)
        {
            reducePageNumber();
        }
        else if(val === paginationData.SaveAndNext)
        {
            nextPageNumber();
        }
        else if(val === paginationData.SaveWithoutPrevious)
        {
            //nextPageNumber();
        }
        else
        {
            handleClose();
        }

        // setIsSaving(false);
        setIsSavingSections(false);
    }

    const getSectionToSave = async() => {
        
        switch(pageNumber) {
            case 1:
               return await loadAssetData(); 
            case 2:
                return await loadFrontOfVehicleData();
            case 3:
                return await loadFrontDriverSideOfVehicleData();
            case 4:
                return await RearDriverSideOfVehicleData();
            case 5:
                return await RearOfVehicleData();
            case 6:
                return await RearPassengerSideOfVehicleData();
            case 7:
                return await FrontPassengerSideOfVehicleData();
            case 8:
                return await InteriorDataPointsData();
            case 9:
                return await LoadMiscData();
            // case 10:
            //     return await UnderCarriageData();
            // case 11:
            //     return await LoadMiscData();
            default:
              // code block
          }
    };

    const onChange = (key, val) => {
        setEditedAsset({ ...editedAsset, [key]: val });
        setMandatoryFields({ ...mandatoryFields, [key]: val ? false : true });
    };

    const IsDamageResponse = (field) => {
        return damageResponses.includes(field);
    }

    const changeOnSection = (json, val, damagedValidation = false) => {
        let newObj = {...conditionReport[json.arrayName][json.object]};
        newObj[json.key] = val;
        if (json.key == "state"){
            if(damagedValidation && IsDamageResponse(val)){
                newObj["isDamage"] = true;
            }
            else {
                newObj["isDamage"] = false;
            }
        }

        let arrayModified = { ...conditionReport[json.arrayName], [json.object]: newObj }
        setConditionReport({ ...conditionReport, [json.arrayName]:  arrayModified});
    }

    const fieldNeedNote = (dataPointType_p, sectionType_p) => {
        const fieldConfig = clientsCheckIn.find((fieldConf) => fieldConf.sectionTypeId === sectionType_p && fieldConf.dataPointTypeId === dataPointType_p && fieldConf.needNote === true);
        if (fieldConfig) return true;
        return false;
    }

    const FrontVehicleSection = () => {
        return <div>
                    <div className={classes.sectionTitle}>
                        Front
                    </div>

                    {
                        isFieldActive(DataPointType.Windshield_Damage, SectionType.Front) && 
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Windshield Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="WindshieldDamage"
                                    label="Windshield Damage"
                                    value={conditionReport.frontOfVehicle.windshieldDamage.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ 
                                                        arrayName: "frontOfVehicle",
                                                        object:"windshieldDamage",
                                                        key: "state"
                                                    }
                                                    , val
                                                    , true)}
                                    values={loadingData.yesOrNotQuestions}
                                    showEmpty={true}
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Windshield_Damage, SectionType.Front) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="WindshieldDamageNotes"
                                        label="Notes..."
                                        value={conditionReport.frontOfVehicle.windshieldDamage.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection(
                                                            { arrayName: "frontOfVehicle", object:"windshieldDamage", key: "detail"}
                                                            , val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }

                    {
                        isFieldActive(DataPointType.Windshield_Wipers_Working, SectionType.Front) && 
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Windshield Wipers Broken/Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <Grid>
                                    <CustomInput
                                        id="windshieldWipersWorking"
                                        label="Windshield Wipers Broken/Damage"
                                        value={conditionReport.frontOfVehicle.windshieldWipersWorking.state} // CHANGE TO CR REPORT DATA
                                        elementType="dropdown"
                                        onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"windshieldWipersWorking", key: "state"}, val, true)}
                                        values={loadingData.yesOrNotQuestions}
                                        required={true}
                                        showEmpty
                                    />
                                </Grid>
                            </div>
                            {
                                fieldNeedNote(DataPointType.Windshield_Wipers_Working, SectionType.Front) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="windshieldWipersWorking"
                                        label="Notes..."
                                        value={conditionReport.frontOfVehicle.windshieldWipersWorking.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection(
                                                            { arrayName: "frontOfVehicle", object:"windshieldWipersWorking", key: "detail"}
                                                            , val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Hood_Operational, SectionType.Front) && 
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Hood Broken/Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="HoodOperational"
                                    label="Hood Broken/Damage"
                                    value={conditionReport.frontOfVehicle.hoodOperational.state} // CHANGE TO CR REPORT DATA
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"hoodOperational", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    required={true}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Hood_Operational, SectionType.Front) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="HoodOperational"
                                        label="Notes..."
                                        value={conditionReport.frontOfVehicle.hoodOperational.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection(
                                                            { arrayName: "frontOfVehicle", object:"hoodOperational", key: "detail"}
                                                            , val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Oil_Leakage, SectionType.Front) && 
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Oil Leakage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="OilLeakage"
                                    label="Oil Leakage"
                                    value={conditionReport.frontOfVehicle.oilLeakage.state} // CHANGE TO CR REPORT DATA
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"oilLeakage", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    required={true}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Oil_Leakage, SectionType.Front) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="oilLeakage"
                                        label="Notes..."
                                        value={conditionReport.frontOfVehicle.oilLeakage.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection(
                                                            { arrayName: "frontOfVehicle", object:"oilLeakage", key: "detail"}
                                                            , val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }

                    {
                        isFieldActive(DataPointType.Hose_Damage, SectionType.Front) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Hose Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="HoseDamage"
                                    label="Hose Damage"
                                    value={conditionReport.frontOfVehicle.hoseDamage.state} // CHANGE TO CR REPORT DATA
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"hoseDamage", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    required={true}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Hose_Damage, SectionType.Front) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="hoseDamage"
                                        label="Notes..."
                                        value={conditionReport.frontOfVehicle.hoseDamage.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection(
                                                            { arrayName: "frontOfVehicle", object:"hoseDamage", key: "detail"}
                                                            , val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Battery_Wire_Corrosion, SectionType.Front) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Battery/Wire Corrosion</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="BatteryWireCorrosion"
                                    label="Battery/Wire Corrosion"
                                    value={conditionReport.frontOfVehicle.batteryWireCorrosion.state} // CHANGE TO CR REPORT DATA
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"batteryWireCorrosion", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    required={true}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Battery_Wire_Corrosion, SectionType.Front) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="batteryWireCorrosion"
                                        label="Notes..."
                                        value={conditionReport.frontOfVehicle.batteryWireCorrosion.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection(
                                                            { arrayName: "frontOfVehicle", object:"batteryWireCorrosion", key: "detail"}
                                                            , val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Coolant_Overflow_Tank, SectionType.Front) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Coolant Overflow Tank</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="CoolantOverflowTank"
                                    label="Coolant Overflow Tank"
                                    value={conditionReport.frontOfVehicle.coolantOverflow.state} // CHANGE TO CR REPORT DATA
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"coolantOverflow", key: "state"}, val)}
                                    values={loadingData.levelNotLevelOptions}
                                    required={true}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Coolant_Overflow_Tank, SectionType.Front) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="coolantOverflow"
                                        label="Notes..."
                                        value={conditionReport.frontOfVehicle.coolantOverflow.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection(
                                                            { arrayName: "frontOfVehicle", object:"coolantOverflow", key: "detail"}
                                                            , val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Washer_Fluid, SectionType.Front) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Washer Fluid</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="WasherFluidFillIf"
                                    label="Washer Fluid"
                                    value={conditionReport.frontOfVehicle.washerFluidFillIf.state} // CHANGE TO CR REPORT DATA
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"washerFluidFillIf", key: "state"}, val)}
                                    values={loadingData.washerFluidIf}
                                    required={true}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Washer_Fluid, SectionType.Front) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="washerFluidFillIf"
                                        label="Notes..."
                                        value={conditionReport.frontOfVehicle.washerFluidFillIf.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection(
                                                            { arrayName: "frontOfVehicle", object:"washerFluidFillIf", key: "detail"}
                                                            , val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Driver_Side_Headlight_Turn_Signal_Damage, SectionType.Front) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Driver Side Headlight & Turn Signal Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="DriverSideHeadlightTurnSignal"
                                    label="Driver Side Headlight & Turn Signal Damage"
                                    value={conditionReport.frontOfVehicle.driverSideHeadlightTurnSignal.state} // CHANGE TO CR REPORT DATA
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"driverSideHeadlightTurnSignal", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    required={true}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Driver_Side_Headlight_Turn_Signal_Damage, SectionType.Front) &&
                                <div className={classes.input}>
                                    <CustomInput
                                        id="DriverSideHeadlightTurnSignalNotes"
                                        label="Notes..."
                                        value={conditionReport.frontOfVehicle.driverSideHeadlightTurnSignal.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"driverSideHeadlightTurnSignal", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                    
                    {
                        isFieldActive(DataPointType.Passenger_Headlight_Turn_Signal_Damage, SectionType.Front) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Passenger Headlight & Turn Signal Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <Grid>
                                    <CustomInput
                                        id="PassengerHeadlightTurnSignal"
                                        label="Passenger Headlight & Turn Signal Damage"
                                        value={conditionReport.frontOfVehicle.passengerHeadlightTurnSignal.state} // CHANGE TO CR REPORT DATA
                                        elementType="dropdown"
                                        onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"passengerHeadlightTurnSignal", key: "state"}, val, true)}
                                        values={loadingData.yesOrNotQuestions}
                                        required={true}
                                        showEmpty
                                    />
                                </Grid>
                            </div>
                            {
                                fieldNeedNote(DataPointType.Passenger_Headlight_Turn_Signal_Damage, SectionType.Front) &&
                                <div className={classes.input}>
                                    <CustomInput
                                        id="PassengerHeadlightTurnSignalNotes"
                                        label="Notes..."
                                        value={conditionReport.frontOfVehicle.passengerHeadlightTurnSignal.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"passengerHeadlightTurnSignal", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }

                    {
                        isFieldActive(DataPointType.Additional_Front_Lighting_Issues, SectionType.Front) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Additional Front Lighting Issues</p>
                            </Grid>
                            <div className={classes.input}>
                                <Grid>
                                    <CustomInput
                                        id="AddiotionalFronLightingtIssues"
                                        label="Addiotional Front Lighting Issues"
                                        value={conditionReport.frontOfVehicle.additionalFrontLighting.state} // CHANGE TO CR REPORT DATA
                                        elementType="dropdown"
                                        onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"additionalFrontLighting", key: "state"}, val, true)}
                                        values={loadingData.yesOrNotQuestions}
                                        required={true}
                                        showEmpty
                                    />
                                </Grid>
                            </div>
                            {
                                fieldNeedNote(DataPointType.Additional_Front_Lighting_Issues, SectionType.Front) &&
                                <div className={classes.input}>
                                    <CustomInput
                                        id="AddiotionalFronLightingtIssuesNotes"
                                        label="Notes..."
                                        value={conditionReport.frontOfVehicle.additionalFrontLighting.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"additionalFrontLighting", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }

                    {
                        isFieldActive(DataPointType.Body_Damage, SectionType.Front) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Body Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <Grid>
                                    <CustomInput
                                        id="BodyDamage"
                                        label="Body Damage"
                                        value={conditionReport.frontOfVehicle.bodyDamage.state} // CHANGE TO CR REPORT DATA
                                        elementType="dropdown"
                                        onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"bodyDamage", key: "state"}, val , true)}
                                        values={loadingData.yesOrNotQuestions}
                                        required={true}
                                        showEmpty
                                    />
                                </Grid>
                            </div>
                            {
                                fieldNeedNote(DataPointType.Body_Damage, SectionType.Front) &&
                                <div className={classes.input}>
                                    <CustomInput
                                        id="BodyDamageNotes"
                                        label="Notes..."
                                        value={conditionReport.frontOfVehicle.bodyDamage.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "frontOfVehicle", object:"bodyDamage", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>;
    }

    const FrontDriverSideSection = () => {
        return <div>
                    <div className={classes.sectionTitle}>
                        Front Driver Side Of Vehicle
                    </div>

                    {
                        isFieldActive(DataPointType.Door_Operational, SectionType.Front_Driver_Side) && 
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Door Broken/Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="DoorOperational"
                                    label="Door Broken/Damage"
                                    value={conditionReport.frontDriverSideOfVehicle.doorOperational.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "frontDriverSideOfVehicle", object:"doorOperational", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Door_Operational, SectionType.Front_Driver_Side) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="doorOperational"
                                        label="Notes..."
                                        value={conditionReport.frontDriverSideOfVehicle.doorOperational.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection(
                                                            { arrayName: "frontDriverSideOfVehicle", object:"doorOperational", key: "detail"}
                                                            , val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }

                    {
                        isFieldActive(DataPointType.Sideview_Mirror_Damage, SectionType.Front_Driver_Side) && 
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Sideview Mirror Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="DriverFrontSideviewMirrorIssue/Damage"
                                    label="Sideview Mirror Damage"
                                    value={conditionReport.frontDriverSideOfVehicle.driverFrontSideviewMirror.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "frontDriverSideOfVehicle", object:"driverFrontSideviewMirror", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    showEmpty
                                />
                            </div>
                            {
                                <div className={classes.input}>
                                    <CustomInput
                                        id="DriverFrontSideviewMirrorIssue/DamageNotes"
                                        label="Notes..."
                                        value={conditionReport.frontDriverSideOfVehicle.driverFrontSideviewMirror.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "frontDriverSideOfVehicle", object:"driverFrontSideviewMirror", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }

                    {
                        isFieldActive(DataPointType.Window_Damage, SectionType.Front_Driver_Side) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Window Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="DriverSideFrontWindow"
                                    label="Window Damage"
                                    value={conditionReport.frontDriverSideOfVehicle.driverSideFrontWindow.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "frontDriverSideOfVehicle", object:"driverSideFrontWindow", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    showEmpty
                                />
                            </div>
                            {
                                <div className={classes.input}>
                                    <CustomInput
                                        id="DriverSideFrontWindowNotes"
                                        label="Notes..."
                                        value={conditionReport.frontDriverSideOfVehicle.driverSideFrontWindow.detail} 
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "frontDriverSideOfVehicle", object:"driverSideFrontWindow", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                            
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Tire_Damage, SectionType.Front_Driver_Side) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Tire Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="TireDamage"
                                    label="Tire Damage"
                                    value={conditionReport.frontDriverSideOfVehicle.tireDamage.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "frontDriverSideOfVehicle", object:"tireDamage", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Tire_Damage, SectionType.Front_Driver_Side) &&
                                <div className={classes.input}>
                                    <CustomInput
                                        id="TireDamageNotes"
                                        label="Notes..."
                                        value={conditionReport.frontDriverSideOfVehicle.tireDamage.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "frontDriverSideOfVehicle", object:"tireDamage", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }

                    {
                        isFieldActive(DataPointType.Tire_Tread_Measurement, SectionType.Front_Driver_Side) &&
                        <div className={classes.subSectionContainerPhoto}>
                            <Grid item xs={12}>
                                <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Tire Tread Measurement </p>
                                </Grid>
                                
                                <div className={classes.input}>
                                    <CustomInput
                                        id="TireTreadMeasurement"
                                        label="Tire Tread Measurement"
                                        value={conditionReport.frontDriverSideOfVehicle.driverSideFrontTireTreadMeasurement.state}
                                        elementType="dropdown"
                                        onChange={val => changeOnSection({ arrayName: "frontDriverSideOfVehicle", object:"driverSideFrontTireTreadMeasurement", key: "state"}, val, true)}
                                        values={loadingData.TireTreadMeasurements}
                                        showEmpty
                                    />
                                </div>
                                {
                                fieldNeedNote(DataPointType.Tire_Tread_Measurement, SectionType.Front_Driver_Side) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="driverSideFrontTireTreadMeasurement"
                                        label="Notes..."
                                        value={conditionReport.frontDriverSideOfVehicle.driverSideFrontTireTreadMeasurement.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection(
                                                            { arrayName: "frontDriverSideOfVehicle", object:"driverSideFrontTireTreadMeasurement", key: "detail"}
                                                            , val)}
                                        required={true}
                                    />
                                </div>
                                }
                            </Grid>
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Brake_Measurement, SectionType.Front_Driver_Side) &&
                        <div className={classes.subSectionContainerPhoto}>
                            <Grid item xs={12}>
                                <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Brake Measurement </p>
                                </Grid>
                                <div className={classes.input}>
                                    <CustomInput
                                        id="BrakeMeasurement"
                                        label="Brake Measurement"
                                        value={conditionReport.frontDriverSideOfVehicle.driverSideFrontBrakeMeasurement.state}
                                        elementType="dropdown"
                                        onChange={val => changeOnSection({ arrayName: "frontDriverSideOfVehicle", object:"driverSideFrontBrakeMeasurement", key: "state"}, val, true)}
                                        values={loadingData.BrakeMeasurements}
                                        showEmpty
                                    />
                                </div>
                                {
                                fieldNeedNote(DataPointType.Brake_Measurement, SectionType.Front_Driver_Side) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="driverSideFrontBrakeMeasurement"
                                        label="Notes..."
                                        value={conditionReport.frontDriverSideOfVehicle.driverSideFrontBrakeMeasurement.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection(
                                                            { arrayName: "frontDriverSideOfVehicle", object:"driverSideFrontBrakeMeasurement", key: "detail"}
                                                            , val)}
                                        required={true}
                                    />
                                </div>
                                }
                            </Grid>
                        </div>
                    }

                    {
                        isFieldActive(DataPointType.Body_Damage, SectionType.Front_Driver_Side) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                <p className={classes.subSectionTitle}>Body Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="BodyDamage"
                                    label="Body Damage"
                                    value={conditionReport.frontDriverSideOfVehicle.bodyDamage.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "frontDriverSideOfVehicle", object:"bodyDamage", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Body_Damage, SectionType.Front_Driver_Side) &&
                                <div className={classes.input}>
                                    <CustomInput
                                        id="BodyDamageNotes"
                                        label="Notes..."
                                        value={conditionReport.frontDriverSideOfVehicle.bodyDamage.detail} 
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "frontDriverSideOfVehicle", object:"bodyDamage", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>;
    }

    const RearDriverSideSection = () => {
        return <div>
                    <div className={classes.sectionTitle}>
                        Rear Driver Side of Vehicle
                    </div>

                    {
                        isFieldActive(DataPointType.Door_Operational_Includes_Sliding_Door_, SectionType.Driver_Side_Rear) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                <p className={classes.subSectionTitle}>Door Broken/Damage (Includes Sliding Door)</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="DoorOperational"
                                    label="Door Broken/Damage (Includes Sliding Door)"
                                    value={conditionReport.rearDriverSideOfVehicle.doorOperational.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "rearDriverSideOfVehicle", object:"doorOperational", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotOrNAQuestions}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Door_Operational_Includes_Sliding_Door_, SectionType.Driver_Side_Rear) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="doorOperational"
                                        label="Notes..."
                                        value={conditionReport.rearDriverSideOfVehicle.doorOperational.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection(
                                                            { arrayName: "rearDriverSideOfVehicle", object:"doorOperational", key: "detail"}
                                                            , val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Window_Damage, SectionType.Driver_Side_Rear) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                <p className={classes.subSectionTitle}>Window Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="DriverSideRearWindow"
                                    label="Window Damage"
                                    value={conditionReport.rearDriverSideOfVehicle.driverSideRearWindow.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "rearDriverSideOfVehicle", object:"driverSideRearWindow", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotOrNAQuestions}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Window_Damage, SectionType.Driver_Side_Rear) &&
                                <div className={classes.input}>
                                    <CustomInput
                                        id="DriverSideRearWindowNotes"
                                        label="Notes..."
                                        value={conditionReport.rearDriverSideOfVehicle.driverSideRearWindow.detail} 
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "rearDriverSideOfVehicle", object:"driverSideRearWindow", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                    
                    {
                        isFieldActive(DataPointType.Tire_Damage, SectionType.Driver_Side_Rear) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                <p className={classes.subSectionTitle}>Tire Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="TireDamage"
                                    label="Tire Damage"
                                    value={conditionReport.rearDriverSideOfVehicle.tireDamage.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "rearDriverSideOfVehicle", object:"tireDamage", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    showEmpty
                                />
                            </div>

                            {
                                fieldNeedNote(DataPointType.Tire_Damage, SectionType.Driver_Side_Rear) &&
                                <div className={classes.input}>
                                    <CustomInput
                                        id="TireDamageNotes"
                                        label="Notes..."
                                        value={conditionReport.rearDriverSideOfVehicle.tireDamage.detail}
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "rearDriverSideOfVehicle", object:"tireDamage", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Tire_Tread_Measurement, SectionType.Driver_Side_Rear) &&
                        <div className={classes.subSectionContainerPhoto}>
                            <Grid item xs={12}>
                                <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Tire Tread Measurement </p>
                                </Grid>
                                <div className={classes.input}>
                                    <CustomInput
                                        id="TireTreadMeasurement"
                                        label="Tire Tread Measurement"
                                        value={conditionReport.rearDriverSideOfVehicle.driverSideRearTireTreadMeasurement.state}
                                        elementType="dropdown"
                                        onChange={val => changeOnSection({ arrayName: "rearDriverSideOfVehicle", object:"driverSideRearTireTreadMeasurement", key: "state"}, val, true)}
                                        values={loadingData.TireTreadMeasurements}
                                        showEmpty
                                    />
                                </div>
                                {
                                    fieldNeedNote(DataPointType.Tire_Tread_Measurement, SectionType.Driver_Side_Rear) && 
                                    <div className={classes.input}>
                                        <CustomInput
                                            id="driverSideRearTireTreadMeasurement"
                                            label="Notes..."
                                            value={conditionReport.rearDriverSideOfVehicle.driverSideRearTireTreadMeasurement.detail}
                                            elementType="textarea"
                                            onChange={val => changeOnSection(
                                                                { arrayName: "rearDriverSideOfVehicle", object:"driverSideRearTireTreadMeasurement", key: "detail"}
                                                                , val)}
                                            required={true}
                                        />
                                    </div>
                                }
                            </Grid>
                        </div>
                    }

                    {
                        isFieldActive(DataPointType.Brake_Measurement, SectionType.Driver_Side_Rear) &&    
                        <div className={classes.subSectionContainerPhoto}>
                            <Grid item xs={12}>
                                <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                    <p className={classes.subSectionTitle}>Brake Measurement </p>
                                </Grid>
                                <div className={classes.input}>
                                    <CustomInput
                                        id="BrakeMeasurement"
                                        label="Brake Measurement"
                                        value={conditionReport.rearDriverSideOfVehicle.driverSideRearBrakeMeasurement.state}
                                        elementType="dropdown"
                                        onChange={val => changeOnSection({ arrayName: "rearDriverSideOfVehicle", object:"driverSideRearBrakeMeasurement", key: "state"}, val, true)}
                                        values={loadingData.BrakeMeasurements}
                                        showEmpty
                                    />
                                </div> 
                                {
                                    fieldNeedNote(DataPointType.Brake_Measurement, SectionType.Driver_Side_Rear) && 
                                    <div className={classes.input}>
                                        <CustomInput
                                            id="driverSideRearBrakeMeasurement"
                                            label="Notes..."
                                            value={conditionReport.rearDriverSideOfVehicle.driverSideRearBrakeMeasurement.detail}
                                            elementType="textarea"
                                            onChange={val => changeOnSection(
                                                                { arrayName: "rearDriverSideOfVehicle", object:"driverSideRearBrakeMeasurement", key: "detail"}
                                                                , val)}
                                            required={true}
                                        />
                                    </div>
                                }
                            </Grid>
                        </div>
                    }

                    {
                        isFieldActive(DataPointType.Body_Damage, SectionType.Driver_Side_Rear) &&  
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                <p className={classes.subSectionTitle}>Body Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="BodyDamage"
                                    label="Body Damage"
                                    value={conditionReport.rearDriverSideOfVehicle.bodyDamage.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "rearDriverSideOfVehicle", object:"bodyDamage", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Body_Damage, SectionType.Driver_Side_Rear) &&
                                <div className={classes.input}>
                                    <CustomInput
                                        id="BodyDamageNotes"
                                        label="Notes..."
                                        value={conditionReport.rearDriverSideOfVehicle.bodyDamage.detail} 
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "rearDriverSideOfVehicle", object:"bodyDamage", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                            
                        </div>
                    }
                </div>;
    }

    const RearOfVehicleSection = () => {
            return <div>
                    <div className={classes.sectionTitle}>
                        Rear of Vehicle
                    </div>
                    {
                        isFieldActive(DataPointType.Rear_Glass_Damage, SectionType.Rear) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                <p className={classes.subSectionTitle}>Rear Glass Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="RearGlassDamage"
                                    label="Rear Glass Damage"
                                    value={conditionReport.rearOfVehicle.rearGlassDamage.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"rearGlassDamage", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotOrNAQuestions}
                                    showEmpty
                                />
                            </div>

                            {
                                fieldNeedNote(DataPointType.Rear_Glass_Damage, SectionType.Rear) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="RearGlassDamageNotes"
                                        label="Notes..."
                                        value={conditionReport.rearOfVehicle.rearGlassDamage.detail} 
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"rearGlassDamage", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Door_s_Trunk_Operational, SectionType.Rear) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                <p className={classes.subSectionTitle}>Door(s)/Trunk Broken/Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="DoorOperational"
                                    label="Door(s)/Trunk Broken/Damage"
                                    value={conditionReport.rearOfVehicle.doorsTrunckOperational.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"doorsTrunckOperational", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotOrNAQuestions}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Door_Operational, SectionType.Rear) && 
                                <div className={classes.input}>
                                    <CustomInput
                                        id="doorsTrunckOperational"
                                        label="Notes..."
                                        value={conditionReport.rearOfVehicle.doorsTrunckOperational.detail} 
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"doorsTrunckOperational", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }


                    {
                        isFieldActive(DataPointType.Reverse_Light_s_Working, SectionType.Rear) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                <p className={classes.subSectionTitle}>Reverse Light(s) Broken/Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="ReverseLightWorking"
                                    label="Reverse Light(s) Broken/Damage"
                                    value={conditionReport.rearOfVehicle.reverseLightsWorking.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"reverseLightsWorking", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Reverse_Light_s_Working, SectionType.Rear) &&
                                <div className={classes.input}>
                                    <CustomInput
                                        id="ReverseLightWorkingNotes"
                                        label="Notes..."
                                        value={conditionReport.rearOfVehicle.reverseLightsWorking.detail} 
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"reverseLightsWorking", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }

{
                        isFieldActive(DataPointType.Rear_Driver_Side_Headlight_Turn_Signal_Damage, SectionType.Rear) &&
                    <div className={classes.subSectionContainer}>
                        <Grid item xs={12} className={classes.subSectionContainerTitle}>
                            <p className={classes.subSectionTitle}>Rear Driver Side Headlight & Turn Signal Damage</p>
                        </Grid>
                        <div className={classes.input}>
                            <CustomInput
                                id="rearDriverSideHeadLightTurnSignal"
                                label="Rear Driver Side Headlight & Turn Signal Damage"
                                value={conditionReport.rearOfVehicle.rearDriverSideHeadLightTurnSignal.state}
                                elementType="dropdown"
                                onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"rearDriverSideHeadLightTurnSignal", key: "state"}, val, true)}
                                values={loadingData.yesOrNotQuestions}
                                showEmpty
                            />
                        </div>

                        {
                            fieldNeedNote(DataPointType.Rear_Driver_Side_Headlight_Turn_Signal_Damage, SectionType.Rear) &&
                            <div className={classes.input}>
                                <CustomInput
                                    id="rearDriverSideHeadLightTurnSignalNotes"
                                    label="Notes..."
                                    value={conditionReport.rearOfVehicle.rearDriverSideHeadLightTurnSignal.detail} 
                                    elementType="textarea"
                                    onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"rearDriverSideHeadLightTurnSignal", key: "detail"}, val)}
                                    required={true}
                                />
                            </div>
                    }
                    </div>
    }
                    {
                        isFieldActive(DataPointType.Rear_Passanger_Side_Headlight_Turn_Signal_Working, SectionType.Rear) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                <p className={classes.subSectionTitle}>Rear Passanger Side Headlight & Turn Signal Broken/Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="rearPassenserSideHeadlightTurnSignal"
                                    label="Rear Passanger Side Headlight & Turn Signal Broken/Damage"
                                    value={conditionReport.rearOfVehicle.rearPassenserSideHeadlightTurnSignal.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"rearPassenserSideHeadlightTurnSignal", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    showEmpty
                                />
                            </div>
                            {
                                fieldNeedNote(DataPointType.Rear_Passanger_Side_Headlight_Turn_Signal_Working, SectionType.Rear) &&
                                <div className={classes.input}>
                                    <CustomInput
                                        id="rearPassenserSideHeadlightTurnSignalNotes"
                                        label="Notes..."
                                        value={conditionReport.rearOfVehicle.rearPassenserSideHeadlightTurnSignal.detail} 
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"rearPassenserSideHeadlightTurnSignal", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
{
                        isFieldActive(DataPointType.Rear_Wipers_Working, SectionType.Rear) &&
                    <div className={classes.subSectionContainer}>
                        <Grid item xs={12} className={classes.subSectionContainerTitle}>
                            <p className={classes.subSectionTitle}>Rear Wipers Broken/Damage</p>
                        </Grid>
                        <div className={classes.input}>
                            <CustomInput
                                id="rearWipersWorking"
                                label="Rear Wipers Broken/Damage"
                                value={conditionReport.rearOfVehicle.rearWipersWorking.state}
                                elementType="dropdown"
                                onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"rearWipersWorking", key: "state"}, val, true)}
                                values={loadingData.yesOrNotOrNAQuestions}
                                showEmpty
                            />
                        </div>
                        {
                            fieldNeedNote(DataPointType.Rear_Wipers_Working, SectionType.Rear) &&
                            <div className={classes.input}>
                                <CustomInput
                                    id="rearWipersWorking"
                                    label="Notes..."
                                    value={conditionReport.rearOfVehicle.rearWipersWorking.detail} 
                                    elementType="textarea"
                                    onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"rearWipersWorking", key: "detail"}, val)}
                                    required={true}
                                />
                            </div>
                        }
                    </div>
    }
{
                        isFieldActive(DataPointType.Spare_Tire, SectionType.Rear) &&
                    <div className={classes.subSectionContainer}>
                        <Grid item xs={12} className={classes.subSectionContainerTitle}>
                            <p className={classes.subSectionTitle}>Spare Tire</p>
                        </Grid>
                        <div className={classes.input}>
                            <CustomInput
                                id="spareTire"
                                label="Spare Tire"
                                value={conditionReport.rearOfVehicle.spareTire.state}
                                elementType="dropdown"
                                onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"spareTire", key: "state"}, val)}
                                values={loadingData.yesOrNotOrNAQuestions}
                                showEmpty
                            />
                        </div>
                        {
                            fieldNeedNote(DataPointType.Spare_Tire, SectionType.Rear) &&
                            <div className={classes.input}>
                                <CustomInput
                                    id="spareTire"
                                    label="Notes..."
                                    value={conditionReport.rearOfVehicle.spareTire.detail} 
                                    elementType="textarea"
                                    onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"spareTire", key: "detail"}, val)}
                                    required={true}
                                />
                            </div>
                        }
                    </div>
}
                    {
                        isFieldActive(DataPointType.Body_Damage, SectionType.Rear) &&
                        <div className={classes.subSectionContainer}>
                            <Grid item xs={12} className={classes.subSectionContainerTitle}>
                                <p className={classes.subSectionTitle}>Body Damage</p>
                            </Grid>
                            <div className={classes.input}>
                                <CustomInput
                                    id="BodyDamage"
                                    label="Body Damage"
                                    value={conditionReport.rearOfVehicle.bodyDamage.state}
                                    elementType="dropdown"
                                    onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"bodyDamage", key: "state"}, val, true)}
                                    values={loadingData.yesOrNotQuestions}
                                    showEmpty
                                />
                            </div>
                            
                            {
                                fieldNeedNote(DataPointType.Body_Damage, SectionType.Rear) &&
                                <div className={classes.input}>
                                    <CustomInput
                                        id="BodyDamageNotes"
                                        label="Notes..."
                                        value={conditionReport.rearOfVehicle.bodyDamage.detail} 
                                        elementType="textarea"
                                        onChange={val => changeOnSection({ arrayName: "rearOfVehicle", object:"bodyDamage", key: "detail"}, val)}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    }
                    
                </div>;
    }

    const RearPassengerSideOfVehicleSection = () => {
        return <div>
                <div className={classes.sectionTitle}>
                    Rear Passenger Side Of Vehicle
                </div>

    {
                isFieldActive(DataPointType.Door_Operational_Includes_Sliding_Door_, SectionType.Passenger_Rear) &&
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Door Broken/Damage (Includes Sliding Door)</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="doorOperational"
                            label="Door Broken/Damage (Includes Sliding Door)"
                            value={conditionReport.rearPassengerSideOfVehicle.doorOperational.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "rearPassengerSideOfVehicle", object:"doorOperational", key: "state"}, val, true)}
                            values={loadingData.yesOrNotOrNAQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Door_Operational_Includes_Sliding_Door_, SectionType.Passenger_Rear) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="doorOperational"
                                label="Notes..."
                                value={conditionReport.rearPassengerSideOfVehicle.doorOperational.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection(
                                                    { arrayName: "rearPassengerSideOfVehicle", object:"doorOperational", key: "detail"}
                                                    , val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Window_Damage, SectionType.Passenger_Rear) &&
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Window Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="passenserSideRearWindow"
                            label="Window Damage"
                            value={conditionReport.rearPassengerSideOfVehicle.passenserSideRearWindow.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "rearPassengerSideOfVehicle", object:"passenserSideRearWindow", key: "state"}, val, true)}
                            values={loadingData.yesOrNotOrNAQuestions}
                            showEmpty
                        />
                    </div>
                    {
                            fieldNeedNote(DataPointType.Window_Damage, SectionType.Passenger_Rear) && 
                             <div className={classes.input}>
                                <CustomInput
                                    id="PassenserSideRearWindowNotes"
                                    label="Notes..."
                                    value={conditionReport.rearPassengerSideOfVehicle.passenserSideRearWindow.detail} 
                                    elementType="textarea"
                                    onChange={val => changeOnSection({ arrayName: "rearPassengerSideOfVehicle", object:"passenserSideRearWindow", key: "detail"}, val)}
                                    required={true}
                                />
                            </div>
                        }
                </div>
    }
{
                isFieldActive(DataPointType.Tire_Damage, SectionType.Passenger_Rear) &&
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Tire Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="TireDamage"
                            label="Tire Damage"
                            value={conditionReport.rearPassengerSideOfVehicle.tireDamage.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "rearPassengerSideOfVehicle", object:"tireDamage", key: "state"}, val, true)}
                            values={loadingData.yesOrNotOrNAQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Tire_Damage, SectionType.Passenger_Rear) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="TireDamageNotes"
                                label="Notes..."
                                value={conditionReport.rearPassengerSideOfVehicle.tireDamage.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "rearPassengerSideOfVehicle", object:"tireDamage", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
    {
                isFieldActive(DataPointType.Tire_Tread_Measurement, SectionType.Passenger_Rear) &&
                <div className={classes.subSectionContainerPhoto}>
                    <Grid item xs={12}>
                        <Grid item xs={12} className={classes.subSectionContainerTitle}>
                            <p className={classes.subSectionTitle}>Tire Tread Measurement </p>
                        </Grid>
                        <div className={classes.input}>
                            <CustomInput
                                id="TireTreadMeasurement"
                                label="Tire Tread Measurement"
                                value={conditionReport.rearPassengerSideOfVehicle.passengerSideRearTireTreadMeasurement.state}
                                elementType="dropdown"
                                onChange={val => changeOnSection({ arrayName: "rearPassengerSideOfVehicle", object:"passengerSideRearTireTreadMeasurement", key: "state"}, val, true)}
                                values={loadingData.TireTreadMeasurements}
                                showEmpty
                            />
                        </div>
                        {
                        fieldNeedNote(DataPointType.Tire_Tread_Measurement, SectionType.Passenger_Rear) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="passengerSideRearTireTreadMeasurement"
                                label="Notes..."
                                value={conditionReport.rearPassengerSideOfVehicle.passengerSideRearTireTreadMeasurement.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "rearPassengerSideOfVehicle", object:"passengerSideRearTireTreadMeasurement", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                        }
                    </Grid>
                </div>
    }
{
                isFieldActive(DataPointType.Brake_Measurement, SectionType.Passenger_Rear) &&
                <div className={classes.subSectionContainerPhoto}>
                    <Grid item xs={12}>
                        <Grid item xs={12} className={classes.subSectionContainerTitle}>
                            <p className={classes.subSectionTitle}>Brake Measurement </p>
                        </Grid>
                        <div className={classes.input}>
                            <CustomInput
                                id="BrakeMeasurement"
                                label="Brake Measurement"
                                value={conditionReport.rearPassengerSideOfVehicle.passengerSideFrontBrakeMeasurement.state}
                                elementType="dropdown"
                                onChange={val => changeOnSection({ arrayName: "rearPassengerSideOfVehicle", object:"passengerSideFrontBrakeMeasurement", key: "state"}, val, true)}
                                values={loadingData.BrakeMeasurements}
                                showEmpty
                            />
                        </div>
                        {
                        fieldNeedNote(DataPointType.Brake_Measurement, SectionType.Passenger_Rear) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="passengerSideFrontBrakeMeasurement"
                                label="Notes..."
                                value={conditionReport.rearPassengerSideOfVehicle.passengerSideFrontBrakeMeasurement.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "rearPassengerSideOfVehicle", object:"passengerSideFrontBrakeMeasurement", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                        }
                    </Grid>
                </div>
    }
{
                isFieldActive(DataPointType.Body_Damage, SectionType.Passenger_Rear) &&
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Body Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="BodyDamage"
                            label="Body Damage"
                            value={conditionReport.rearPassengerSideOfVehicle.bodyDamage.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "rearPassengerSideOfVehicle", object:"bodyDamage", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Body_Damage, SectionType.Passenger_Rear) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="BodyDamageNotes"
                                label="Notes..."
                                value={conditionReport.rearPassengerSideOfVehicle.bodyDamage.detail} 
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "rearPassengerSideOfVehicle", object:"bodyDamage", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
            </div>;
    }

    const FrontPassengerSideOfVehicleSection = () => {
        return <div>
                <div className={classes.sectionTitle}>
                    Front Passenger Side Of Vehicle
                </div>
{
    isFieldActive(DataPointType.Door_Operational, SectionType.Front_Passenger) && 

    <div className={classes.subSectionContainer}>
        <Grid item xs={12} className={classes.subSectionContainerTitle}>
            <p className={classes.subSectionTitle}>Door Broken/Damage</p>
        </Grid>
        <div className={classes.input}>
            <CustomInput
                id="doorOperational"
                label="Door Broken/Damage"
                value={conditionReport.frontPassengerSideOfVehicle.doorOperational.state}
                elementType="dropdown"
                onChange={val => changeOnSection({ arrayName: "frontPassengerSideOfVehicle", object:"doorOperational", key: "state"}, val, true)}
                values={loadingData.yesOrNotQuestions}
                showEmpty
            />
        </div>
        {
            fieldNeedNote(DataPointType.Door_Operational, SectionType.Front_Passenger) && 
            <div className={classes.input}>
                <CustomInput
                    id="doorOperational"
                    label="Notes..."
                    value={conditionReport.frontPassengerSideOfVehicle.doorOperational.detail}
                    elementType="textarea"
                    onChange={val => changeOnSection(
                                        { arrayName: "frontPassengerSideOfVehicle", object:"doorOperational", key: "detail"}
                                        , val)}
                    required={true}
                />
            </div>
        }
    </div>
}
                
    {
    isFieldActive(DataPointType.Sideview_Mirror_Damage, SectionType.Front_Passenger) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Sideview Mirror Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="passengerFrontSideviewMirror"
                            label="Sideview Mirror Damage"
                            value={conditionReport.frontPassengerSideOfVehicle.passengerFrontSideviewMirror.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "frontPassengerSideOfVehicle", object:"passengerFrontSideviewMirror", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Sideview_Mirror_Damage, SectionType.Front_Passenger) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="passengerFrontSideviewMirrorNotes"
                                label="Notes..."
                                value={conditionReport.frontPassengerSideOfVehicle.passengerFrontSideviewMirror.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "frontPassengerSideOfVehicle", object:"passengerFrontSideviewMirror", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
    {
    isFieldActive(DataPointType.Window_Damage, SectionType.Front_Passenger) &&
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Window Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="passengerFrontWindow"
                            label="Window Damage"
                            value={conditionReport.frontPassengerSideOfVehicle.passengerFrontWindow.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "frontPassengerSideOfVehicle", object:"passengerFrontWindow", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Window_Damage, SectionType.Front_Passenger) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="passengerFrontWindowNotes"
                                label="Notes..."
                                value={conditionReport.frontPassengerSideOfVehicle.passengerFrontWindow.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "frontPassengerSideOfVehicle", object:"passengerFrontWindow", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
    {
    isFieldActive(DataPointType.Tire_Damage, SectionType.Front_Passenger) &&
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Tire Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="TireDamage"
                            label="Tire Damage"
                            value={conditionReport.frontPassengerSideOfVehicle.tireDamage.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "frontPassengerSideOfVehicle", object:"tireDamage", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Tire_Damage, SectionType.Front_Passenger) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="TireDamageNotes"
                                label="Notes..."
                                value={conditionReport.frontPassengerSideOfVehicle.tireDamage.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "frontPassengerSideOfVehicle", object:"tireDamage", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }

    {
    isFieldActive(DataPointType.Tire_Tread_Measurement, SectionType.Front_Passenger) &&
                <div className={classes.subSectionContainerPhoto}>
                    <Grid item xs={12}>
                        <Grid item xs={12} className={classes.subSectionContainerTitle}>
                            <p className={classes.subSectionTitle}>Tire Tread Measurement </p>
                        </Grid>
                        <div className={classes.input}>
                            <CustomInput
                                id="TireTreadMeasurement"
                                label="Tire Tread Measurement"
                                value={conditionReport.frontPassengerSideOfVehicle.passengerSideFrontTireTreadMeasurement.state}
                                elementType="dropdown"
                                onChange={val => changeOnSection({ arrayName: "frontPassengerSideOfVehicle", object:"passengerSideFrontTireTreadMeasurement", key: "state"}, val, true)}
                                values={loadingData.TireTreadMeasurements}
                                showEmpty
                            />
                        </div>
                        {
                        fieldNeedNote(DataPointType.Tire_Tread_Measurement, SectionType.Front_Passenger) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="passengerSideFrontTireTreadMeasurement"
                                label="Notes..."
                                value={conditionReport.frontPassengerSideOfVehicle.passengerSideFrontTireTreadMeasurement.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "frontPassengerSideOfVehicle", object:"passengerSideFrontTireTreadMeasurement", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                    </Grid>
                </div>
    }
    {
    isFieldActive(DataPointType.Brake_Measurement, SectionType.Front_Passenger) &&
                <div className={classes.subSectionContainerPhoto}>
                    <Grid item xs={12}>
                        <Grid item xs={12} className={classes.subSectionContainerTitle}>
                            <p className={classes.subSectionTitle}>Brake Measurement </p>
                        </Grid>
                        <div className={classes.input}>
                            <CustomInput
                                id="BrakeMeasurement"
                                label="Brake Measurement"
                                value={conditionReport.frontPassengerSideOfVehicle.passengerSideRearBrakeMeasurement.state}
                                elementType="dropdown"
                                onChange={val => changeOnSection({ arrayName: "frontPassengerSideOfVehicle", object:"passengerSideRearBrakeMeasurement", key: "state"}, val, true)}
                                values={loadingData.BrakeMeasurements}
                                showEmpty
                            />
                        </div>
                        {
                        fieldNeedNote(DataPointType.Brake_Measurement, SectionType.Front_Passenger) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="passengerSideRearBrakeMeasurement"
                                label="Notes..."
                                value={conditionReport.frontPassengerSideOfVehicle.passengerSideRearBrakeMeasurement.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "frontPassengerSideOfVehicle", object:"passengerSideRearBrakeMeasurement", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                    </Grid>
                </div>
    }
    {
    isFieldActive(DataPointType.Body_Damage, SectionType.Front_Passenger) &&
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Body Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="BodyDamage"
                            label="Body Damage"
                            value={conditionReport.frontPassengerSideOfVehicle.bodyDamage.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "frontPassengerSideOfVehicle", object:"bodyDamage", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Body_Damage, SectionType.Front_Passenger) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="BodyDamageNotes"
                                label="Notes..."
                                value={conditionReport.frontPassengerSideOfVehicle.bodyDamage.detail} 
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "frontPassengerSideOfVehicle", object:"bodyDamage", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
            </div>;
    }

    const InteriorDataPointsSection = () => {
        return <div>
                <div className={classes.sectionTitle}>
                    Interior Data Points
                </div>
    {
                isFieldActive(DataPointType.Rearview_Mirror_Damage, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Rearview Mirror Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="rearviewMirror"
                            label="Rearview Mirror Damage"
                            value={conditionReport.interiorDataPoints.rearviewMirror.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearviewMirror", key: "state"}, val, true)}
                            values={loadingData.yesOrNotOrNAQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Rearview_Mirror_Damage, SectionType.Front) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="RearviewMirrorNotes"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.rearviewMirror.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearviewMirror", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                    
                </div>
    }
{
                isFieldActive(DataPointType.Main_Dome_Light_Working, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Main Dome Light Broken</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="mainDomeLightWorking"
                            label="Main Dome Light Broken"
                            value={conditionReport.interiorDataPoints.mainDomeLightWorking.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"mainDomeLightWorking", key: "state"}, val)}
                            values={loadingData.yesOrNotOrNAQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Main_Dome_Light_Working, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="mainDomeLightWorking"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.mainDomeLightWorking.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"mainDomeLightWorking", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Odometer, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Odometer</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="Odometer"
                            label="Odometer"
                            value={conditionReport.interiorDataPoints.odometer.detail}
                            elementType="input"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"odometer", key: "detail"}, val)}
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Odometer, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="odometer"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.odometer.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"odometer", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Fuel_Level, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Fuel Level</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="fuelLevel"
                            label="Fuel Level"
                            value={conditionReport.interiorDataPoints.fuelLevel.detail}
                            elementType="input"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"fuelLevel", key: "detail"}, val)}
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Fuel_Level, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="fuelLevel"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.fuelLevel.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"fuelLevel", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Check_Engine_Light, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Check Engine Light</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="checkEngineLight"
                            label="Check Engine Light"
                            value={conditionReport.interiorDataPoints.checkEngineLight.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"checkEngineLight", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Check_Engine_Light, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="checkEngineLight"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.checkEngineLight.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"checkEngineLight", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Other_Service_Lights, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Other Service Lights Issues</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="otherServiceLight"
                            label="Other Service Lights Issues"
                            value={conditionReport.interiorDataPoints.otherServiceLight.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"otherServiceLight", key: "state"}, val)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Other_Service_Lights, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="otherServiceLightNotes"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.otherServiceLight.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"otherServiceLight", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Vehicle_Starts_and_Operates, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Vehicle Starts and Operates</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="vehicleStartsOperate"
                            label="Vehicle Starts and Operates"
                            value={conditionReport.interiorDataPoints.vehicleStartsOperate.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"vehicleStartsOperate", key: "state"}, val)}
                            values={loadingData.yesOrNotOrNAQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Vehicle_Starts_and_Operates, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="vehicleStartsOperate"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.vehicleStartsOperate.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"vehicleStartsOperate", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Radio_Working, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Radio Issues</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="radioWorking"
                            label="Radio Issues"
                            value={conditionReport.interiorDataPoints.radioWorking.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"radioWorking", key: "state"}, val)}
                            values={loadingData.yesOrNotOrNAQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Radio_Working, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="radioWorking"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.radioWorking.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"radioWorking", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Defroster_Working, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Defroster Issues</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="defrosterWorking"
                            label="Defroster Issues"
                            value={conditionReport.interiorDataPoints.defrosterWorking.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"defrosterWorking", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Defroster_Working, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="defrosterWorking"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.defrosterWorking.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"defrosterWorking", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Air_Conditioning_Working_, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Air Conditioning Issues</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="airConditioningWorking"
                            label="Air Conditioning Issues"
                            value={conditionReport.interiorDataPoints.airConditioningWorking.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"airConditioningWorking", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Air_Conditioning_Working_, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="airConditioningWorking"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.airConditioningWorking.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"airConditioningWorking", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Heat_Working, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Heat Issues</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="heatWorking"
                            label="Heat Issues"
                            value={conditionReport.interiorDataPoints.heatWorking.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"heatWorking", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Heat_Working, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="heatWorking"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.heatWorking.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"heatWorking", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Driver_Side_Seat_Damage, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Driver Side Seat Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="seatDamage"
                            label="Driver Side Seat Damage"
                            value={conditionReport.interiorDataPoints.seatDamage.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"seatDamage", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Driver_Side_Seat_Damage, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="SeatDamageNotes"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.seatDamage.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"seatDamage", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Driver_Side_Floor_Damage, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Driver Side Floor Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="floorCarpetDamage"
                            label="Driver Side Floor Damage"
                            value={conditionReport.interiorDataPoints.floorCarpetDamage.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"floorCarpetDamage", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Driver_Side_Floor_Damage, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="floorCarpetDamageNotes"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.floorCarpetDamage.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"floorCarpetDamage", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Driver_Side_Seat_Belt_Damage, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Driver Side Seat Belt Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="seatBeltIssue"
                            label="Driver Side Seat Belt Damage"
                            value={conditionReport.interiorDataPoints.seatBeltIssue.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"seatBeltIssue", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Driver_Side_Seat_Belt_Damage, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="SeatBeltIssueNotes"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.seatBeltIssue.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"seatBeltIssue", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Rear_Driver_Side_Seat_Damage, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Rear Driver Side Seat Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="rearDriverSideSeatDamage"
                            label="Rear Driver Side Seat Damage"
                            value={conditionReport.interiorDataPoints.rearDriverSideSeatDamage.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearDriverSideSeatDamage", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Rear_Driver_Side_Seat_Damage, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="rearDriverSideSeatDamageNotes"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.rearDriverSideSeatDamage.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearDriverSideSeatDamage", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Rear_Driver_Side_Floor_Damage, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Rear Driver Side Floor Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="rearDriverSideFloorDamage"
                            label="Rear Driver Side Floor Damage"
                            value={conditionReport.interiorDataPoints.rearDriverSideFloorDamage.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearDriverSideFloorDamage", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Rear_Driver_Side_Floor_Damage, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="rearDriverSideFloorDamageNotes"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.rearDriverSideFloorDamage.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearDriverSideFloorDamage", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Rear_Driver_Side_Seat_Belt_Damage, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Rear Driver Side Seat Belt Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="rearDriverSideSeatBeltDamage"
                            label="Rear Driver Side Seat Belt Damage"
                            value={conditionReport.interiorDataPoints.rearDriverSideSeatBeltDamage.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearDriverSideSeatBeltDamage", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Rear_Driver_Side_Seat_Belt_Damage, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="rearDriverSideSeatBeltDamageNotes"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.rearDriverSideSeatBeltDamage.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearDriverSideSeatBeltDamage", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Rear_Passenger_Side_Seat_Damage, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Rear Passenger Side Seat Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="rearPassengerSideSeatDamage"
                            label="Rear Passenger Side Seat Damage"
                            value={conditionReport.interiorDataPoints.rearPassengerSideSeatDamage.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearPassengerSideSeatDamage", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Rear_Passenger_Side_Seat_Damage, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="rearPassengerSideSeatDamageNotes"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.rearPassengerSideSeatDamage.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearPassengerSideSeatDamage", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Rear_Passenger_Side_Floor_Damage, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Rear Passenger Side Floor Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="rearPassengerSideFloorDamage"
                            label="Rear Passenger Side Floor Damage"
                            value={conditionReport.interiorDataPoints.rearPassengerSideFloorDamage.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearPassengerSideFloorDamage", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Rear_Passenger_Side_Floor_Damage, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="rearPassengerSideFloorDamageNotes"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.rearPassengerSideFloorDamage.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearPassengerSideFloorDamage", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
{
                isFieldActive(DataPointType.Rear_Passenger_Side_Seat_Belt_Damage, SectionType.Interior) && 
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Rear Passenger Side Seat Belt Damage</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="rearPassengerSideSeatBeltDamage"
                            label="Rear Passenger Side Seat Belt Damage"
                            value={conditionReport.interiorDataPoints.rearPassengerSideSeatBeltDamage.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearPassengerSideSeatBeltDamage", key: "state"}, val, true)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>
                    {
                        fieldNeedNote(DataPointType.Rear_Passenger_Side_Seat_Belt_Damage, SectionType.Interior) && 
                        <div className={classes.input}>
                            <CustomInput
                                id="rearPassengerSideSeatBeltDamageNotes"
                                label="Notes..."
                                value={conditionReport.interiorDataPoints.rearPassengerSideSeatBeltDamage.detail}
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "interiorDataPoints", object:"rearPassengerSideSeatBeltDamage", key: "detail"}, val)}
                                required={true}
                            />
                        </div>
                    }
                </div>
    }
            </div>;
    }

    const reducePageNumber = () => {
        setPageNumber(pageNumber - 1);
        scrollToTop();
    }

    const nextPageNumber = () => {
        setPageNumber(pageNumber + 1)
        scrollToTop();
    }

    const paginationData = {
        SaveAndPrevious: 1,
        SaveAndNext: 2,
        SaveAndExit: 3,
        SaveWithoutPrevious: 4
    }

    const pagination = () => {
        return <div>
            <Grid item xs={12} style={{display:"flex", justifyContent:"center"}}>
                {
                    pageNumber == 1 
                    ? <Button className={classes.button} variant="contained" onClick={() => SaveConditionReport(paginationData.SaveWithoutPrevious)}>Save</Button>
                    : <Button className={classes.button} variant="contained" disabled={isSavingSections} onClick={() => SaveConditionReport(paginationData.SaveAndPrevious)}>Save and Previous</Button>
                }
                <Button className={classes.button} variant="contained" disabled={true}>{pageNumber} Of {pageConfiguration.length}</Button>
                {
                    pageNumber == pageConfiguration.length 
                    ? <Button className={classes.button} variant="contained" onClick={() => SaveConditionReport(paginationData.SaveAndExit)}>Save</Button>
                    : <Button className={classes.button} variant="contained" disabled={isSavingSections} onClick={() => SaveConditionReport(paginationData.SaveAndNext)}>Save and Next</Button>
                }
            </Grid>
            <Grid item xs={12} style={{display:"flex", justifyContent:"center"}}>
                <Button className={classes.button} variant="outlined" disabled={isSavingSections} onClick={() => SaveConditionReport(paginationData.SaveAndExit)}>Save and Exit</Button>
            </Grid>
        </div>
    }

    const isFieldActive = (dataPointType_p, sectionType_p) => {
        const fieldConfig = clientsCheckIn.find((fieldConf) => fieldConf.sectionTypeId === sectionType_p && fieldConf.dataPointTypeId === dataPointType_p && fieldConf.isActive === true);
        if (fieldConfig) return true;
        return false;
    }

    const convertToSentenceCase = (str) => {
        if (str)
            return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
    };
    const populatDecoded = () => {
        setOpenDecoded(false);
        if (decodedData) {
            setColorDecoded(true);
            if (decodedData?.vehicleType)
                setColorDecodedType(true);

            setEditedAsset({
                ...editedAsset,
                year: decodedData?.year,
                make: convertToSentenceCase(decodedData?.make),
                model: decodedData?.model,
                ...(decodedData?.vehicleType && { vehicleTypeId: decodedData.vehicleType }),
            });

            setTimeout(() => {
                setColorDecoded(false);
            }, 5000);
        }
    }


    const HeaderSection = () => {
        return <div>
                    <div className={classes.sectionTitle}>
                        Header Section
                    </div>

                    {
                        isFieldActive(DataPointType.Client, SectionType.Header) && 
                        <div className={classes.input}>
                        <CustomInput
                            id="client"
                            label="Client"
                            value={editedAsset.customerId}
                            elementType="dropdown"
                            onChange={val => onChange("customerId", +val)}
                            values={clientAccounts}
                        />
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Asset_ID, SectionType.Header) && 
                        <div className={classes.input}>
                                <Grid>
                                    <CustomInput
                                        id="assetId"
                                        label="Asset ID"
                                        value={editedAsset.assetId}
                                        elementType="input"
                                        onChange={val => onChange("assetId", val)}
                                    />
                                    {!!editedAsset?.id && <VehicleMatches
                                        title={'This asset id already exists in ParQ, click for more details'}
                                        currentId={editedAsset?.id}
                                        assetId={editedAsset.assetId}
                                        clientAccountId={editedAsset.customerId}
                                    />}
                                </Grid>
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Vin, SectionType.Header) && 
                        <div className={classes.input}>
                            <Grid xs={9}>
                                <div className={classes.vinInput}>
                                    <CustomInput
                                        id="vin"
                                        label="VIN"
                                        value={editedAsset.descriptor}
                                        elementType="input"
                                        required={true}
                                        onChange={val => onChange("descriptor", val)}
                                    />
                                    {!!editedAsset?.id && <VehicleMatches
                                        title={'This VIN already exists in ParQ, click for more details'}
                                        currentId={editedAsset?.id}
                                        vin={editedAsset.descriptor}
                                        clientAccountId={editedAsset.customerId}
                                    />}
                                </div>
                            </Grid>
                            <Grid >
                                <VinDecode vin={editedAsset?.descriptor}
                                           setDecodedData={setDecodedData}
                                           applyDecode={populatDecoded}
                                           openDecoded={openDecoded}
                                           setOpenDecoded={setOpenDecoded} />
                            </Grid>
                        </div>
                    }
                    <Grid container>
                    {
                        isFieldActive(DataPointType.Vehicle_Year, SectionType.Header) &&
                            <Grid item xs={6}>
                                <div className={classes.input}>
                                    <CustomInput
                                        id="year"
                                        label="Vehicle Year"
                                        value={editedAsset.year}
                                        elementType="input"
                                        onChange={val => onChange("year", val)}
                                    />
                                </div>
                        </Grid>
                    }
                    {
                        isFieldActive(DataPointType.Vehicle_Make, SectionType.Header) &&
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="make"
                                    label="Vehicle Make"
                                    value={editedAsset.make}
                                    elementType="input"
                                    onChange={val => onChange("make", val)}
                                />
                            </div>
                        </Grid>
                    }
                    </Grid>
                    <Grid container>
                    {
                        isFieldActive(DataPointType.Vehicle_Model, SectionType.Header) &&
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="model"
                                    label="Vehicle Model"
                                    value={editedAsset.model}
                                    elementType="input"
                                    onChange={val => onChange("model", val)}
                                />
                            </div>
                        </Grid>
                    }
                    </Grid>
                    {
                        isFieldActive(DataPointType.Vehicle_Color, SectionType.Header) &&
                        <div className={classes.input}>
                            <Grid>
                                <CustomInput
                                    id="color"
                                    label="Vehicle Color"
                                    value={editedAsset.color}
                                    elementType="input"
                                    onChange={val => onChange("color", val)}
                                />
                            </Grid>
                        </div>
                    }
                    {
                        isFieldActive(DataPointType.Vehicle_Type, SectionType.Header) &&
                        <div className={classes.input}>
                            <CustomInput
                                id="type"
                                label="Vehicle Type"
                                value={editedAsset.vehicleTypeId}
                                elementType="dropdown"
                                onChange={val => onChange("vehicleTypeId", +val)}
                                values={vehicleTypes}
                            />
                        </div>
                    }
                    <Grid container>
                    {
                        isFieldActive(DataPointType.Carrier, SectionType.Header) &&
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="carrier"
                                    label="Carrier"
                                    value={editedAsset.driverCarrierId}
                                    elementType="dropdown"
                                    onChange={val => onChange("driverCarrierId", +val)}
                                    values={[{ id: null, name: '' }, ...vendors]}
                                />
                            </div>
                        </Grid>
                    }
                    {
                        isFieldActive(DataPointType.Driver_Name, SectionType.Header) &&
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="driver"
                                    label="Driver"
                                    elementType="dropdown"
                                    values={drivers}
                                    value={editedAsset.driverId}
                                    showEmpty
                                    onChange={val => onChange("driverId", +val)}
                                />
                            </div>
                        </Grid>
                    }
                    </Grid>
                </div>;
    }

    const MiscSection = () => {
        return <div>
            <div className={classes.sectionTitle}>
                Miscellaneous
            </div>

            {
                isFieldActive(DataPointType.License_Plate, SectionType.Misc) && 
                <Grid container className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>License Plate</p>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.input}>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={editedAsset.hasLicensePlate ?? false}
                                    onChange={(e, value) => onChange("hasLicensePlate", value)}
                                />}
                                label="Has license plate" />
                        </div>
                    </Grid>
                    {editedAsset.hasLicensePlate && <><Grid item xs={3}>
                        <div className={classes.input}>
                            <CustomInput
                                id="license"
                                label="License Plate"
                                value={editedAsset.licensePlate ?? ''}
                                elementType="input"
                                onChange={val => onChange("licensePlate", val)}
                                valid={!mandatoryFields?.['licensePlate']}
                                touched={true}
                            />
                        </div>
                    </Grid>
                        <Grid item xs={3}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="licensePlateState"
                                    label="License Plate State"
                                    value={editedAsset.licensePlateState}
                                    elementType="dropdown"
                                    onChange={val => onChange("licensePlateState", val)}
                                    values={[{ name: '', id: null }, ...VehicleService.getUsStates()]}
                                    valid={!mandatoryFields?.['licensePlateState']}
                                    touched={true}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3} >
                            <div className={classes.input}>
                                <CustomInput
                                    id="licensePlateType"
                                    label="License Type"
                                    value={editedAsset.licensePlateType}
                                    elementType="dropdown"
                                    onChange={val => onChange("licensePlateType", val)}
                                    values={licensePlateTypesList}
                                    valid={!mandatoryFields?.['licensePlateType']}
                                    touched={true}
                                    showEmpty
                                />
                            </div>
                        </Grid>
                        </>}
                </Grid>
            }

            {
                isFieldActive(DataPointType.Registration, SectionType.Misc) &&
                <Grid container className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Registration</p>
                    </Grid>
                    <Grid item xs={8} container>
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={editedAsset.hasRegistration ?? false}
                                        onChange={(e, value) => {
                                            if (!value) {
                                                onChange("registrationExpired", false)
                                                onChange("registrationYear", null)
                                                onChange("registrationMonth", null)
                                            }
                                            onChange("hasRegistration", value);
                                        }
                                        }
                                    />}
                                    label="Has registration" />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.input}>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={editedAsset.registrationExpired ?? false}
                                        onChange={(e, value) => onChange("registrationExpired", value)}
                                    />}
                                    label="Expired" />
                            </div>
                        </Grid>
                    </Grid>
                    {editedAsset.hasRegistration && <><Grid item xs={2}>
                        <div className={classes.input}>
                            <CustomInput
                                id="regDateYear"
                                label="Year"
                                value={editedAsset.registrationYear}
                                elementType="dropdown"
                                onChange={val => onChange("registrationYear", val)}
                                values={[{ name: '', id: null }, ...FormatService.getYears(30)?.map(y => ({ id: y, name: y }))]}
                                valid={!mandatoryFields?.['registrationYear']}
                                touched={true}
                            />
                        </div>
                    </Grid>
                        <Grid item xs={2}>
                            <div className={classes.input}>
                                <CustomInput
                                    id="regDateMonth"
                                    label="Month"
                                    value={editedAsset.registrationMonth}
                                    elementType="dropdown"
                                    onChange={val => onChange("registrationMonth", val)}
                                    values={[{ name: '', id: null }, ...FormatService.getMonths()]}
                                />
                            </div>
                        </Grid>
                    </>
                    }
                </Grid>
            }

            {
                isFieldActive(DataPointType.Keys_Handed_Over, SectionType.Misc) &&
                <div className={classes.subSectionContainerPhoto}>
                    <Grid item xs={12}>
                        <Grid item xs={12} className={classes.subSectionContainerTitle}>
                            <p className={classes.subSectionTitle}>Keys and Fobs</p>
                        </Grid>

                        <div className={classes.input}>
                            <CustomInput
                                id="KeysHandedOver"
                                label="Keys Handed Over"
                                value={conditionReport.misc.keysHandedOver.state}
                                elementType="input"
                                onChange={val => changeOnSection({ arrayName: "misc", object:"keysHandedOver", key: "state"}, val, true)}
                                showEmpty
                            />
                        </div>

                        <div className={classes.input}>
                            <CustomInput
                                id="FobsHandedOver"
                                label="Fobs Handed Over"
                                value={conditionReport.misc.fobsHandedOver.state}
                                elementType="input"
                                onChange={val => changeOnSection({ arrayName: "misc", object:"fobsHandedOver", key: "state"}, val, true)}
                                showEmpty
                            />
                        </div>
                        
                    </Grid>
                </div>
            }

            {
                isFieldActive(DataPointType.DOT_Inspection_Sticker, SectionType.Misc) &&
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>DOT Inspection Sticker</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="InspectionSticker"
                            label="DOT Inspection Sticker"
                            value={conditionReport.misc.dotInspectionSticker.state}
                            elementType="dropdown"
                            onChange={val => changeOnSection({ arrayName: "misc", object:"dotInspectionSticker", key: "state"}, val)}
                            values={loadingData.yesOrNotQuestions}
                            showEmpty
                        />
                    </div>

                    {
                        <div className={classes.input}>
                            <CustomInput
                                id="InspectionStickerNotes"
                                label="Notes..."
                                value={conditionReport.misc.dotInspectionSticker.detail} 
                                elementType="textarea"
                                onChange={val => changeOnSection({ arrayName: "misc", object:"dotInspectionSticker", key: "detail"}, val)}
                                required={true}
                            />
                            {/* <AddPhotoCR
                                    limit={2}
                                    newImages={conditionReport.misc.dotInspectionSticker.image || []}
                                    existingImages={[]}
                                    addImage={addImageCR}
                                    label='Add Picture'
                                    onDelete={handleDelete}
                                    sectionParent='misc'
                                    sectionChild='dotInspectionSticker'
                                    attachmentPhoto='attachmentPhoto'
                                    image='image'
                            /> */}
                        </div>
                    }
                </div>
            }

            {
                isFieldActive(DataPointType.Battery_Strength, SectionType.Misc) &&
                <div className={classes.subSectionContainer}>
                    <Grid item xs={12} className={classes.subSectionContainerTitle}>
                        <p className={classes.subSectionTitle}>Battery Strenght</p>
                    </Grid>
                    <div className={classes.input}>
                        <CustomInput
                            id="batteryVolts"
                            label="Volts (to one decimal, xx.x)"
                            value={conditionReport.misc.batteryStrenght.detail} 
                            elementType="textarea"
                            onChange={val => changeOnSection({ arrayName: "misc", object:"batteryStrenght", key: "detail"}, val)}
                            required={true}
                        />
                    </div>
                </div>
            }

            {
                isFieldActive(DataPointType.Brake_Measurement, SectionType.Misc) &&
                <div className={classes.subSectionContainerPhoto}>
                    <Grid item xs={12}>
                        <Grid item xs={12} className={classes.subSectionContainerTitle}>
                            <p className={classes.subSectionTitle}>Brake Noise</p>
                        </Grid>

                        <div className={classes.input}>
                            <CustomInput
                                id="BrakeNoise"
                                label="Brake Noise"
                                value={conditionReport.misc.brakeNoise.state}
                                elementType="dropdown"
                                onChange={val => changeOnSection({ arrayName: "misc", object:"brakeNoise", key: "state"}, val, true)}
                                values={loadingData.yesOrNotQuestions}
                                showEmpty
                            />
                        </div>
                    </Grid>
                </div>
            }
        </div>
    }
    
    let pageConfiguration = [
        {header: 1},
        {Front: 2},
        {FrontDriverSide: 3},
        {DriverSideRear: 4},
        {Rear: 5},
        {PassengerRearSide: 6},
        {FrontPassengerSide: 7},
        //{Interior: 8},
        {InteriorDataPoints: 8},
        //{UnderCarriage: 10},
        {Miscellaneous: 9}
    ]

    const scrollDown = () => {
        const element = document.getElementById('bottomPage');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const isSectionEnable = (sectionType_p) => {
        const hasActive = clientsCheckIn.find((sectionConfig) => sectionConfig.sectionTypeId === sectionType_p 
                                                                 && sectionConfig.isActive === true);
        if (hasActive) return true;
        return false;
    }

    let form;
    if (error) {
        form = (
            <>
                <div className={classes.error}>There was an error saving the asset</div>
            </>
        )
    } else if (isLoading() || isSaving) {
        form = <CircularProgress className={classes.spinner} />
    } else {
        form = (
            <>
                <div id="topPage"></div>
                {
                    (pageNumber == 1 && isSectionEnable(SectionType.Header)) &&  HeaderSection()
                }
                                
                {
                    (pageNumber == 2  && isSectionEnable(SectionType.Front)) && FrontVehicleSection()
                }

                {
                    (pageNumber == 3 && isSectionEnable(SectionType.Front_Driver_Side)) && FrontDriverSideSection()
                }

                {
                    (pageNumber == 4 && isSectionEnable(SectionType.Driver_Side_Rear)) && RearDriverSideSection()
                }

                {
                    (pageNumber == 5 && isSectionEnable(SectionType.Rear)) && RearOfVehicleSection()
                }

                {
                    (pageNumber == 6 && isSectionEnable(SectionType.Passenger_Rear)) && RearPassengerSideOfVehicleSection()
                }

                {
                    (pageNumber == 7 && isSectionEnable(SectionType.Front_Passenger)) && FrontPassengerSideOfVehicleSection()
                }

                {
                    (pageNumber == 8 && isSectionEnable(SectionType.Interior)) && InteriorDataPointsSection()
                }

                {
                    (pageNumber == 9 && isSectionEnable(SectionType.Misc)) && MiscSection()
                }
                <div style={{position: "absolute", right: 0, bottom: 50}}>
                    <Button  variant="contained" className={classes.buttonFloat}  onClick={() => scrollDown()}>
                        <FontAwesomeIcon style={{color: "#0090fe", width:"20", height:"40"}} icon={faArrowDown} />
                    </Button>
                </div>
            </>
        );
    }

    return (
        <Paper className={classes.formContainer}>
            <form className={`${classes.form} ${isLoading() && classes.disabledForm}`}>
                {form}
            </form>
            {
                pagination()
            }
            <div id="bottomPage"></div>
        </Paper>
    );
};

export default ConditionReportPopup;
