import { permissionProfiles } from "components/Authorize/permissionProfiles";
import Authorize from 'components/Authorize';
import PageComponent from 'components/Page/PageComponent';
import FilterService, { fieldTypes } from "services/filterService";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from './taxRateFilterSlice';
import { useGetLotsQuery } from 'features/lots/lotSlice';
import DataListHeader from "components/DataListHeader";
import { Button, Grid } from '@material-ui/core';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import DataTable from "components/DataTable/DataTable";
import { TaxRateStyle } from "./TaxRateStyle";
import { orderBy } from "lodash";
import { useGetTaxRatesQuery } from './TaxRateSlice';
import useSortOptions from "hooks/useSortOptions";
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import TaxRateForm from './components/TaxRateForm'
import { isAuthorized } from "components/Authorize/authorizeUtil";

const TaxRatePage = () => {
    const classes = TaxRateStyle();
    const dispatch = useDispatch();

    let { data: lots, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: taxRates, isLoading: isLoadingTaxRates } = useGetTaxRatesQuery();

    lots = lots || [];
    taxRates = taxRates?.data || [];


    const history = useHistory();
    
    const { filters } = useSelector(state => state.taxRatesFilters);
    const [sortOptions, sortData] = useSortOptions();
    const [taxRateToEdit, setTaxRateToEdit] = useState(null);

    let filterConfig = [
        { type: fieldTypes.ASSOCIATED_LOT, size: 3, title: 'Lot', key: 'lotId', values: lots },
    ];

    const updateFilters = (prop, value) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    }

    const onDropFilter = (filterValue, prop) => {
        updateFilters(prop, filterValue);
    };

    taxRates = taxRates?.map(o => ({
        ...o,
        lot: lots?.find(l => l.id === o.lotId)?.name,
    }));

    let taxRateColumns = [
        { name: "Lot", key: "lot" },
        { name: "Mechanical Rate", key: "mechanicalRate" },
        { name: "Storage Rate", key: "storageRate" }];

    const onEdit = (id) => {
        setTaxRateToEdit(taxRates?.find(o => o.id === id));
    };

    const rowActions = (rowId) => ([
        {
            icon: <EditIcon />,
            onClick: () => onEdit(rowId),
            profile: permissionProfiles.TAX_RATE.TAX_FULL
        }
    ]);

    taxRates = taxRates?.map(t => ({
        ...t,
        lot: lots?.find(l => l.id === t.lotId)?.name,
    }));

    const isLoading = () => isLoadingLots || isLoadingTaxRates;

    let filteredTaxRates = FilterService.filter(filters, null, taxRates, filterConfig);

    useEffect(() => {
        let filterField = history?.location?.state?.params?.filters;
        if (filterField) {
            for (let i in filterField) {
                updateFilters(i, filterField[i]);
            }
        }
    }, [])

    return (
        <Authorize profile={permissionProfiles.TAX_RATE.TAX_VIEW}>
            <PageComponent
                header={<DataListHeader
                    title={'Tax Rates'}
                    data={taxRates}
                    dropDownsConfig={filterConfig}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    actionButtons={(
                        <Authorize profile={permissionProfiles.TAX_RATE.TAX_FULL}>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Button variant='contained' color='primary' onClick={() => history.push(`/taxrate/new`)}>Add New Tax Rate</Button>
                                </Grid>
                            </Grid>
                        </Authorize>
                    )}
                />}
            >
                <>
                    {
                        isLoading() ? <LoadingSpinner loading={isLoading()} /> :
                            <div className={classes.table}>
                                <DataTable
                                    columns={taxRateColumns}
                                    rows={orderBy(
                                        filteredTaxRates,
                                        sortOptions.columnToSort,
                                        sortOptions.sortDirection
                                    )}
                                    rowIdentifier='id'
                                    onSort={sortData}
                                    sortDirection={sortOptions.sortDirection}
                                    columnToSort={sortOptions.columnToSort}
                                    noItemsMessage='There are no tax rates to display'
                                    actions={isAuthorized(permissionProfiles.TAX_RATE.TAX_FULL)? rowActions:null}
                                    maxActionCount={2}
                                />
                            </div>

                    }
                </>
                {window.location.pathname.includes('/new') && <TaxRateForm taxRateToEdit={null} />}
                {taxRateToEdit && <TaxRateForm taxRateToEdit={taxRateToEdit} />}
            </PageComponent>
        </Authorize>)
}

export default TaxRatePage;