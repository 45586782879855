import { Button, Checkbox, FormControlLabel, Grid, Paper } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import { useEffect, useState } from "react";
import Authorize from "../../../../../components/Authorize";
import { permissionProfiles } from "../../../../../components/Authorize/permissionProfiles";
import ResetPasswordDialog from "../resetPassword/resetPasswordDialog";
import SendRestorePasswordDialog from "../sendRestorePassword/sendRestorePasswordDialog";
import UsersPageStyle from "../UsersPageStyle";
import { selectRole } from "features/user/userSlice";
import { useSelector } from "react-redux";
import { userTypeEnums, userTypeOptionsEnums } from "features/users/UsersEnums";

const useStyles = UsersPageStyle;

const UserInformation = ({ user, roles, refetchUsers, userClaims, onCancel, handleSave, handleUserChange, editing = true, errorMessages,validateDataBeforeSend  }) => {
    const classes = useStyles();
    roles = roles || [];

    const [hasChanged, setHasChanged] = useState(false);
    const [saving, setSaving] = useState(false);
    const [roleId, setRoleId] = useState();
    const [fullName, setFullName] = useState(user?.name??"");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [userArchive, setUserArchive] = useState(user?.lockoutEnabled ?? false);
    const [userBlackList, setUserBlackList] = useState(user?.isBlackList ?? false);
    const [openResetDialog, setOpenResetDialog] = useState(false);
    const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
    const [userTypeId, setUserTypeId] = useState(user?.userType ?? 1);
    const [userRoleInfo, setUserRoleInfo] = useState(null);
    const userLoggedProfileRoleName = useSelector(selectRole);
    const [errorMessage, setErrorMessage] = useState("");

    const initialize = () => {
        if (user?.assignedRole) {
            setRoleId(user?.assignedRole[0]?.roleId ?? "");
        }
    }

    useEffect(() => {
        if (handleUserChange) {
            handleUserChange({
                fullName,
                roleId,
                userTypeId,
                userName,
                email,
                lockoutEnabled: userArchive,
                isBlackList: userBlackList,
            });
        }
    }, [fullName, roleId, userTypeId, userArchive, userBlackList, userName, email]);

    useEffect(() => {
        initialize();
    }, [])

    useEffect(() => {
        initialize();
    }, [userClaims, user])


    useEffect(() => {
        if (roles) {
            const myRoleInfo = [...roles]?.find(x => x.name === userLoggedProfileRoleName);
            setUserRoleInfo(myRoleInfo);
        }
    }, [roles])

    
    const getErrorMessage = (field) => {
        return "* " + errorMessages[field]
    }

    const handleUserInfoSave = async () => {
        setSaving(true);
        setErrorMessage("");
        
        const validateData = validateDataBeforeSend()

        if (!validateData) {
            setSaving(false);
            return false;
        }

        if (handleSave) {
            await handleSave();
        }
        
        setSaving(false);
        setHasChanged(false);
    }

    const getRoleValues = () => {
        let rolesCanISee = [];
        let rolesAssigment = userRoleInfo?.rolesIdsVisibles ? [...userRoleInfo?.rolesIdsVisibles] : [];
        let allRolesInformationCopy = [...roles]?.filter(x => x.key !== userRoleInfo?.key) ?? [...roles];
        if (rolesAssigment?.length === 0) return [];

        if (rolesAssigment) {
            allRolesInformationCopy = allRolesInformationCopy.filter((rol) => rolesAssigment.some(x => x === rol.id.toString()));
            allRolesInformationCopy.push(userRoleInfo);
        }

        if (userTypeId) {
            let typeId = parseInt(userTypeId ?? 1)
            if (typeId === userTypeEnums.InternalPMF) {
                rolesCanISee = allRolesInformationCopy?.filter(y => y.isRoleForClient === false);
            }
            if (typeId === userTypeEnums.Client) {
                rolesCanISee = allRolesInformationCopy?.filter(y => y.isRoleForClient === true);
            }
            return rolesCanISee.map(x => ({ name: x.name, id: x.key }));
        }

        rolesCanISee = allRolesInformationCopy;
        return rolesCanISee.map(x => ({ name: x.name, id: x.key }));
    }

    const onClickReset = () => {
        setOpenResetDialog(true);
    }

    const onClickRestorePassword = () => {
        setOpenRestoreDialog(true);
    }

    const changeUserType = (val) => {
        setRoleId("");
        setUserTypeId(val);
    }

    return (
        <Paper className={classes.paper}>
            <div className={classes.label}>User Information</div>

            {editing && <Grid container className={classes.wrapperSave}>

                <Authorize profile={permissionProfiles.USER_MANAGEMENT.RESET_PASSWORD}>
                    <Grid item>
                        <Button
                            className={classes.resetButton}
                            onClick={onClickReset}
                            color="primary"
                            variant="contained"
                        >
                            Reset Password
                        </Button>
                    </Grid>
                </Authorize>

                <Authorize profile={permissionProfiles.USER_MANAGEMENT.SEND_RESTORE_PASSWORD}>
                    <Grid item>
                        <Button
                            className={classes.resetButton}
                            onClick={onClickRestorePassword}
                            color="primary"
                            variant="contained"
                        >
                            Send Restore Password
                        </Button>
                    </Grid>
                </Authorize>

            </Grid>}
            <Grid item className={classes.grids}>
                <CustomInput
                    id="userName"
                    label="Username"
                    elementType="input"
                    classStyles={classes.dropdown}
                    value={user?.userName}
                    disabled={editing}
                    onChange={(val) => {
                        setHasChanged(true);
                        setUserName(val);
                    }}
                />
                {errorMessages.userName &&
                    <p className={classes.requiredField}>{getErrorMessage('userName')}</p>}
            </Grid>
            <Grid item className={classes.grids}>
                <CustomInput
                    id="name"
                    label="Full Name"
                    elementType="input"
                    classStyles={classes.dropdown}
                    onChange={(val) => {
                        setHasChanged(true);
                        setFullName(val);
                    }}
                    value={fullName}
                />
                {errorMessages.fullName &&
                    <p className={classes.requiredField}>{getErrorMessage('fullName')}</p>}
            </Grid>
            <Grid item className={classes.grids}>
                <CustomInput
                    id="email"
                    label="Email"
                    elementType="input"
                    classStyles={classes.dropdown}
                    value={user?.email}
                    disabled={editing}
                    onChange={(val) => {
                        setHasChanged(true);
                        setEmail(val);
                    }}
                />
                {errorMessages.email &&
                    <p className={classes.requiredField}>{getErrorMessage('email')}</p>}
            </Grid>
            <Grid item className={classes.grids}>
                <CustomInput
                    id="userType"
                    label="User Type"
                    elementType="dropdown"
                    values={[...userTypeOptionsEnums]}
                    onChange={val => {
                        setHasChanged(true);
                        changeUserType(val);
                    }}
                    touched
                    value={userTypeId}
                />
                {errorMessages.userType &&
                    <p className={classes.requiredField}>{getErrorMessage('userType')}</p>}
            </Grid>
            <Grid item className={classes.grids}>
                <CustomInput
                    id="role"
                    label="Role"
                    elementType="dropdown"
                    values={[{ id: '', name: "" }, ...(getRoleValues() ?? [])]}
                    onChange={val => {
                        setHasChanged(true);
                        setRoleId(val);
                    }}
                    touched
                    value={roleId ?? ""}
                    disabled={!userTypeId}
                />
                {errorMessages.role &&
                    <p className={classes.requiredField}>{getErrorMessage('role')}</p>}
            </Grid>
            {editing && <><Authorize profile={permissionProfiles.USER_MANAGEMENT.ARCHIVE_USER}>
                <Grid item className={classes.grids}>
                    <FormControlLabel
                        control={<Checkbox checked={userArchive} onChange={(e, value) => {
                            setHasChanged(true);
                            setUserArchive(value);
                        }} />}
                        label={userArchive ? `User Archived (Uncheck to reactive user)` : `Archive User`} />
                </Grid>
            </Authorize><Authorize profile={permissionProfiles.USER_MANAGEMENT.TOTAL_BLACK_LIST}>
                    <Grid item className={classes.grids}>
                        <FormControlLabel
                            control={<Checkbox checked={userBlackList} onChange={(e, value) => {
                                setUserBlackList(value);
                            }} />}
                            label={userBlackList ? `Is in Black List (Uncheck to reactive user)` : `Add to the Black List`} />
                    </Grid>
                </Authorize>
                {errorMessage && <p className={classes.requiredField}>{errorMessage}</p>}
                <Grid container className={classes.wrapperSave}>
                    {hasChanged ?
                        <Grid item>
                            <Button
                                className={classes.resetButton}
                                onClick={handleUserInfoSave}
                                color="primary"
                                variant="contained"
                                disabled={saving}
                            >
                                {saving ? 'Saving...' : 'Save'}
                            </Button>
                        </Grid> : null
                    }
                </Grid>
                <SendRestorePasswordDialog
                    open={openRestoreDialog}
                    setOpenDialog={setOpenRestoreDialog}
                    userId={user?.id}
                />

                <ResetPasswordDialog open={openResetDialog} setOpenDialog={setOpenResetDialog}
                    userId={user?.id}></ResetPasswordDialog>
            </>}
        </Paper>
    )
}

export default UserInformation;