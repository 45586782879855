import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from "@material-ui/core";
import { hasPermission } from "components/Authorize/authorizeUtil";
import { permissions } from "components/Authorize/permissions/permissions";
import ExportButton from "components/ExportButton/ExportButton";
import { withUserPreferences } from "components/UserPreferences/WithUserPreferences";
import { userPreferencesComponentIds, userPreferencesTypes } from "components/UserPreferences/userPreferencesConsts";
import { activityStatusesList } from "features/activity/activityConsts";
import { useGetAllActivityItemsQuery } from "features/activity/activitySlice";
import { ServiceItemTypes } from "features/invoice/loads/loadInvoiceConsts";
import { useGetInvoicesQuery } from "features/invoice/loads/loadInvoiceSlice";
import { loadExportColumnIds, loadExportColumns, loadStatuses, moveTypes, trailerTypes } from "features/loads/enums";
import statuses from "features/loads/loadStatusesWithIcons";
import { selectIsOwner } from "features/user/userSlice";
import { useGetFuelTypesQuery, useGetVehicleTypesQuery } from "features/vehicles/vehicleSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormatService from "services/formatService";
import { exportLoadStyles } from "./ExportLoadsStyles";

const ExportLoads = ({ groupedLoads, businessUnits, loads, isLoadingLoads, readinessForLoadId, savedColumns, saveColumns }) => {

    const classes = exportLoadStyles();

    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();
    let { data: fuelTypes, error: fuelTypesError, isLoading: isLoadingFuelTypes, isSuccess: isSuccessFuelTypes } = useGetFuelTypesQuery();
    let { data: invoices, error, isFetching: isLoadingInvoices } = useGetInvoicesQuery([2, 5]);
    let { data: activityItems, error: activityItemsError, isFetching: isLoadingActivityItems } = useGetAllActivityItemsQuery();
    invoices = invoices || [];
    activityItems = activityItems || [];
    
    useEffect(() => {
        if (savedColumns) {
            const savedColumnKeys = savedColumns?.map(s => s.key);
            setSelectedColumns(Object.values(loadExportColumns).filter(c => savedColumnKeys.includes(c.key)));
        }
    }, [savedColumns])

    const isOwner = useSelector(selectIsOwner);

    const hasInvoicePerm = hasPermission(permissions.LOADS.INVOICE.key);

    if(hasInvoicePerm)
    { 
        loadExportColumnIds.INVOICING = '43';
        loadExportColumns[loadExportColumnIds.INVOICING] = {name: 'Invoicing', key: '43'};
    }       

    const [openSelectFieldsPopup, setOpenSelectFieldsPopup] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([...Object.values(loadExportColumns)].filter(c => c.selectedByDefault));

    const isChecked = (checked, columnName) => {
        return [...checked]?.filter(column => column ? column.name == columnName : false)?.length > 0;
    }

    const onSubmitSelectedFields = () => {
        setOpenSelectFieldsPopup(false);
        saveColumns(selectedColumns);
    }

    const onSelectField = (index, value) => {
        if (value) {
            setSelectedColumns(
                Object.values(loadExportColumns).map((column,i) => ( i==index || isChecked(selectedColumns, column.name) ) ? column : null)
                .filter(column=>column)
            );
        }
        else{
            setSelectedColumns(
                selectedColumns.filter( c => c ? c.name != Object.values(loadExportColumns)[index].name : false )
            );
        }
    }

    let flattenedLoads = [];
   
    groupedLoads?.map(groupedLoad => {
        groupedLoad.loads.forEach(l => {
            l = {...l, groupId: groupedLoad?.id, groupName: groupedLoad?.name, movementType: groupedLoad?.movementType, businessUnitName: groupedLoad?.businessUnitName};
            flattenedLoads.push(l);
        });
        groupedLoad.activities.forEach(a => {
            a = {...a, groupId: groupedLoad?.id, groupName: groupedLoad?.name};
            flattenedLoads.push(a);
        });
    });
    
    const getLocationColumns = (l, prefix) => {
        return {
            [`${prefix} Node Name`]: l?.nodeName,
            [`${prefix} Domicile Site`]: l?.name,
            [`${prefix} Address`]: l?.addressLine1,
            [`${prefix} City`]: l?.city,
            [`${prefix} State`]: l?.state,
            [`${prefix} Zip`]: l?.zipCode,
        }
    }

    const getStatusColumns = (l) => {
        const statusColumns = {};
        Object.keys(statuses).filter(status => statuses[status]?.export).map(statusKey => {
            const status = l?.statuses?.find(s => s.status == statusKey);
            const statusDate = status?.statusDate;
            statusColumns[`Date ${statuses[statusKey].exportLabel ?? statuses[statusKey].label}`] = status ? FormatService.formatDateAndTimeWithFormat(statusDate, FormatService.formats.USDateTimeFormat) : '';
            if (statusKey == loadStatuses.EXCEPTION || statusKey == loadStatuses.CANCELLED) {
                statusColumns[`Comments for ${statuses[statusKey].exportLabel ?? statuses[statusKey].label}`] = status?.comment ?? '';
            }
        })
        return statusColumns;
    }

    invoices = invoices?.filter(invoice => flattenedLoads?.some(load => load.lmId === invoice.externalId))
        
    const invoicesLookup = invoices?.reduce(function (map, obj) {
        map[obj.externalId] = obj;
        return map;
    }, {});
    
    const allDescriptions = [];
    invoices?.map(invoice => {
        invoice?.services?.map(service => {
            if (service?.itemTypeId !== ServiceItemTypes.TRANSPORT) {
                const desc = service?.description?.replace(` - ${invoice?.externalId}`, '');
                if (!allDescriptions.includes(desc)) {
                    allDescriptions.push(desc);
                }
            }
        })
    })

    let maxNumberOffPassthroughs = 1;
    invoices?.map(invoice => {
        maxNumberOffPassthroughs = Math.max(invoice?.services.length, maxNumberOffPassthroughs)
    })

    const mapExportData = v => {
        let selectedColumnsMappedData = {};
        const loadsData = ({
            ['Group Name']: v?.groupName,
            ['Load ID']: v?.activityId ? null : v.lmId,
            ['Carrier']: v?.carrier?.transporterName,
            ['Asset ID']: v?.externalId,
            ['VRID']: v?.id,
            ['VIN']: v?.assets?.[0]?.vin,
            ['Make']: v?.assets?.[0]?.make,
            ['Model']: v?.assets?.[0]?.model,
            ['Year']: v?.assets?.[0]?.year,
            ['Vehicle Type']: vehicleTypes?.find(s => s.id === v?.assets?.[0]?.assetTypeId)?.name,
            ['Fuel Type']: fuelTypes?.find(f => f.id == v.assets?.[0]?.fuelType)?.name,
            ...(businessUnits?.length ? { ['Business Unit']: v?.businessUnitName } : {}),
            ['Request Date']: FormatService.formatDateTime(v?.createdDate),
            ['Move Type']: v?.activityId ? (activityItems?.find(a => a.id === v?.activityItemId)?.name) : v?.moveType ? (moveTypes.find(t => t.id === v?.moveType)?.name) : v?.movementType?.name,
            ['Miles']: v?.miles,
            ['Trailer Type']: trailerTypes.find(t => t.id == v?.trailerType)?.name,
            ['Latest Status']: v?.activityId ? activityStatusesList[v.activityId].name : statuses[v?.latestStatus]?.label,
            [loadExportColumnIds.ALL_STATUSES]: getStatusColumns(v),
            ...getLocationColumns(v?.pickupLocation, 'Pickup'),
            ['Earliest Pickup Date']: FormatService.formatDate(v?.pickupStartDate),
            ['Estimated Pickup Date']: FormatService.formatDate(v?.confirmedPickupDate),
            ['Actual Pickup Date']: FormatService.formatDateTime(v?.actualPickupDate),
            ...getLocationColumns(v?.deliveryLocation, 'Delivery'),
            ['Latest Delivery Date']: FormatService.formatDate(v?.deliveryEndDate),
            ['Estimated Delivery Date']: FormatService.formatDate(v?.confirmedDeliveryDate),
            ['Actual Delivery Date']: FormatService.formatDateTime(v?.actualDeliveryDate),
            ['Needed By Week']: v?.neededByWeek,
            ['Handoff Week']: v?.handoffWeek,
            ['Comments']: v?.activityId ? null : v?.comments,
            ['Readiness']: v?.activityId ? null : (readinessForLoadId[v.id]?.status == 1 ? "Ready" : "Not Ready"),
            ['Latest Wash']: v?.activityId ? null : (readinessForLoadId[v.id]?.latestCompletedWash ? FormatService.formatDate(readinessForLoadId[v.id]?.latestCompletedWash) : 'Unknown'),
            ['Latest Inspection']: v?.activityId ? null : (readinessForLoadId[v.id]?.latestAmeritInspection ? FormatService.formatDate(readinessForLoadId[v.id]?.latestAmeritInspection) : 'Unknown'),
            ['Used GPS']: v?.activityId ? null : (v?.usedGps ? "Yes" : "No")
        });

        for (const field of selectedColumns) {
            if(field.name === "All Statuses")
            {
                selectedColumnsMappedData = {...selectedColumnsMappedData, ...loadsData[loadExportColumnIds.ALL_STATUSES]}
            }
            else if(field.name ==="Invoicing")
            {
                if(hasInvoicePerm && (invoices?.length > 0)) 
                {
                    let loadInvoice = invoicesLookup[v.lmId]

                    //selectedColumnsMappedData['Managemant Fee'] = `$${loadInvoice?.managementFee ?? 0}`
                    selectedColumnsMappedData['Invoice Submitted'] = loadInvoice ? 'Yes' : 'No';
                    selectedColumnsMappedData['Rate'] = `$${loadInvoice?.carrierCost ?? 0}`;
                    selectedColumnsMappedData['Rate with Fees'] = `$${loadInvoice?.carrierCostWithFees ?? 0}`;
                    if (isOwner) {
                        selectedColumnsMappedData['Client Rate'] = `$${loadInvoice?.carrierCostFinal ?? 0}`;
                    }
                    //selectedColumnsMappedData['Fuel'] = loadInvoice?.fuelCost ?? '$0'

                    const passThroughs = loadInvoice?.services?.filter(service => service?.itemTypeId !== ServiceItemTypes.TRANSPORT);

                    for (let i = 1; i <= maxNumberOffPassthroughs; i++) {
                        selectedColumnsMappedData[`Pass-through ${i} description`] = `${passThroughs?.[i - 1]?.description ?? '-'}`;
                        selectedColumnsMappedData[`Pass-through ${i} amount`] = `$${passThroughs?.[i - 1]?.amount ?? 0}`;
                    }

                    // allDescriptions.map(desc => {
                    //     let amount = loadInvoice?.services?.filter(s => s?.description?.startsWith(desc))?.reduce(function (a, b) {
                    //         return a + b?.amount;
                    //     }, 0);
                    //     selectedColumnsMappedData[desc] = `$${amount ?? 0}`
                    // })           
                }
        
            }
            else { selectedColumnsMappedData[field.name] = loadsData[field.name]}
        }
        
        return selectedColumnsMappedData;
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenSelectFieldsPopup(true)}
                startIcon={<img src="/icons/icon-download.svg" />}
            >
                Export
            </Button>

            <Dialog open={openSelectFieldsPopup} onClose={() => setOpenSelectFieldsPopup(false)}>
                <DialogTitle id="form-dialog-title">Select Columns</DialogTitle>
                <DialogContent>
                    <div className={classes.checkboxList}>
                        {Object.values(loadExportColumns).map((column, index) => (
                        <>
                            <FormControlLabel
                                control={<Checkbox defaultChecked={isChecked([...selectedColumns], column.name)} onChange={(e, value) => onSelectField(index, value)}/>}
                                label={column.name}
                            />
                        </>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className={classes.btnContainer}>
                        <ExportButton disabled={isLoadingInvoices || isLoadingLoads} fileLabel="loads" data={flattenedLoads} mapExportData={mapExportData} onFinish={onSubmitSelectedFields} />
                        <Button className={classes.button} variant="outlined" onClick={() => setOpenSelectFieldsPopup(false)}>Close</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withUserPreferences(ExportLoads, userPreferencesComponentIds.EXPORT_LOADS, userPreferencesTypes.COLUMNS);