import { Grid } from "@material-ui/core";
import Incrementor from "components/Incrementor/Incrementor";
import { useGetVehicleTypesQuery } from "features/vehicles/vehicleSlice";
import { arrayToObject } from "utils/object-util";
import { ReservationAssetTypesStyle } from "./ReservationAssetTypesStyle";
import { ReservationFormStyles } from "../ReservationForm/ReservationFormStyles";
import { Alert } from "@mui/material";

const ReservationAssetTypes = ({
    reservationOrder,
    setReservationOrder,
    availableVehicleTypesList,
    isLoading = false,
    errors, setErrors,
    setHasUnsavedChanges
}) => {
    const classes = ReservationAssetTypesStyle();
    const formClasses = ReservationFormStyles();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes, isSuccess: isVehicleTypesSuccess } = useGetVehicleTypesQuery();

    const rentalType = 2;
    vehicleTypes = vehicleTypes?.filter(v => v.type == 2) || []

    const vehicleTypesLookup = arrayToObject(vehicleTypes);

    const reservationOrderLookup = arrayToObject(reservationOrder, 'vehicleTypeId')

    const onChangeCount = (vehicleTypeId, val) => {
        let list = [...reservationOrder];
        // let item = reservationOrderLookup?.[vehicleTypeId];
        let index = reservationOrder.findIndex(r => r.vehicleTypeId == vehicleTypeId);
        if (index < 0) {
            list.push({
                vehicleTypeId: vehicleTypeId,
                quantity: val
            })
        } else {
            list?.splice(index, 1, { vehicleTypeId: vehicleTypeId, quantity: val });
        }
        setReservationOrder(list);
        setHasUnsavedChanges(true);
    }

    return (
        <>
            <div className={formClasses.sectionTitle}>
                Asset Types & Quantity
            </div>
            {!!errors?.['assetTypes'] && <Grid xs={12} className={formClasses.alertWrapper}>
                <Alert severity='error' >
                    {errors?.['assetTypes']}
                </Alert>
            </Grid>}
            {vehicleTypes?.map(vt => <Grid item xs={12} container alignItems='center' justifyContent="space-between">
                <Grid item >
                    <div className={classes.assetTypeName} >{vehicleTypesLookup?.[vt.id]?.name}</div>
                </Grid>
                <Grid item >
                    <Incrementor
                        value={reservationOrderLookup?.[vt.id]?.quantity ?? 0}
                        onChange={(v) => onChangeCount(vt.id, v)}
                        min={0}
                    //max={item.quantity}
                    />
                </Grid>
            </Grid>)}


        </>
    )
};

export default ReservationAssetTypes;
