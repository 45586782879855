import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper
} from "@material-ui/core";
import { TabsWrapper } from 'components/TabsWrapper/TabsWrapper';
import { useEffect, useState } from "react";
import NotificationProvider from "components/NotificationProvider";
import LoadingSpinner from "components/LoadingSpinner";
import Validator from "services/validator";
import { useCreateUserByManagerMutation } from "../userManagementSlice";
import { useGetUserTaskConfigQuery } from "./UserTasks/userTaskConfigSlice";
import AlarmOnOutlinedIcon from '@material-ui/icons/AlarmOnOutlined';
import { IconKey, IconLot, IconUser } from 'icons';
import UserInformation from './userInformation/UserInformation';
import UserLotRelations from './userLotRelations/UserLotRelations';
import UserRestrictions from './userRestrictions/UserRestrictions';
import UserTasksList from './UserTasks/UserTasksList';
import { useGetClaimsByUserIdQuery } from '../../usersSlice';
import { useGetUserLotRelationsForUserByManagerQuery } from "../userManagementSlice";
import usersPageStyle from './UsersPageStyle';
import { userTaskInterval } from "./UserTasks/UserTaskItem";

const useStyles = usersPageStyle;

const CreateUserDialog = ({ open, roles, setOpenDialog, refetchUsers, user, clients, lots }) => {
    const classes = useStyles();
    const [createUser] = useCreateUserByManagerMutation();

    clients = clients || [];
    lots = lots || [];
    roles = roles || [];

    const [tab, setTab] = useState(0);
    const [userToCreate, setUserToCreate] = useState({});
    const [selectedLotIds, setSelectedLotIds] = useState([]);
    const [selectedClientIds, setSelectedClientIds] = useState([]);
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState()
    const [isSaving, setIsSaving] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});

    let {
        data: userLotRelations,
        isLoading: isLoadingRelations
    } = useGetUserLotRelationsForUserByManagerQuery(user?.id, { skip: !user?.id });
    let {
        data: userClaims,
    } = useGetClaimsByUserIdQuery(user?.id, { skip: !user?.id });

    const [relations, setRelations] = useState(userLotRelations|| []);
    
    let { data: userTasksConfig,
        isLoading: isLoadingUserTasks }
        = useGetUserTaskConfigQuery()

    const [selectedUserTasks, setSelectedUserTasks] = useState([]);

    const updateRelations = (relations) =>{
        setRelations(relations);

        if(relations?.length > 0){
            const relationTypes = [...new Set(relations.map(item => item.relationType))];
            const updatedTasks = userTasksConfig?.data
            .filter(task => relationTypes.includes(task.lotRelation))
            .map(task => ({
                ...task,
                on: false
            }));

            setSelectedUserTasks(updatedTasks);
        }
    }

    useEffect(() => {
        updateRelations(userLotRelations);
    }, [isLoadingRelations]);

    useEffect(() => {
        setSelectedLotIds(selectedLotIds.length ? selectedLotIds : [])
        setSelectedClientIds(selectedClientIds.length ? selectedClientIds : [])
    }, [userToCreate]);


    const handleUserChange = (userInfo) => {
        setUserToCreate(userInfo);
    };

    const handleRestrictionsChange = (restrictions) => {
        const lotIds = restrictions?.lotClaims?.map(lot => lot.claimValue) || [];
        const clientIds = restrictions?.clientClaims?.map(client => client.claimValue) || [];

        setSelectedLotIds(lotIds);
        setSelectedClientIds(clientIds);
    };

    const onSave = async () => {

        setIsSaving(true);
        let isValid = await validateData();
        if (!isValid) {
            setIsSaving(false);
            return;
        }

        setErrorMessages({});

        userToCreate["password"] = password;
        userToCreate["confirmPassword"] = confirmPassword;
        userToCreate.clientIds = selectedClientIds;
        userToCreate.lotIds = selectedLotIds;
        userToCreate.email.trim();
        userToCreate.userName.trim();
        userToCreate.userType = parseInt(userToCreate.userTypeId ?? 1);
        userToCreate.relations = relations;
        userToCreate.userTasks = selectedUserTasks
        .filter(task => task.on)
        .map(task => ({
            ...task,
            id: task.taskConfigId,
            schedule: formatSchedule(task),
            taskTypeId: task.taskTypeEnum
        }));

        let result = await createUser(userToCreate);

        if (result && !result.error) {
            NotificationProvider.success("User created successfully");
            refetchUsers();
            onClose()
        } else {
            NotificationProvider.error("Error creating the user");
            setIsSaving(false);
        }
    }

    const formatSchedule = (task) => {
        if (!task.scheduleList) return null;
    
        const isWeekly = task.interval === userTaskInterval.WEEKLY;
        const filteredSchedule = task.scheduleList.filter(s => isWeekly ? s.day && s.hour : s.hour);
    
        return JSON.stringify(filteredSchedule);
    };

    const onClose = () => {
        setUserToCreate(null);
        setOpenDialog(false);
        setSelectedClientIds([]);
        setSelectedLotIds([]);
        setPassword("");
        setConfirmPassword("");
        setIsSaving(false);
        setErrorMessages({});
        setTab(0);
        updateRelations([]);
        setSelectedUserTasks([]);
    }

    const validateData = async () => {
        let isValid = true;

        let validEmail = Validator.validateEmail(userToCreate?.email);
        let validUsername = Validator.validUsername(userToCreate?.userName);

        const updatedMessages = { ...errorMessages };

        if (!userToCreate?.userName || !validUsername) {
            updatedMessages.userName = "Please enter a valid username";
            isValid = false;
        } else {
            if (updatedMessages.userName) updatedMessages.userName = null
        }

        if (!userToCreate?.fullName) {
            updatedMessages.fullName = "Please enter a full name";
            isValid = false;
        } else {
            if (updatedMessages.fullName) updatedMessages.fullName = null
        }

        if (!userToCreate?.email || !validEmail) {
            updatedMessages.email = "Please enter a valid email";
            isValid = false;
        } else {
            if (updatedMessages.email) updatedMessages.email = null
        }

        if (!userToCreate?.roleId) {
            updatedMessages.role = "Please select a role";
            isValid = false;
        } else {
            if (updatedMessages.role) updatedMessages.role = null
        }

        if (!userToCreate?.userTypeId) {
            updatedMessages.userType = "Please select a user type";
            isValid = false;
        } else {
            if (updatedMessages.userType) updatedMessages.userType = null
        }

        if (!isValid) {
            setTab(0);
        }
        setErrorMessages(updatedMessages);
        return isValid;
    }

    const tabs = [];
    tabs.push({
        label: <><IconUser width={25} height={25} className={classes.labelIcon} />User Information</>,
        clearOnSwitch: false,
        component: <UserInformation user={user} roles={roles} userClaims={userClaims}
            refetchUsers={refetchUsers}
            onCancel={onClose}
            relations={userLotRelations}
            editing={false}
            handleSave={onSave}
            handleUserChange={handleUserChange}
            validateDataBeforeSend={validateData}
            errorMessages={errorMessages}></UserInformation>,
    });

    tabs.push({
        label: <><IconLot width={25} height={25} className={classes.labelIcon} />Lot Relations</>,
        clearOnSwitch: false,
        component: <Paper className={classes.paper}>
            <UserLotRelations userId={user?.id} lots={lots} relations={relations} refetchUsers={refetchUsers}
                setRelations={updateRelations}
                onCancel={onClose} editing={false} />
        </Paper>
    });

    tabs.push(
        {
            label: <><IconKey width={25} height={25} className={classes.labelIcon} />Access</>,
            clearOnSwitch: false,
            component: <UserRestrictions user={user} userClaims={userClaims} lots={lots} clients={clients}
                refetchUsers={refetchUsers}
                userLotRelations={userLotRelations} editing={false} handleChange={handleRestrictionsChange}></UserRestrictions>
        });
    tabs.push(
        {
            label: <><AlarmOnOutlinedIcon width={25} height={25} className={classes.labelIcon} />Tasks</>,
            clearOnSwitch: false,
            component: <Paper className={classes.componentWrapper}>
                <UserTasksList userId={user?.id} 
                 editing={false}
                 onChange={setSelectedUserTasks} 
                 userTasks={selectedUserTasks} 
                 isLoadingUserTasks={isLoadingUserTasks} />
                </Paper>
        });
    return (
        <>
            <LoadingSpinner loading={isSaving} />
            <Dialog open={open} className={classes.paper} fullWidth maxWidth='md'>
                <DialogTitle id="form-dialog-title">
                    <Grid container direction='row' alignItems='center' spacing={2}>
                        <Grid item>
                            <IconUser width={40} height={40} className={classes.titleIcon} />
                        </Grid>
                        <Grid item>
                            Create User
                            <div className={classes.subTitle}>{user?.email}</div>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.editUserDialogContent}>
                        <TabsWrapper tabs={tabs} setSelectedTab={setTab} selectedTab={tab} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <div>
                        Clicking "Save" will store the information from all tabs.
                    </div>
                    <Button onClick={() => onClose()} color="primary">Close</Button>
                    <Button onClick={() => onSave()} color="primary" variant={"contained"}
                        disabled={isSaving}>{isSaving ? "Saving..." : "Save"}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CreateUserDialog;