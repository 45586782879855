import { Grid, Popper, Slide } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import Authorize from '../components/Authorize';
import { permissionProfiles } from '../components/Authorize/permissionProfiles';
import { useGetClientsWithConfigsQuery } from '../features/clients/clientConfigSlice';
import { selectIsAdmin, selectIsClient, selectIsOwner } from "../features/user/userSlice";
import { IconBox, IconCar, IconClipboard, IconForm, IconGraph, IconKey, IconLogout, IconLot, IconReservations, IconSla, IconTasks, IconTruck, IconUser } from '../icons';
import SideNavLink from "./SideNavLink";
import { sideNavStyles } from './style';
import { isAuthorized } from 'components/Authorize/authorizeUtil';

export default function SideNav({ }) {
    const classes = sideNavStyles();
    const isMobile = false;
    const history = useHistory();
    let isAdmin = useSelector(selectIsAdmin);
    let isOwner = useSelector(selectIsOwner);
    let isClient = useSelector(selectIsClient);
    let user = useSelector(state => state.oidc.user);
    let isUserLoaded = !!user;
    const [openSubMenu, setOpenSubMenu] = useState();

    let { data: clientsWithConfigs, error: clientsWithConfigsError, isFetching: isLoadingClientsWithConfigs } = useGetClientsWithConfigsQuery(null, { skip: isAdmin || !isUserLoaded });
    clientsWithConfigs = clientsWithConfigs || [];

    let isUsingTransports = false;
    let showUsers = false;
    let isUsingWashes = false

    const hasMechanicalInvoicePermission = isAuthorized(permissionProfiles.INVOICE.READ_MECHANICAL_INVOICE);
    const hasStorageInvoicePermission = isAuthorized(permissionProfiles.INVOICE.READ_STORAGE_INVOICE);
    const hasTransInvoicePermission = isAuthorized(permissionProfiles.INVOICE.READ_TRANSPORTATION_INVOICE);

    const getInvoiceUrlByPermission = () => {
        if (hasTransInvoicePermission) {
            return 'invoices/loads';
        }
        if (hasMechanicalInvoicePermission) {
            return 'invoices/activity';
        }
        if (hasStorageInvoicePermission) {
            return 'invoices/storage';
        }
    }

    function logout() {
        history.push("/logout");
    }

    const menuItems = [
        {
            text: 'Dashboard',
            icon: <IconGraph />,
            subMenu: {
                items: [
                    {
                        text: 'Washes',
                        link: 'dashboard/wash',
                        checkVisibility: () => isUsingWashes,
                        profile: permissionProfiles.DASHBOARD.WASH_DASHBOARD_VIEW
                    },
                    {
                        text: 'Dispatch',
                        link: 'dashboard/dispatch',
                        profile: permissionProfiles.DASHBOARD.DISPATCH_DASHBOARD_VIEW
                    },
                    {
                        text: 'KPI',
                        link: 'dashboard/kpi',
                        profile: permissionProfiles.DASHBOARD.KPI_DASHBOARD_VIEW
                    },
                    {
                        text: 'Mechanicals',
                        link: 'dashboard/mechanical',
                        profile: permissionProfiles.DASHBOARD.MECHANICAL_DASHBOARD_VIEW
                    }
                ]
            }
        },
        {
            text: 'Lots',
            icon: <IconLot />,
        },
        {
            text: 'Users',
            icon: <IconUser />,
            checkVisibility: () => showUsers
        },
        // {
        //     text: 'Users',
        //     icon: <IconUser />,
        //     link: 'userManagement',
        //     profile: permissionProfiles.USER_MANAGEMENT.ACCESS,
        // },
        {
            text: 'Access',
            icon: <IconKey />,
            subMenu: {
                profile: permissionProfiles.ACCESS.ACCESS_SUB_MENU,
                items: [
                    {
                        text: 'Door Releases',
                        link: 'doorRelease',
                        profile: permissionProfiles.ACCESS.DOOR_RELEASE_LIST
                    },
                    {
                        text: 'Camera Events',
                        link: 'cameras',
                        profile: permissionProfiles.ACCESS.CAMERA_EVENTS_LIST
                    }
                ]
            }
        },
        {
            text: 'Assets',
            icon: <IconCar />,
            profile: permissionProfiles.ASSETS.ASSETS_PAGE_VIEW_ASSET_TABLE
        },
        {
            text: 'Workflow',
            icon: <IconTasks />,
            profile: permissionProfiles.ISSUETRACKER.ISSUE_TRACKER_VIEW
        },
        {
            text: 'Inventory',
            icon: <IconBox />,
            profile: permissionProfiles.DASHBOARD.VIEW_REPORTS_SUB_MENU
        },
        {
            text: 'Reports',
            icon: <IconClipboard />,
            placement: 'right-end',
            subMenu: {
                profile: permissionProfiles.DASHBOARD.SHOW_REPORT_SUB_MENU,
                items: [
                    {
                        text: 'Activity report',
                        link: 'dashboard/mechanical',
                        profile: permissionProfiles.DASHBOARD.ONE_ON_ONE,
                        newGroup: true
                    },
                    {
                        text: 'Pricing Database',
                        link: 'pricing',
                        profile: permissionProfiles.PRICING.MANAGE_PPRICING,
                    },
                    {
                        text: '1:1 Report',
                        link: 'oneonones',
                        profile: permissionProfiles.DASHBOARD.ONE_ON_ONE,
                        newGroup: true
                    },
                    {
                        text: 'New 1:1',
                        link: 'oneonones/new',
                        profile: permissionProfiles.DASHBOARD.ONE_ON_ONE
                    },
                    {
                        text: 'Inspections',
                        link: 'inspection',
                        profile: permissionProfiles.INSPECTION.INSPECTION_VIEW,
                        newGroup: true
                    },
                    {
                        text: 'New Inspection',
                        link: 'inspection/new',
                        profile: permissionProfiles.INSPECTION.INSPECTION_CREATE
                    },
                    {
                        text: 'Tasks',
                        link: 'tasks',
                        profile: permissionProfiles.TASKS.SUPERS_TASK_LIST,
                        newGroup: true
                    },
                    {
                        text: 'Tax Rate',
                        link: 'taxrate',
                        profile: permissionProfiles.TAX_RATE.TAX_VIEW,
                        newGroup: true
                    },
                ]
            },
        },
        {
            text: 'Loads',
            icon: <IconTruck />,
            subMenu: {
                items: [
                    {
                        text: 'Load List',
                        link: 'loads/list',
                    },
                    {
                        text: 'Create Load',
                        link: 'loads/list?createload=true&latestStatus=8',
                    }
                ]
            },
            checkVisibility: () => isUsingTransports
        },
        {
            text: 'SLAs',
            icon: <IconSla />,
            subMenu: {
                profile: permissionProfiles.DASHBOARD.SHOW_SLA_SUB_MENU,
                items: [
                    {
                        text: 'Invoices',
                        link: getInvoiceUrlByPermission(),
                        profile: permissionProfiles.INVOICE.INVOICES_VIEW
                    },
                    {
                        text: 'Reports',
                        link: 'storage',
                        profile: permissionProfiles.REPORTS.VIEW_STORAGE_REPORTS
                    },
                    {
                        text: 'Cost Codes',
                        link: 'costcodes',
                        profile: permissionProfiles.REPORTING.VIEW_COST_CODES_PAGE
                    }
                ]
            }
        },
        {
            text: 'Forms',
            icon: <IconForm />,
            profile: permissionProfiles.FORMS.CREATE_FORMS
        },
        {
            text: 'Reservations',
            icon: <IconReservations />,
            profile: permissionProfiles.RESERVATIONS.VIEW
        },
    ]

    if (!isUserLoaded) {
        return <></>;
    }

    if (isClient) {
        isUsingTransports = clientsWithConfigs?.length ? clientsWithConfigs[0]?.config.transportConfig.useTransport : false;
        isUsingWashes = clientsWithConfigs?.length ? clientsWithConfigs[0]?.config.washConfig?.useWashProgram : false;
    } else {
        isUsingTransports = true;
        isUsingWashes = true;
    }

    if (isOwner) {
        showUsers = true;
    }

    return (
        <div className={classes.root}>
            <Drawer
                className={`${classes.drawer} ${isMobile ? classes.drawerMobile : ""}`}
                variant="permanent"
                classes={{
                    paper: `${classes.drawerPaper} ${isMobile ? classes.drawerPaperMobile : ""}`
                }}
            >
                <Toolbar disableGutters>
                    <img
                        src="/images/ParqAIlogo.png"
                        className={`${classes.logo} ${isMobile ? classes.logoMobile : ""}`}
                        alt="parq.ai"
                    />
                </Toolbar>
                <div className={classes.drawerContainer}>
                    <List style={{ overflowY: "auto", paddingTop: '0' }}>
                        {menuItems?.filter(item => !item.checkVisibility || item.checkVisibility()).map((item, index) =>
                            <MenuItem menu={item} setOpenSubMenu={setOpenSubMenu} openSubMenu={openSubMenu} />
                        )}
                    </List>
                    <div className={`${classes.logoutButton} ${isMobile ? classes.logoutButtonMobile : ""}`}>
                        <Button variant="text" onClick={() => logout()} startIcon={<IconLogout />}>
                            {
                                "Logout"
                            }
                        </Button>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

const MenuItem = ({ menu, openSubMenu, setOpenSubMenu }) => {

    const isMobile = false;//useMediaQuery(mobileBreakpoint);
    const [open, setOpen] = useState(false);
    const classes = sideNavStyles();
    const ref = useRef();

    const subMenus = <>{menu?.subMenu?.items?.filter(m => !m.checkVisibility || m.checkVisibility()).map((subItem, sindex) =>
        <Authorize profile={subItem.profile}>
            <SideNavLink isSubItem={true}
                key={menu.text + sindex}
                text={subItem.text}
                icon={subItem.icon}
                link={subItem.link}
                style={subItem.newGroup ? { borderTop: '1px solid #efefef' } : {}}
                onMenuItemClick={() => {
                    setOpen(false)
                }} />
        </Authorize>
    )}</>

    const openMenu = () => {
        setOpen(!open);
        if (!open) {//Opened 
            setOpenSubMenu(menu.text);
        }
    }

    useEffect(() => {
        if (openSubMenu !== menu.text && open) {
            setOpen(false);
        }
    }, [openSubMenu]);

    const subMenuLength = menu?.subMenu?.items?.length;
    return (
        <Authorize profile={menu.profile}>
            <div ref={ref}>
                <Grid container alignContent='space-between' alignItems='top' justifyContent='flex-end'>
                    <Grid className={classes.menuItemWrapper} xs={12} >
                        <SideNavLink
                            link={menu.link}
                            key={menu.text}
                            text={menu.text}
                            icon={menu.icon}
                        />
                    </Grid>
                    {!!subMenuLength &&
                        <Authorize profile={menu?.subMenu?.profile}>
                            <Grid className={classes.expand}>
                                <div className={classes.expandBtn} onClick={openMenu}>
                                    {open ? <KeyboardArrowRightIcon /> : <ExpandMoreIcon />}
                                </div>
                            </Grid>
                        </Authorize>
                    }
                </Grid>
            </div>
            <Popper
                transition
                open={!!open}
                anchorEl={ref?.current}
                onClose={() => { setOpen(false) }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                placement={menu.placement ?? "right-start"}
                hideBackdrop={true}
                disableScrollLock={true}
                style={{ zIndex: 1 }}
            >
                {({ TransitionProps }) => (
                    <Slide direction='right' {...TransitionProps} timeout={350}>
                        <div className={classes.subMenu}>{subMenus}</div>
                    </Slide>
                )}
            </Popper>
        </Authorize>
    )
}
