import { Button, Chip, CircularProgress, FormControlLabel, Grid, ListItem, Paper, Switch } from '@material-ui/core';
import { Alert } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CustomInput from 'components/CustomInput';
import VehicleStatusView from 'features/vehicles/VehicleStatus/VehicleStatusView';
import { groupBy, orderBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetDefaultCheckoutStatusByClientIdQuery, useGetDefaultStatusByClientIdQuery, useGetPaveConfigByClientIdQuery, useGetPaveCheckinByClientIdQuery, useUpdateCheckinWithPaveMutation, useUpdateDefaultCheckinStatusMutation, useUpdateDefaultCheckoutStatusMutation } from '../../../../../../src/features/clients/clientConfigSlice';
import Authorize from '../../../../../components/Authorize';
import { permissionProfiles } from '../../../../../components/Authorize/permissionProfiles';
import NotificationProvider from '../../../../../components/NotificationProvider';
import { StepDefinitionTypeById } from '../../enums';
import EditWorkFlowPopupStyle from './EditWorkFlowPopupStyle';
import { screenTypeAdditionalComponent } from './screenTypes';
import {
    useGetDefaultStepDefinitionQuery,
    useGetStepDefinitionDetailsGroupQuery,
    useGetStepDefinitionsByClientQuery,
    useGetStepDefinitionsDetailsByIdQuery,
    useUpdateStepDefinitionMutation,
} from './workFlowSlice';
import { PaveApiKeys } from './PaveApiKeys';

const useStyles = EditWorkFlowPopupStyle;

const EditWorkFlow = ({
    contract,
    open,
    clientConfig,
    handleClose,
    stepDefinitionTypeId = StepDefinitionTypeById.CHECKIN
}) => {

    const classes = useStyles();
    const [updateDetails] = useUpdateStepDefinitionMutation();
    const [updateCheckinWithPave] = useUpdateCheckinWithPaveMutation();
    const [updateDefaultCheckinStatus] = useUpdateDefaultCheckinStatusMutation();
    const [updateDefaultCheckoutStatus] = useUpdateDefaultCheckoutStatusMutation();
    const [isSaving, setIsSaving] = useState(false);
    const [steps, setSteps] = useState([]);
    const [usePaveCheckin, setUsePaveCheckin] = useState(null);
    const [paveApiKey, setPaveApiKey] = useState("");
    const [paveApiSecret, setPaveApiSecret] = useState("");
    const [paveUserName, setPaveUserName] = useState("");
    const [defaultCheckinStatusId, setDefaultCheckinStatusId] = useState(null);
    const [defaultCheckoutStatusId, setDefaultCheckoutStatusId] = useState(null);
    const ScanVinScreenTypeId = 33;
    const AssetIdScreenTypeId = 53;

    //default steps
    let { data: defaultStepDefinitions, error: defaultStepDefinitionsError, isLoading: isLoadingDefaultStepDefinitions } = useGetDefaultStepDefinitionQuery();
    defaultStepDefinitions = defaultStepDefinitions || [];
    const defaultStepDefinition = defaultStepDefinitions?.data?.find(s => s.stepDefinitionTypeId == stepDefinitionTypeId);

    let { data: defaultStepDefinitionDetails, error: defaultStepDefinitionDetailsError, isLoading: isLoadingDefaultStepDefinitionDetails } = useGetStepDefinitionsDetailsByIdQuery(defaultStepDefinition?.id, { skip: !defaultStepDefinition?.id });
    let defaultSteps = defaultStepDefinitionDetails?.data || [];

    let { data: defaultStepDefinitionDetailsGroups, error: defaultStepDefinitionDetailsGroupsError, isLoading: isLoadingdefaultStepDefinitionDetailsGroups } = useGetStepDefinitionDetailsGroupQuery();
    let stepsGroups = defaultStepDefinitionDetailsGroups?.data || [];

    //users steps
    let { data: stepDefinitions, error: stepDefinitionsError, isLoading: isLoadingStepDefinitions } = useGetStepDefinitionsByClientQuery(clientConfig?.id);
    let { data: paveCheckin, error: paveCheckinError, isLoading: isPaveCheckinLoading } = useGetPaveCheckinByClientIdQuery({ clientId: clientConfig?.id });
    let { data: paveConfig, error: paveConfigError, isLoading: isPaveConfigLoading } = useGetPaveConfigByClientIdQuery({ clientId: clientConfig?.id });
    let { data: checkinStatusId, error: checkinStatusIdError, isLoading: isCheckinStatusIdLoading, refetch: refetchCheckin } = useGetDefaultStatusByClientIdQuery({ clientId: clientConfig?.id });
    let { data: checkoutStatusId, error: checkoutStatusIdError, isLoading: isCheckoutStatusIdLoading, refetch: refetchCheckout } = useGetDefaultCheckoutStatusByClientIdQuery({ clientId: clientConfig?.id });

    stepDefinitions = stepDefinitions || [];
    const stepDefinition = stepDefinitions?.data?.filter(s => s.stepDefinitionTypeId == stepDefinitionTypeId)[0];

    let { data: stepDefinitionDetails, error: stepDefinitionDetailsError, isLoading: isLoadingStepDefinitionDetails } = useGetStepDefinitionsDetailsByIdQuery(stepDefinition?.id, { skip: !stepDefinition?.id });
    let clientsSteps = stepDefinitionDetails?.data || [];

    let isLoading = isCheckinStatusIdLoading || isLoadingDefaultStepDefinitions || isLoadingDefaultStepDefinitionDetails || isLoadingdefaultStepDefinitionDetailsGroups || isLoadingStepDefinitions || isLoadingStepDefinitionDetails;

    useEffect(() => {
        if (!isLoading && defaultSteps?.length && stepsGroups?.length) {
            let clientsDefaultSteps = [];
            [...defaultSteps].forEach(step => {
                let defaulStep = { ...step };
                defaulStep.description = defaulStep.description ?? "";
                let clientStep = clientsSteps?.find(s => s.screenTypeId == defaulStep.screenTypeId);
                if (clientStep) {
                    defaulStep.id = clientStep?.id;
                    defaulStep.isActive = clientStep?.isActive;
                    defaulStep.required = clientStep?.required;
                    defaulStep.lastDateSync = clientStep?.lastDateSync;
                }
                else {
                    defaulStep.id = 0;
                    defaulStep.isActive = defaulStep.required ? 1 : 0;
                    defaulStep.stepDefinitionId = stepDefinition?.id;
                    defaulStep.lastDateSync = moment().format();
                }
                clientsDefaultSteps.push(defaulStep);
            });

            const stepsGrouped = groupBy([...clientsDefaultSteps]?.filter(s => s.groupId), s => s.groupId);
            const groupsWithSteps = [...stepsGroups]?.filter(g => stepsGrouped[g.id])?.map(g => ({
                ...g,
                steps: stepsGrouped[g.id],
                stepNumber: stepsGrouped[g.id]?.[0]?.stepNumber
            }));
            const stepsWithoutGroups = clientsDefaultSteps?.filter(s => !s.groupId);
            const orderedSteps = orderBy([...stepsWithoutGroups, ...groupsWithSteps], 'stepNumber');

            setSteps(orderedSteps);
        }
    }, [open, defaultSteps, stepsGroups, clientsSteps])

    useEffect(() => {
        setDefaultCheckinStatusId(checkinStatusId);
    }, [checkinStatusId])

    useEffect(() => {
        setUsePaveCheckin(paveCheckin);
    }, [paveCheckin])

    useEffect(() => {
        setPaveApiKey(paveConfig?.paveApiKey);
        setPaveApiSecret(paveConfig?.paveApiSecret);
        setPaveUserName(paveConfig?.paveUserName);
    }, [paveConfig])

    useEffect(() => {
        setDefaultCheckoutStatusId(checkoutStatusId);
    }, [checkoutStatusId])

    const isStepActive = (step) => {
        if (step.isActive) {
            return step.isActive;
        }
        else {
            let activeSteps = step.steps?.find(s => s.isActive);
            return activeSteps ? true : false;
        }
    }

    const onClose = () => {
        setUsePaveCheckin(paveCheckin);
        setPaveApiKey(paveConfig?.paveApiKey);
        setPaveApiSecret(paveConfig?.paveApiSecret);
        setPaveUserName(paveConfig?.paveUserName);
        setDefaultCheckinStatusId(checkinStatusId);
        setDefaultCheckoutStatusId(checkoutStatusId);
        handleClose();
    }

    const allStepsActive = (step) => {
        if (step.steps) {
            let activeSteps = step.steps?.find(s => s.isActive);
            let notActiveSteps = step.steps?.find(s => !s.isActive);
            return activeSteps && notActiveSteps ? false : true;
        }
        return true;
    }

    const isStepRequired = (step) => {
        if (step.required) {
            return step.required;
        }
        else {
            let requiredSteps = step.steps?.find(s => s.required);
            return requiredSteps ? true : false;
        }
    }

    const getStepsName = (steps, index) => {
        if (steps) {
            return <Grid spacing={1} container>
                <Grid item xs={12}> Included screens: </Grid>
                {steps.map((s, subIndex) =>
                    <Grid item>
                        <Chip
                            label={s.name}
                            className={classes.chip}
                            avatar={steps?.length > 1 &&
                                <Checkbox
                                    color="default"
                                    checked={s.isActive}
                                    onChange={(e, value) => onChangeStep(e, index, subIndex)}
                                />}
                        />
                    </Grid>
                )}
            </Grid>;
        }
    }

    const onChangeDefaultStatus = (p_statusId) => {
        if (isCheckin)
            setDefaultCheckinStatusId(p_statusId);
        else if (isCheckout)
            setDefaultCheckoutStatusId(p_statusId);
    }

    const onChangeStep = (e, index, subIndex = null) => {
        let mySteps = steps?.map(s => ({ ...s }));
        let myStep = mySteps[index];

        if (subIndex != null)
            myStep = myStep?.steps[subIndex];

        if (myStep.isActive != undefined)
            myStep.isActive = e.target.checked ? 1 : 0;
        else {
            myStep.steps = [...myStep.steps]?.map((s) => {
                s.isActive = e.target.checked ? 1 : 0;
                return s;
            })
        }

        if (subIndex != null)
            mySteps[index].steps[subIndex] = { ...myStep };

        else
            mySteps[index] = { ...myStep };

        setSteps(mySteps);
    }

    const getFlattenSteps = () => {
        let flatten = [...steps?.filter(s => s.steps).flatMap(s => s.steps), ...steps?.filter(s => !s.steps)];
        let orderedFlatten = orderBy(flatten, ['isActive', 'stepNumber'], ['desc', 'asc']);
        return orderedFlatten;
    }

    const { selectedVehicleTypes } = useSelector(state => state.mobileWorkflowState);

    const validate = (steps) => {
        // VIN or Asset ID is required for Check-In SLA
        if (isCheckin) {
            const found = steps?.find(s => (s.screenTypeId === ScanVinScreenTypeId || s.screenTypeId === AssetIdScreenTypeId) && s.isActive);
            let valid = found != null;
            return { valid: valid, message: valid ? '' : "VIN or Asset ID is required for Check-In SLA" };
        }
        return { valid: true, message: '' };
    }

    const handleSave = async () => {
        setIsSaving(true);

        let stepDefinitionToSave = {
            id: stepDefinition?.id,
            clientId: clientConfig?.id,
            name: stepDefinition?.name,
            stepDefinitionTypeId: stepDefinitionTypeId,
            stepDefinitionDetailsList: getFlattenSteps(),
            selectedVehicleTypes
        }
        let res = await updateDetails(stepDefinitionToSave);

        let clientStatusEntity = {
            clientId: clientConfig?.id,
            statusId: isCheckin ? defaultCheckinStatusId : defaultCheckoutStatusId
        }

        let clientPaveEntity = {
            clientId: clientConfig?.id,
            usePave: usePaveCheckin,
            apiKey: paveApiKey,
            apiSecret: paveApiSecret,
            userName: paveUserName
        }

        if (stepDefinitionTypeId == StepDefinitionTypeById.CHECKIN) {
            let responseUpdateStatus = await updateDefaultCheckinStatus(clientStatusEntity);
            let responseUpdatePaveCheckin = await updateCheckinWithPave(clientPaveEntity);
        }

        if (stepDefinitionTypeId == StepDefinitionTypeById.CHECKOUT) {
            let responseUpdateStatus = await updateDefaultCheckoutStatus(clientStatusEntity);
        }

        setIsSaving(false);
        handleClose();
        if (!res?.error) {
            refetchCheckin();
            refetchCheckout();
            NotificationProvider.success('Saved successfully');
        } else {
            NotificationProvider.error('Failed to save');
        }
    };

    const isCheckin = stepDefinitionTypeId == StepDefinitionTypeById.CHECKIN;
    const isCheckout = stepDefinitionTypeId == StepDefinitionTypeById.CHECKOUT;

    return (
        <Grid container spacing={3} justifyContent='space-between' direction='column' className={classes.wfContainer}>
            {isLoading
                ? <CircularProgress className={classes.spinner} /> :
                <>
                    {isSaving && <CircularProgress className={classes.spinner} />}
                    <Grid item container justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12} container justifyContent='flex-end' className={classes.btnWrapper}>
                            <div className={classes.btn}>
                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.WORKFLOW_SLA_ACTIONS}>
                                    <Button
                                        onClick={onClose}
                                        className={classes.button}
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Authorize>
                            </div>
                            <div className={classes.btn}>
                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.WORKFLOW_SLA_ACTIONS}>
                                    <Button
                                        onClick={handleSave}
                                        disabled={validate(getFlattenSteps())?.valid == false || isSaving}
                                        className={classes.button}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        {isSaving ? "Saving..." : "Save"}
                                    </Button>
                                </Authorize>
                            </div>
                        </Grid>
                        {isCheckin && <Grid xs={12} className={classes.checkinWithPave}>
                            <PaveApiKeys
                                {...{
                                    paveApiKey,
                                    paveApiSecret,
                                    paveUserName,
                                    setPaveApiKey,
                                    setPaveApiSecret,
                                    setPaveUserName,
                                    usePaveCheckin,
                                    setUsePaveCheckin
                                }}
                            />
                        </Grid>}
                        {isCheckin && <Grid component={Paper} container direction='column' spacing={1} xs={12} item className={classes.dropdownStatuses}>
                            <Grid item><b>Default Check In Status</b></Grid>
                            <Grid item>
                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.WORKFLOW_SLA_EDIT}>
                                    <VehicleStatusView
                                        clientId={clientConfig.id}
                                        label=""
                                        value={defaultCheckinStatusId}
                                        onChange={val => onChangeDefaultStatus(+val)}
                                        readonly={false}
                                    />
                                </Authorize>
                            </Grid>
                        </Grid>}
                        {isCheckout && <Grid component={Paper} direction='column' spacing={1} xs={12} item className={classes.dropdownStatuses}>
                            <Grid item><b>Default Check In Status</b></Grid>
                            <Grid item>
                                <Authorize profile={permissionProfiles.CLIENT_CONFIG.WORKFLOW_SLA_EDIT}>
                                    <VehicleStatusView
                                        clientId={clientConfig.id}
                                        label=""
                                        value={defaultCheckoutStatusId}
                                        onChange={val => onChangeDefaultStatus(+val)}
                                        readonly={false}
                                    />
                                </Authorize>
                            </Grid>
                        </Grid>}
                    </Grid>

                    {validate(getFlattenSteps())?.valid == false &&
                        <Grid className={classes.validationAlert}>
                            <Alert severity="error">{validate(getFlattenSteps())?.message}</Alert>
                        </Grid>
                    }

                    {(!isCheckin || !usePaveCheckin) && steps?.map((step, index) =>
                        <Grid xs={12} item >
                            <Paper elevation={0} className={`${classes.listItem} ${classes.container}`}>
                                <Grid container direction="row" spacing={2} className={classes.labelWrapper}>
                                    <Grid key={index} item>

                                        <ListItem key={index} >
                                            <FormControlLabel
                                                control={
                                                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.WORKFLOW_SLA_EDIT}>
                                                        <Switch
                                                            color="primary"
                                                            checked={isStepActive(step)}
                                                            onChange={(e) => onChangeStep(e, index)}
                                                            disabled={isStepRequired(step)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    </Authorize>
                                                }
                                                label={
                                                    <div className={classes.stepName}>{step.name}</div>}
                                            />
                                        </ListItem>

                                        <ListItem key={index} className={classes.description}>
                                            {step.description}
                                        </ListItem>
                                        {step.steps && <ListItem key={index} className={classes.groupNames}>
                                            {getStepsName(step.steps, index)}
                                        </ListItem>}

                                    </Grid>

                                    <Grid item className={classes.info}>
                                        {!allStepsActive(step) && <p className={classes.requiredLabel}>Not all steps are active</p>}
                                        {isStepRequired(step) && <p className={classes.requiredLabel}>This is a required step</p>}
                                    </Grid>

                                </Grid>
                                {isStepActive(step) && screenTypeAdditionalComponent(clientConfig?.id)[step.screenTypeId]}
                            </Paper>
                        </Grid>
                    )}
                </>
            }
        </Grid >
    );
};

export default EditWorkFlow;


