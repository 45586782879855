import { FormControlLabel, Grid, Switch } from '@material-ui/core';
import CustomInput from 'components/CustomInput';
import Authorize from '../../../../../components/Authorize';
import { permissionProfiles } from '../../../../../components/Authorize/permissionProfiles';
import { StepDefinitionTypeById } from '../../enums';
import EditWorkFlowPopupStyle from './EditWorkFlowPopupStyle';
import AccordionCore from 'components/Shared/Accordion/AccordionCore';

const useStyles = EditWorkFlowPopupStyle;

export const PaveApiKeys = ({
    paveApiKey,
    paveApiSecret,
    paveUserName,
    setPaveApiKey,
    setPaveApiSecret,
    setPaveUserName,
    usePaveCheckin,
    setUsePaveCheckin
}) => {

    return (<>
        {
            <AccordionCore
                header={<b>PAVE API Configuration</b>}
                open={usePaveCheckin}
            >
                <Grid container spacing={2} direction='column'>
                    <Grid item>
                        <Authorize profile={permissionProfiles.CLIENT_CONFIG.PAVE_CHECKIN} >
                            <FormControlLabel
                                control={
                                    <Switch
                                        color="primary"
                                        checked={usePaveCheckin}
                                        onChange={(e) => setUsePaveCheckin(e.target.checked)}
                                    />
                                }
                                label="Use PAVE Check-in"
                            />
                        </Authorize>
                    </Grid>
                    <Authorize profile={permissionProfiles.CLIENT_CONFIG.PAVE_CHECKIN_CONFIG}><>
                        <Grid item>
                            <CustomInput
                                id="apiKey"
                                label='Api Key'
                                value={paveApiKey}
                                onChange={val => setPaveApiKey(val)}
                                elementType='input'
                                inputType='password'
                                shrink
                            />
                        </Grid>
                        <Grid item>
                            <CustomInput
                                id="apiSecret"
                                label='Api Secret'
                                value={paveApiSecret}
                                onChange={val => setPaveApiSecret(val)}
                                elementType='input'
                                inputType='password'
                                shrink
                            />
                        </Grid>
                        <Grid item>
                            <CustomInput
                                id="userName"
                                label='PAVE User Name'
                                value={paveUserName}
                                onChange={val => setPaveUserName(val)}
                                elementType='input'
                                shrink
                            />
                        </Grid>
                    </>
                    </Authorize>
                </Grid>
            </AccordionCore >

        }
    </>
    );
};



