import { Button, Collapse, Grid, Tooltip } from '@material-ui/core';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';
import LoadingSpinner from 'components/LoadingSpinner';
import { useState } from 'react';
import Note from './Note';
import NoteEditor from './NoteEditor';
import { useGetEntityNotesQuery } from './notesSlice';
import { NotesStyle } from './style';

const NoteList = ({
    notes,
    entityId,
    entityType,
    collapsible = true,
    readOnly = false,
    listOnly = false,
    title,
    style,
    showEmpty = false,
    editPermissionProfile = permissionProfiles.NOTES.EDIT_NOTES
}) => {
    const classes = NotesStyle();
    let { data: notesFromApi, error: notesError, isFetching: isLoadingNotes } = useGetEntityNotesQuery({ entityId: entityId, entityType: entityType }, { skip: !entityId || !!notes });

    const noteData = notes ?? notesFromApi ?? [];
    const [addNew, setAddNew] = useState(false);
    const [collapsed, setCollapsed] = useState(false);

    const getContainer = (content) => {
        if (collapsible) {
            return (<Collapse in={collapsed} timeout="auto" unmountOnExit >
                {content}
            </Collapse>)
        }
        return content;
    }
    return (
        <>
            <Grid container spacing={1} direction='column' style={style ? style : { position: 'relative', minHeight: collapsible ? 0 : 30, padding:'10px'}}>
                <Grid item container justifyContent='space-between'>
                    {title && !!noteData?.length && <h4 className={classes.titleText}>{title}</h4>}
                    {!listOnly && <div>
                        <Grid container alignItems='center' className={classes.title} onClick={() => collapsible && setCollapsed(!collapsed)}>
                            <MessageOutlinedIcon className={classes.commentIcon} />
                            <div>{`Notes (${noteData?.length ?? 0})`}</div>
                        </Grid>
                    </div>}
                    {!readOnly && <Authorize profile={editPermissionProfile}>
                        <Tooltip title='Add Note'>
                            <Button style={{ marginLeft: 'auto' }} onClick={() => setAddNew(true)} color="primary" variant='outlined' >
                                Add Note
                            </Button>
                        </Tooltip>
                    </Authorize>}
                </Grid>
                {getContainer(
                    <Grid className={classes.noteListWrapper} item container spacing={2} direction='column'>
                        {noteData?.map((note, index) =>
                            <Grid container item key={index}>
                                <Note note={note} editPermissionProfile={editPermissionProfile} readOnly={readOnly} />
                            </Grid>)}
                    </Grid>
                )}
                {!isLoadingNotes && !(!!noteData?.length) && (!listOnly || showEmpty) && <EmptyPlaceholder text='No Notes Found' />}
            </Grid>
            {addNew && <NoteEditor note={{ entityId, entityType }} onClose={() => setAddNew(false)} />}

            <LoadingSpinner loading={isLoadingNotes} />
        </ >
    );
}
export default NoteList;