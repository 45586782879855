import { modes } from "../modes";
import { permissions } from "../permissions/permissions";

export const invoicePermissionProfile = {
    INVOICES_VIEW: {
        [modes.EDIT_PERMISSION]: [
            permissions.INVOICES.READ_TRANSPORTATION_INVOICE
            ,permissions.INVOICES.READ_STORAGE_INVOICE
            ,permissions.INVOICES.READ_MECHANICAL_INVOICE
        ],
    },
    INVOICES_MANAGE: {
        [modes.EDIT_PERMISSION]: [permissions.STORAGE_INVOICES.MANAGE],
    },
    CAN_SEE_DETAILS: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.EDIT_TRANSPORTATION_INVOICE,
            permissions.INVOICES.EDIT_MECHANICAL_INVOICE,
            permissions.INVOICES.EDIT_MECHANICAL_INVOICE],
    },
    CAN_SEE_STATUS_COLUMN: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.APPROVE_DELETE_TRANSPORTATION_INVOICE,
            permissions.INVOICES.APPROVE_DELETE_STORAGE_INVOICE,
            permissions.INVOICES.APPROVE_DELETE_MECHANICAL_INVOICE],
    },
    EDIT_TRANSPORTATION_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.EDIT_TRANSPORTATION_INVOICE],
    },
    APPROVE_DELETE_TRANSPORTATION_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.APPROVE_DELETE_TRANSPORTATION_INVOICE],
    },
    READ_TRANSPORTATION_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.READ_TRANSPORTATION_INVOICE],
    },
    EDIT_STORAGE_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.EDIT_STORAGE_INVOICE],
    },
    APPROVE_DELETE_STORAGE_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.APPROVE_DELETE_STORAGE_INVOICE],
    },
    READ_STORAGE_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.READ_STORAGE_INVOICE],
    },
    EDIT_MECHANICAL_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.EDIT_MECHANICAL_INVOICE],
    },
    APPROVE_DELETE_MECHANICAL_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.APPROVE_DELETE_MECHANICAL_INVOICE],
    },
    READ_MECHANICAL_INVOICE: {
        [modes.EDIT_PERMISSION]: [permissions.INVOICES.READ_MECHANICAL_INVOICE],
    },
}