import { Collapse } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useState } from 'react';
import { ImportResultsDialogStyle } from './ImportResultsDialogStyle';

function ImportItem({ item, accessCodeGeneratedItems, displayFields }) {
    const classes = ImportResultsDialogStyle();
    const [collapsed, setCollapsed] = useState(true);

    const accessCodeMessage = accessCodeGeneratedItems?.find(
        (a) => a?.item?.id === item?.id
    )?.message;

    return (
        <div className={classes.itemWrapper} onClick={() => setCollapsed(!collapsed)}>
            <div className={classes.itemHeader}>
                <div>
                    {displayFields.map((field, index) => (
                        field.value && (
                            <div key={index} className={classes.itemTitle}>
                                {field.label}: {field.value}
                            </div>
                        )
                    ))}
                </div>
                <KeyboardArrowDownIcon />
            </div>
            <Collapse in={collapsed} timeout="auto" unmountOnExit className={classes.collapseWrapper}>
                <div>{item.message}</div>
                {accessCodeMessage && <div>{accessCodeMessage}</div>}
            </Collapse>
        </div>
    );
}

export default ImportItem;
