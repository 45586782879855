export const reservationPermissions = {
    VIEW: {
        key: 'Reservations.View',
        name: 'View Reservations'
    },
    EDIT: {
        key: 'Reservations.Edit',
        name: 'Edit Reservations'
    },
    VIEW_BANKING_INFO: {
        key: 'Reservations.ViewBankingInfo',
        name: 'Reservation view banking info'
    }
}