import CryptoJS from 'crypto-js';

const storageKeyPrefix = 'pmf';
const secretKey = process.env.REACT_APP_SK_KEY || 'WvxD1eLfEAUON_WRpM8f4=';

const StorageUtil = {
  // Helper function to build the full key with the prefix
  getFullKey: (key) => `${storageKeyPrefix}${key}`,

  // Encrypt the value before storing
  encryptValue: (value) => CryptoJS.AES.encrypt(value, secretKey).toString(),

  // Decrypt the value when retrieving
  decryptValue: (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  },

  // Set item in storage
  setItem: (key, value) => {
    const fullKey = StorageUtil.getFullKey(key);

    // Ensure the value is a string before encrypting
    if (typeof value !== 'string') {
      console.warn(`Value for key "${key}" is not a string. Converting to string.`);
      value = String(value);
    }

    try {
      const encryptedValue = StorageUtil.encryptValue(value);
      localStorage.setItem(fullKey, encryptedValue); // Store the encrypted value
    } catch (error) {
      console.error(`Error encrypting value for key "${key}":`, error);
    }
  },

  // Get item from storage
  getItem: (key) => {
    const fullKey = StorageUtil.getFullKey(key);
    const item = localStorage.getItem(fullKey);

    if (item) {
      try {
        const decryptedValue = StorageUtil.decryptValue(item);

        if (!decryptedValue) {
          throw new Error('Decryption failed or returned an empty string.');
        }
        return decryptedValue; // Return the decrypted value directly
      } catch (error) {
        console.error(`Error decrypting value for key "${key}":`, error);
        return null;
      }
    }
    return null; // Return null if item does not exist
  },

  // Remove item from storage
  removeItem: (key) => {
    const fullKey = StorageUtil.getFullKey(key);
    localStorage.removeItem(fullKey); // Remove from storage
  },

  // Clear all items from storage
  clear: () => {
    Object.keys(localStorage)
      .filter(key => key.startsWith(storageKeyPrefix))
      .forEach(key => {
        localStorage.removeItem(key);
      });
  }
};

export default StorageUtil;
