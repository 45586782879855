import { ListItem } from "@material-ui/core";
import UserTaskItem from "./UserTaskItem";
import LoadingSpinner from "components/LoadingSpinner";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import { useEffect } from "react";


const UserTasksList = ({ userId, editing = true, onChange, userTasks, isLoadingUserTasks }) => {

    useEffect(()=>{
        if(onChange){
            onChange(userTasks)
        }
    },[userTasks])

    const onTaskUpdated = (updatedTask) =>
    {
        const updatedTasks = userTasks.map(task =>
            task.id === updatedTask.id ? updatedTask : task
        );
        if (onChange) {
            onChange(updatedTasks);
        }
    }

    return (
        <>
            <LoadingSpinner loading={isLoadingUserTasks} />
            <div>
                {userTasks?.map((item, index) => {
                    return <div key={item.id}>
                        <ListItem>
                            <UserTaskItem task={item} userId={userId} onTaskUpdated={onTaskUpdated} hideActionButton={!editing}/>
                        </ListItem>
                    </div>
                })}
                {!isLoadingUserTasks && !userTasks?.length && <EmptyPlaceholder text="There are no available tasks for this user" />}
            </div>
        </>
    );
}
export default UserTasksList;