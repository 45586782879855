import { modes } from "../modes";
import { roles } from "../roles";
import {permissions} from "../permissions/permissions";

export const inspectionPermissionProfile = {
    INSPECTION_CREATE: {
        [modes.EDIT_PERMISSION]: [permissions.INSPECTION.INSPECTIONS_ACCESS],
        [modes.EDIT]: [roles.ADMIN, roles.ACCOUNT_MANAGER],
    },
    INSPECTION_VIEW: {
        [modes.EDIT_PERMISSION]: [permissions.INSPECTION.INSPECTIONS_ACCESS],
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
    },
    INSPECTION_ACCESS: {
        [modes.EDIT_PERMISSION]: [permissions.INSPECTION.INSPECTIONS_ACCESS],
    }
}