import { IconCar, IconMechanicalsColor, IconTasks, IconTicket, IconTransportColor,IconReservations } from "icons";
import { bulkActionsStyle } from "./style";
import { FlagEntityTypes } from "features/flag/flagsConsts";
import { useGetNoteForFlagQuery } from "features/flag/flagsSlice";
import { Grow, Popper } from "@material-ui/core";

export const FlagTooltip = ({
    sourceType,
    anchorEl,
    closePopover,
    entityType,
    flagObj,
}) => {

    const classes = bulkActionsStyle();

    let { data: note, error: noteError, isFetching: isLoadingNote } = useGetNoteForFlagQuery(flagObj?.id, { skip: (!flagObj?.id || flagObj?.id == 0) });

    const getTooltipTitle = () => {
        switch (sourceType ?? entityType) {
            case FlagEntityTypes.TRANSPORT:
                return <><IconTransportColor height='16' width='16' className={classes.tooltipIcon} /> {sourceType ? 'Flagged for Transport' : 'Transport is Flagged'} </>
            case FlagEntityTypes.ACTIVITY:
                return <><IconMechanicalsColor className={classes.tooltipIcon} /> {sourceType ? 'Flagged for Activity' : 'Activity is Flagged'} </>
            case FlagEntityTypes.ISSUE_TRACKER:
                return <><IconTasks height='16' width='16' className={classes.tooltipIcon} /> {'Ticket is Flagged'} </>
            case FlagEntityTypes.RESERVATION:
                return <><IconReservations  height='16' width='16' className={classes.tooltipIcon} /> {'Reservation is Flagged'} </>
            default:
                return <><IconCar height='16' width='16' className={classes.tooltipIcon} /> Asset is flagged </>
        }
    }

    return (
        <Popper
            id="mouse-over-popover"
            style={{ zIndex: 9999 }}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={closePopover}
            transition
            marginThreshold={100}
            disableRestoreFocus
        >
            {({ TransitionProps }) => (
                <Grow {...TransitionProps} timeout={350}>
                    <div className={classes.flagPopover}>
                        <div className={classes.flagTooltip}>{getTooltipTitle()}</div>
                        {flagObj?.noteLocal ?
                            <div className={classes.flagNote}>{flagObj?.noteLocal}</div> :
                            <>{note && <div className={classes.flagNote}>{note?.content}</div>}</>
                        }
                    </div>
                </Grow>
            )}
        </Popper>
    )

}