import { Grid } from "@material-ui/core";
import GraphHeader from "features/landing/components/WorkorderDashboard/GraphHeader/GraphHeader";
import { GraphLoader } from "features/landing/components/WorkorderDashboard/GraphLoader";
import { workorderDashboardPageStyles } from "features/landing/components/WorkorderDashboard/WorkorderDashboardPageStyles";
import { useEffect, useState, useMemo } from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const ActiveUsersGraphic = ({ users = [], loading, roles = [] }) => {
    const classes = workorderDashboardPageStyles();
    const RADIAN = Math.PI / 180;
    const graphColors = ['#0090FE', '#AADAFF', '#A6F6E3', 'black', 'red', 'green'];

    const graphValue = useMemo(() => {
        if (!users.length || !roles.length) return [];

        const filteredUsers = users.filter(user => user?.assignedRole?.length > 0 && !user?.isInactiveUser);

        return roles.map(role => {
            const count = filteredUsers.filter(user =>
                user.assignedRole.some(x => x.roleId === role.key)
            ).length;

            return { name: role?.normalizedName, value: count };
        }).filter(val => val.value > 0); 
    }, [users, roles]);
    const CustomTooltip = ({ payload }) => (
        <div className={classes.tooltip}>
            {payload?.[0]?.payload && (
                <p>
                    <b>{payload[0].payload.name} </b>
                    {payload[0].payload.value}
                </p>
            )}
        </div>
    );
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <Grid container direction="column" className={classes.graphWrapper} style={{ height: 350 }}>
            <Grid item>
                <GraphHeader title={'Active Users'} />
            </Grid>
            <Grid item style={{ height: 300 }}>
                <ResponsiveContainer>
                    <PieChart>
                        <Pie
                            data={graphValue}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={80}
                            paddingAngle={0.5}
                        >
                            {graphValue.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={graphColors[index % graphColors.length]} />
                            ))}
                        </Pie>
                        <Legend
                            height={36}
                            iconType="circle"
                            layout="horizontal"
                            verticalAlign="bottom"
                            iconSize={10}
                            formatter={(value) => <span style={{ color: "#596579", fontWeight: 500 }}>{value}</span>}
                        />
                        <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                </ResponsiveContainer>
            </Grid>
            <GraphLoader loading={loading} />
        </Grid>
    );
};

export default ActiveUsersGraphic;
