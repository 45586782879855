import { Dialog, DialogContent, Grid, IconButton, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import NoteList from './NoteList';
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import { NotesStyle } from './style';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { useGetEntityNotesQuery } from './notesSlice';

const NotesTooltip = ({ notes, entityId, entityType, readonly = false}) => {

    let { data: notesFromApi, error: notesError, isFetching: isLoadingNotes } = useGetEntityNotesQuery({ entityId: entityId, entityType: entityType }, { skip: !entityId || !!notes });

    const noteData = notes ?? notesFromApi ?? [];

    const classes = NotesStyle();
    const [listOpen, setListOpen] = useState(false);

    useEffect(() => {
    }, []);

    return (
        <>
            {!!noteData?.length ?
                <Tooltip title={`Click to view ${noteData?.length} notes`}>
                    <IconButton size='small' onClick={() => setListOpen(true)} className={`${classes.notesTooltipOn} ${classes.notesTooltip}`}>
                        <MessageOutlinedIcon />
                    </IconButton>
                </Tooltip> : !readonly &&
                <Authorize profile={permissionProfiles.NOTES.EDIT_NOTES}>
                    <Tooltip title={'Add notes'}>
                        <IconButton size='small' onClick={() => setListOpen(true)} className={`${classes.notesTooltipOff} ${classes.notesTooltip}`}>
                            <MessageOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Authorize>
            }
            <Dialog open={listOpen} onClose={() => setListOpen(false)} >
                <DialogContent >
                    <NoteList notes={noteData} collapsible={false} entityId={entityId} entityType={entityType} readOnly={readonly} />
                </DialogContent>
            </Dialog>
        </>
    );
}
export default NotesTooltip;