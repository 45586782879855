import { modes } from "../modes";
import { roles } from "../roles";
import { permissions } from "../permissions/permissions";

export const assetsPermissionProfiles = {
    ASSET_AUDIT: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.SUPERVISOR],
    },
    ASSET_EDIT: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC, roles.SUPERVISOR],
    },
    ASSET_IMPORT: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.IMPORT]
    },
    ASSET_IMPORT_INSPECTION: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.SUPERVISOR],
    },
    ADD_ACTIVITY: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC, roles.SUPERVISOR]
    },
    EDIT_ACTIVITY: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC, roles.SUPERVISOR]
    },
    EDIT_MECHANICAL: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC, roles.SUPERVISOR]
    },
    VIEW_ACTIVITY_PRICING: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN]
    },
    DELETE_ACTIVITY: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.SUPERVISOR]
    },
    VEHICLE_ACTIVITY_VIEW_AUDIT: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN, roles.SUPERVISOR]
    },
    ASSET_EDIT_READINESS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.SUPERVISOR],
    },
    VIEW_ACTIVITY_DAYS_COMPLETED_IN: {
        [modes.READONLY]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN, roles.SUPERVISOR]
    },
    VIEW_FLAGGED_ASSETS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.SUPERVISOR],
        [modes.READONLY]: [roles.CLIENT]//Only if the client has the configuration set to see flags
    },
    FLAG_ASSETS: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.SUPERVISOR],
    },
    ASSET_DELETE_ACTIVITY: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.DELETE_ACTIVITY]
    },
    ASSET_DELETE: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.DELETE]
    },
    ASSET_DELETE_PHOTO: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.DELETE_PHOTO]
    },
    EDIT_VIN_ASSET: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.EDIT_VIN_PERMISSIONS],
    },

    //NEW PERMISSION
    ASSETS_PAGE_CAN_SEE_FLAGS: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_PAGE_FLAGS_READ_ONLY
            , permissions.VEHICLE.ASSETS_PAGE_FLAG_ADD_REMOVE],
    },
    ASSETS_PAGE_ADD_ASSET: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_PAGE_ADD_ASSET],
    },
    ASSETS_PAGE_IMPORT_ASSETS: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_PAGE_IMPORT_ASSETS],
    },
    ASSETS_PAGE_AUDIT: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_PAGE_AUDIT],
    },
    ASSETS_PAGE_SHORT_REPORT: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_PAGE_SHORT_REPORT],
    },
    ASSETS_PAGE_EVERYTHING_ON_THE_PAGE_REPORT: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_PAGE_EVERYTHING_ON_THE_PAGE_REPORT],
    },
    ASSETS_PAGE_LONG_REPORT: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_PAGE_LONG_REPORT],
    },
    ASSETS_PAGE_SHOW_MAP: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_PAGE_SHOW_MAP],
    },
    ASSETS_PAGE_VIEW_ASSET_TABLE: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_PAGE_VIEW_ASSET_TABLE],
    },
    ASSETS_PAGE_FLAG_ADD_REMOVE: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_PAGE_FLAG_ADD_REMOVE],
    },
    ASSETS_PAGE_EDIT: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_PAGE_EDIT],
    },
    ASSETS_PAGE_DELETE: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_PAGE_DELETE],
    },
    ASSETS_DETAIL_PAGE_DOWNLOAD_CONDITION_REPORT: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [
            permissions.VEHICLE.ASSETS_DETAIL_PAGE_DOWNLOAD_CONDITION_REPORT,
            permissions.VEHICLE.ASSETS_DETAIL_PAGE_DOWNLOAD_CONDITION_REPORT_FREE_ACCESS,
        ],
    },
    ASSETS_DETAIL_PAGE_CREATE_LOAD: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_CREATE_LOAD],
    },
    ASSETS_DETAIL_PAGE_UPLOAD_DOCUMENT: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_UPLOAD_DOCUMENT],
    },
    ASSETS_DETAIL_PAGE_VIEW_PHOTO: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_VIEW_PHOTO,
        permissions.VEHICLE.ASSETS_DETAIL_PAGE_EDIT_ADD_PHOTO],
    },
    ASSETS_DETAIL_PAGE_EDIT_ADD_PHOTO: {
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_EDIT_ADD_PHOTO],
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC, roles.SUPERVISOR],
    },
    ASSETS_DETAIL_PAGE_DELETE_PHOTO: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_DELETE_PHOTO],
    },
    ASSETS_DETAIL_PAGE_VIEW_NOTE_PHOTO: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_VIEW_NOTE_PHOTO],
    },
    ASSETS_DETAIL_PAGE_EDIT_ADD_NOTE_PHOTO: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_EDIT_ADD_NOTE_PHOTO],
    },
    ASSETS_DETAIL_PAGE_VIEW_NOTE_ASSET: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_VIEW_NOTE_ASSET
            , permissions.VEHICLE.ASSETS_DETAIL_PAGE_EDIT_ADD_NOTE_ASSET],
    },
    ASSETS_DETAIL_PAGE_EDIT_ADD_NOTE_ASSET: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_EDIT_ADD_NOTE_ASSET],
    },
    ASSETS_DETAIL_PAGE_VIEW_ACTIVITY_LOG: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_VIEW_ACTIVITY_LOG
            , permissions.VEHICLE.ASSETS_DETAIL_PAGE_EDIT_ACTIVITY_LOG
            , permissions.VEHICLE.ASSETS_DETAIL_PAGE_ADD_ACTIVITY_LOG],
    },
    ASSETS_DETAIL_PAGE_ADD_ACTIVITY: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_ADD_ACTIVITY],
    },
    ASSETS_DETAIL_PAGE_EXPORT_CSV: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_EXPORT_CSV],
    },
    ASSETS_DETAIL_PAGE_ADD_EDIT_NOTE_ACTIVITY: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_ADD_EDIT_NOTE_ACTIVITY],
    },
    ASSETS_DETAIL_PAGE_ADD_ACTIVITY_LOG: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC, roles.SUPERVISOR],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_ADD_ACTIVITY_LOG],
    },
    ASSETS_DETAIL_PAGE_EDIT_ACTIVITY_LOG: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_EDIT_ACTIVITY_LOG],
    },
    ASSETS_DETAIL_PAGE_ACTIVITY_LOG_EXPORT: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_ACTIVITY_LOG_EXPORT],
    },
    ASSETS_DETAIL_PAGE_DELETE_ACTIVITY_LOG: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_DELETE_ACTIVITY_LOG],
    },
    ASSETS_DETAIL_PAGE_VIEW_WO_READ_ONLY: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_VIEW_WO_READ_ONLY],
    },
    ASSETS_DETAIL_PAGE_EDIT_WO: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_EDIT_WO],
    },
    ASSETS_DETAIL_PAGE_ACTIVITY_DAYS_COMPLETED_IN_COLUMN: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_ACTIVITY_DAYS_COMPLETED_IN_COLUMN],
    },
    ASSETS_DETAIL_PAGE_ACTIVITY_LOG_INTERNAL_COST_COLUMN: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_ACTIVITY_LOG_INTERNAL_COST_COLUMN],
    },
    ASSETS_DETAIL_PAGE_ACTIVITY_LOG_CHARGE_COLUMN: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.VEHICLE.ASSETS_DETAIL_PAGE_ACTIVITY_LOG_CHARGE_COLUMN],
    }
}