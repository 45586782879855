import { makeStyles } from "@material-ui/core";

export const InvoicePageStyle = makeStyles((theme) => ({
    tabs: {
        padding: '1rem 3em 0 1.7rem'
    },
    leftIcon: {
        marginTop: 5,
        marginRight: 10
    },
    rightIcon: {
        fontSize: '200%'
    },
    marketIcon: {
        marginRight: "0.5rem",
    },
    marketIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    marketsList: {
        padding: "1em",
        color: theme.palette.text.secondary,
        //opacity: 0.6,
        //fontWeight: 'lighter'
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '100'
    },
    header: {
        padding: '16px',
        margin: "0 1rem 1rem 0",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        },
        color: theme.palette.common.white,
        // padding: "0 1em",
        border: 0
    },
    icon: {
        fill: theme.palette.common.white,
    },
    headerMobile: {
        marginRight: 0
    },
    lotMobile: {
        maxWidth: "100%",
        flexBasis: "100%"
    },
    card: {
        margin: "0 1rem 1rem 0",
        padding: '1rem',
        height: '90%'
    },
    cardLink: {
        textDecoration: 'none'
    },
    title: {
        margin: 0,
        padding: 0
    },
    subTitle: {
        margin: 0,
        padding: 0,
        color: theme.palette.text.secondary,
        opacity: 0.6,
        fontWeight: 'lighter'
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 20
    },
    cardMobile: {
        margin: "0 0 1rem 0",
    },
    deletePopup: {
        padding: '1em',
        maxWidth: 400,
        margin: '1em',
        fontWeight: 600
    },
    withMarginTop:{
        paddingTop:'15px',
        backgroundColor: theme.palette.common.white,
    },
    tabsWrapper:{
        margin:0, 
        padding:"0.8em 0"
    },
}));
