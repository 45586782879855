
export const TextElement = ({ value, fontSize = '1em', parse = true }) => {
    let valueStr = "";
    try {
        valueStr = (value && parse ? JSON.parse(value ?? "") : "")?.toString()
    } catch (e) {

    }

    return (
        <div style={{ fontSize }}>{valueStr}</div>
    )
}