
import { ReservationDocumentsStyle } from "./ReservationDocumentsStyle";
import {  Grid ,Tooltip} from "@material-ui/core";
import DropZoneInDialog from "components/DropZoneInDialog";
import LoadingSpinner from "components/LoadingSpinner";
import FadeInImage from "features/vehicles/components/FadeInImage";
import ConfirmationPopup from "components/ConfirmationPopup";
import React, { useState } from 'react';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const DocumentForReservation = ({ typeName,onSave,img,onDelete,selectFile,confirmBackup=true,uploadingAttachment=false,permissionViewEditFile=true}) => {
    const classes = ReservationDocumentsStyle();
    
    const [openPopup, setOpenPopup] = useState(false);
    const [file, setFile] = useState(false);
    
    const uploadAttachment =(file)=>{
        if(img?.url && confirmBackup){
            setFile(file)
        setOpenPopup(true)
        }
        else{
            onSave(file,false)
        }
        
        
    }
    const confirmBackUp =(backup)=>{
        setOpenPopup(false)
        onSave(file,backup)
    }

    const deleteAttachment = async (img)=>{
        await onDelete(img)
    }
    let imagesForCarousel={}
    imagesForCarousel[img?.docName]=[img]
    

    return (
        <Grid item xs={12} className={classes.typeDocumentWaper} >
                    <div className={classes.boxTitle}>
                    {typeName}
                    </div>
                    {permissionViewEditFile?
                    <Grid  container direction="row-reverse" alignItems="right" spacing={2}  justifyContent="flex-start" classname={classes.attacment} > 
                        <Grid item xs={6} >
                    <DropZoneInDialog
                            labelDialog={selectFile?`Change Attachment`:`Select ${typeName}`}
                           // buttonLabel={ <Tooltip title={`Add Attachment`}><IconAddCircle /></Tooltip> }
                           buttonLabel={ selectFile?`Change Attachment`:`Select ${typeName}` }
                            btnStyle={"outlined"}
                            onSave={uploadAttachment}
                            accept = {{
                                'image/jpeg': ['.jpg', '.jpeg'],
                                'application/pdf': ['.pdf'],
                                'image/png': ['.png']
                            }}
                            actionButtonLabel="Upload"
                        />
                        <LoadingSpinner loading={uploadingAttachment} /></Grid>
                 {img?.url && <Grid item xs={6}>
                 <FadeInImage

                     img={img}
                     alt={`Attachment`}
                     onDelete={deleteAttachment}
                     showCarousel={true}
                     imagesForCarousel={imagesForCarousel}
                     imageGroupFieldName="docName"
                     showFileName="true"
                 />
             </Grid>
             }
              </Grid>:<>{img?.url ?<Tooltip title={  `the ${typeName} was submitted, you do not have permissions to view the file`}><div>File exist</div></Tooltip>:<div>   You do not have permissions to upload from</div>}</>}
              
                <Dialog open={openPopup} onClose={() => setOpenPopup(false)} aria-labelledby='dialog-title'>
            <DialogTitle id={'dialog-title'} >Save Back up</DialogTitle>
            <DialogContent>
            You selected a new document to replace the existing one, would you like to back up the existing file?
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>confirmBackUp(false)} color='primary'>
                    Save and overwrite
                </Button>
                <Button  onClick={()=>confirmBackUp(true)} color='primary'>
                    Save and back up
                </Button>
            
            </DialogActions>
        </Dialog>
                </Grid> 
            
    )
};

export default DocumentForReservation;
