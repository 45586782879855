import { Button, Grid, Paper } from "@material-ui/core"
import { IconAccessCode, IconAdd } from "icons";
import { VehicleAccessStyle } from "./VehicleAccessStyle";
import { useGetAccessesByVehicleIdQuery } from "features/access/accessSlice";
import { useGetClientsQuery } from "features/clients/clientSlice";
import EmptyPlaceholder from "components/EmptyPlaceholder/EmptyPlaceholder";
import { VehicleAccessListItem } from "./VehicleAccessListItem";
import { AccessList } from "./AccessList";
import { useState } from "react";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import Authorize from "components/Authorize";
import { useGetAllVendorsQuery } from "features/vendors/vendorSlice";

export const VehicleAccess = ({ vehicleId, clientId }) => {
    const classes = VehicleAccessStyle();

    let { data: accessData, error: accessError, isLoading: isLoadingAccess } = useGetAccessesByVehicleIdQuery(vehicleId);
    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetAllVendorsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);

    const [openDialog, setOpenDialog] = useState(false)

    const vendorsLookup = vendors?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});


    const clientLookup = clients?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    accessData = accessData?.map(access => ({
        ...access,
        clientName: clientLookup?.[access?.clientId]?.name,
        carrierName: vendorsLookup?.[access?.carrierId]?.name,
    }));


    return (
        <Paper className={classes.card}>
            <Grid item container justifyContent='space-between' className={classes.titleWrapper}>
                <div>
                    <Grid container alignItems='center' className={classes.title}>
                        <IconAccessCode className={classes.commentIcon} />
                        <div>Access</div>
                    </Grid>
                </div>
                <Authorize profile={permissionProfiles.ACCESS.MANAGE_ACCESS}>
                    <Button color="primary" variant="contained" startIcon={<IconAdd />} onClick={() => setOpenDialog(true)}>
                        Add to Access Group
                    </Button>
                </Authorize>
            </Grid>
            {!!accessData?.length ? accessData?.map((access, key) => (
                <div key={access.id} className={classes.access}><VehicleAccessListItem access={access} vehicleId={vehicleId} /></div>
            )) : <EmptyPlaceholder text='No Accesses Found' />}

            <AccessList vehicleId={vehicleId} openDialog={openDialog} onCloseDialog={() => setOpenDialog(false)} clientId={clientId} />
        </Paper>
    )
}