import { Grid } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { CreatableDropdown } from 'components/CreatableDropdown/CreatableDropdown';
import CustomInput from 'components/CustomInput';
import { setAccessToEdit } from 'features/access/accessStateSlice';
import { useGetClientsQuery } from 'features/clients/clientSlice';
import { useGetDriversQuery } from 'features/drivers/driversSlice';
import { selectIsClient } from 'features/user/userSlice';
import { useGetAllVendorsQuery } from 'features/vendors/vendorSlice';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const CarrierFields = ({ }) => {

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();
    const isClient = useSelector(selectIsClient);

    const onChangeValue = (key, val) => {
        dispatch(setAccessToEdit({ ...accessToEdit, [key]: val }))
    }

    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetAllVendorsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery();
    let { data: drivers, error: driverError, isLoading: isLoadingDrivers } = useGetDriversQuery();

    const vendorsLookup = vendors?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const [scac, setScac] = useState()
    const [newVendor, setNewVendor] = useState(null)
    
    if (newVendor) { vendors = [...vendors, newVendor] }

    useEffect(() => {
        setScac(vendorsLookup?.[accessToEdit?.carrierId]?.scac);
        // dispatch(setAccessToEdit({ ...accessToEdit, ["scac"]: vendorsLookup?.[accessToEdit?.carrierId]?.scac }))
    }, [])


    const onAddCarrier = async (val) => {
        setNewVendor({...val, id: 0})
        setScac(null)
        dispatch(setAccessToEdit({
            ...accessToEdit,
            ["carrierId"]: 0,
            ["carrierName"]: val.name,
            ["scac"]: null,
            ["drivers"]: [],
        }));
    }

    const onSelectCarrier = (val) => {
        let carrierScac = vendors?.find(t => t.id === val)?.scac;
        setScac(carrierScac);
        dispatch(setAccessToEdit({
            ...accessToEdit,
            ["carrierId"]: val,
            ["carrierName"]: null,
            ["scac"]: carrierScac,
            ["drivers"]: drivers?.filter(d => d.vendorId == val),
        }));
    }

    const onChangeScac = (val) => {
        onChangeValue("scac", val);
        setScac(val)
    }

    return (
        <>
            <Grid container direction='row' spacing={2}>
                <Authorize profile={permissionProfiles.ACCESS.CLIENT_FIELDS}>
                    <Grid item xs={4}>
                        <CustomInput
                            label='Client'
                            elementType='dropdown'
                            value={accessToEdit?.clientId}
                            values={clients}
                            onChange={(val) => onChangeValue("clientId", val)}
                            showEmpty
                        />
                    </Grid>
                </Authorize>
                <Grid item xs={isClient ? 6 : 4}>
                    <CreatableDropdown
                        options={vendors}
                        value={accessToEdit?.carrierId}
                        required
                        addValue={onAddCarrier}
                        label='Carrier'
                        onChange={(val) => onSelectCarrier(val)}
                    />
                </Grid>
                <Grid item xs={isClient ? 6 : 4}>
                    <CustomInput
                        id="scac"
                        label='SCAC'
                        elementType='input'
                        value={scac}
                        onChange={(val) => onChangeScac(val)}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default CarrierFields;