export const reservationsStatuses = {
    New: 1,
    WaitingDocuments: 2,
    DocumentsReceived: 3,
    VehiclesPickedUp: 4,
    Returned: 5,
    Billing: 6,
    Cancelled: 7,
}

export const reservationStatusList = [
    {
        id: reservationsStatuses.New,
        name: 'New'
    },
    {
        id: reservationsStatuses.WaitingDocuments,
        name: 'Awaiting Documents'
    },
    {
        id: reservationsStatuses.DocumentsReceived,
        name: 'Documents Received'
    },
    {
        id: reservationsStatuses.VehiclesPickedUp,
        name: 'Vehicles Picked Up'
    },
    {
        id: reservationsStatuses.Returned,
        name: 'Returned'
    },
    {
        id: reservationsStatuses.Billing,
        name: 'Billing'
    },
    {
        id: reservationsStatuses.Cancelled,
        name: 'Cancelled'
    },
]

export const reservationsPickupType = {
    TransportToDSP: 1,
    PickUpAtPMFLocation: 2
}
export const reservationPickupTypeList = [
    {
        id: reservationsPickupType.TransportToDSP,
        name: 'Transport to DSP'
    },
    {
        id: reservationsPickupType.PickUpAtPMFLocation,
        name: 'Pick Up at PMF location'
    }
];

export const paveCheckinStatus = {
    Pending: 1,
    InProgress: 2,
    Completed: 3
}
export const paveCheckinStatusLabel = {
    [paveCheckinStatus.Pending]: 'Pending',
    [paveCheckinStatus.InProgress]: 'In progress',
    [paveCheckinStatus.Completed]: 'Completed',
}