import { createUserManager, loadUser } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';
import StorageUtil from '../utils/storageUtil';
import store from './store/store';

const authority = process.env.REACT_APP_AUTH_URL || "https://dev-parqai-auth.azurewebsites.net";
const clientid = process.env.REACT_APP_AUTH_CLIENT_ID || "parqai-web-local";

// const authority = process.env.REACT_APP_AUTH_URL || "https://localhost:5001/";
// const clientid = process.env.REACT_APP_AUTH_CLIENT_ID || "parqai-web";

const userManagerConfig = {
    client_id: clientid,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/`,
    response_type: 'code',
    scope: 'openid profile email role clientId pmf.api.read pmf.api.write lotId permission name offline_access',  // Include offline_access for refresh tokens
    authority,
    filterProtocolClaims: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: StorageUtil }),
    automaticSilentRenew: false,  // Disable automatic silent renew
};

let userManager = createUserManager(userManagerConfig);

// Helper function to add delay (for retry attempts)
const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

// Function to refresh the access token with retry logic
async function refreshAccessToken(force = false, retries = 3, delayMs = 5000) {
    try {
        const user = await userManager.getUser();
        if (!user) {
            console.error("User not found, redirecting to login.");
            await userManager.signinRedirect();
            return;
        }

        if (user.expired || force) {
            const tokenEndpoint = `${userManager.settings.authority}/connect/token`;
            const params = new URLSearchParams({
                grant_type: 'refresh_token',
                client_id: userManager.settings.client_id,
                refresh_token: user.refresh_token,
            });
            
            const response = await fetch(tokenEndpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: params.toString(),
            });
            if (!response.ok) throw new Error(`Token refresh failed: ${response.statusText}`);

            const tokenData = await response.json();
            if (tokenData.access_token) {
                // Update user tokens
                user.access_token = tokenData.access_token;
                user.refresh_token = tokenData.refresh_token || user.refresh_token;
                user.expires_at = Math.floor(Date.now() / 1000) + tokenData.expires_in;

                // Store updated user back in the storage
                await userManager.storeUser(user);
                // Action to update the user in Redux
                loadUser(store, userManager);

                console.log("Access token successfully refreshed.");
            } else {
                throw new Error("Token refresh response does not contain a new access token.");
            }
        }
    } catch (error) {
        if (retries > 0) {
            console.warn(`Error refreshing token. Retrying in ${delayMs}ms... (Attempts left: ${retries})`, error);
            await delay(delayMs);
            return refreshAccessToken(force, retries - 1, delayMs * 2);
        } else {
            console.error("Token refresh failed after multiple attempts. Redirecting to login.", error);
            await userManager.signinRedirect();
        }
    }
}

// Listen for access token expiration and attempt to refresh
userManager.events.addAccessTokenExpired(async () => {
    await refreshAccessToken();
});

// Periodically check and refresh the token before expiration
const tokenRefreshInterval = 1 * 60 * 1000; // 1 minute
setInterval(async () => {
    const user = await userManager.getUser();
    if (user && user.expires_in && user.expires_in < 10 * 60) {  // Refresh if token expires in less than 10 minutes
        console.log("Access token is about to expire, refreshing...");
        await refreshAccessToken(true);
    }
}, tokenRefreshInterval);

export default userManager;