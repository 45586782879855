import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    accordionBackground: {
        //backgroundColor: "#f3fbff"
    },
    accordionHeader: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%"
    },
    headerIcon: {
        marginRight: theme.spacing(1),
    },
    accordionHeaderText: { 
        fontSize: "1.0rem", 
        fontWeight: "bold", 
        color: "black", 
        paddingLeft: 3 
    },
   
}));