import { Grid, Tooltip } from '@material-ui/core';
import Authorize from 'components/Authorize';
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import { useGetClientsQuery } from 'features/clients/clientSlice';
import { orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import DataListHeader from "../../../components/DataListHeader";
import DataTable from "../../../components/DataTable/DataTable";
import ExportButton from "../../../components/ExportButton/ExportButton";
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import PageComponent from '../../../components/Page/PageComponent';
import { useGetLotsQuery } from "../../../features/lots/lotSlice";
import useSortOptions from "../../../hooks/useSortOptions";
import FilterService, { fieldTypes } from "../../../services/filterService";
import { useGetAccessGroupsQuery } from '../accessSlice';
import { DoorReleaseTypes } from './doorReleaseConsts';
import { DoorReleaseListStyle } from './doorReleaseListStyle';
import { useGetDoorReleasesQuery } from './doorReleaseSlice';
import { setFilters } from './doorReleaseStateSlice';
import { arrayToObject } from 'utils/object-util';
import { useGetAllVendorsQuery } from 'features/vendors/vendorSlice';


const DoorReleaseList = () => {
    const classes = DoorReleaseListStyle();
    const dispatch = useDispatch();

    const getDate = (date) => {
        return date ? new Date(date).toDateString() : "";
    }

    const [sortOptions, sortData] = useSortOptions();
    const { filters } = useSelector(state => state.doorReleaseState);

    let { data: doorReleases, error: doorReleaseError, isLoading: isLoadingDoorReleases } = useGetDoorReleasesQuery({ startDate: getDate(filters?.formatedDate?.value?.startDate), endDate: getDate(filters?.formatedDate?.value?.endDate) });
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: accessData, error: accessError, isFetching: isLoadingAccess } = useGetAccessGroupsQuery();
    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetAllVendorsQuery();

    doorReleases = doorReleases || [];
    clients = clients || [];
    lots = lots || [];
    accessData = accessData || [];

    const clientLookup = arrayToObject(clients, 'id');
    const accessLookup = arrayToObject(accessData, 'id');
    const vendorLookup = arrayToObject(vendors, 'id');
    const lotsLookup = arrayToObject(lots, 'id');

    const mappedDoorReleases = doorReleases?.map(doorRelease => {
        const accessGroup = accessLookup?.[doorRelease.accessGroupId];
        const clientId = accessGroup?.clientId;
        const carrierId = accessGroup?.carrierId;
        const pocName = accessGroup?.pocName;
        return {
            ...doorRelease,
            lotName: lotsLookup?.[doorRelease?.lotId]?.name,
            clientId: clientLookup?.[clientId]?.id,
            clientName: clientLookup?.[clientId]?.name,
            carrierId: vendorLookup?.[carrierId]?.id,
            carrierName: vendorLookup?.[carrierId]?.name,
            typeName: DoorReleaseTypes?.find(t => t.id == doorRelease.type)?.name,
            pocName,
            filterDate: doorRelease?.lotCreatedDate ?? doorRelease?.createdDate
        }
    });

    let columns = [
        { name: "Lot", key: "lotName" },
        { name: "Client", key: "clientName" },
        { name: "Sub Client", key: "carrierName" },
        { name: "POC Name", key: "pocName" },
        { name: "Note", key: "note" },
        //{ name: "Type", key: "typeName" },
        {
            name: "Date", key: "formatedDate", sortKey: "filterDate", component: (row) =>
                row.formatedlotDate?.length > 0 ?
                    <Tooltip title={"UTC: " + row.formatedCreatedDate}>
                        <div>{row.formatedlotDate}</div>
                    </Tooltip> :
                    <div>
                        {row.formatedCreatedDate}
                    </div>
        },
        // {
        //     name: "Photo", key: "imageUrl", component: (row) =>
        //         <div className={classes.imageHolder}>
        //             {row?.url && <FadeInImage
        //                 img={{ url: row?.url }}
        //             />}
        //         </div>
        // }
    ];

    const filterConfig = [
        { type: fieldTypes.LOT, size: 2, title: 'Lot', key: 'lotId', values: lots },
        { type: fieldTypes.TEXT, size: 2, title: 'Client', key: 'clientId', values: clients },
        { type: fieldTypes.TEXT, size: 2, title: 'Sub client', key: 'carrierId', values: vendors },
        { type: fieldTypes.DATERANGE, size: 2, title: 'Date', key: 'formatedDate', values: [] }
    ];

    let filteredData = FilterService.filter(filters, '', mappedDoorReleases, filterConfig);

    const onDropFilter = (value, prop) => {
        dispatch(setFilters({ ...filters, [prop]: { value } }));
    };

    const mapExportData = v => ({
        Lot: v.lotName,
        Client: v.clientName,
        ['Sub client']: v.carrierName,
        Type: v.typeName,
        Note: v.note,
        Date: v.formatedDate,
    });

    const isLoading = () => isLoadingDoorReleases || isLoadingClients || isLoadingLots || isLoadingAccess;

    return (
        <Authorize profile={permissionProfiles.ACCESS.DOOR_RELEASE_LIST}>
            <PageComponent
                header={<DataListHeader
                    title={'Door Releases'}
                    data={filteredData}
                    dropDownsConfig={filterConfig}
                    filters={filters}
                    onDropFilter={onDropFilter}
                    actionButtons={(
                        <Grid container spacing={1}>
                            <Grid item><ExportButton fileLabel="Door Releases" data={filteredData} mapExportData={mapExportData} /></Grid>
                        </Grid>
                    )}
                />}
            >
                <>
                    {
                        isLoading() ? <LoadingSpinner loading={isLoading()} /> :
                            <DataTable
                                columns={columns}
                                rows={orderBy(
                                    filteredData,
                                    sortOptions.columnToSort,
                                    sortOptions.sortDirection
                                )}
                                rowIdentifier='id'
                                onSort={sortData}
                                sortDirection={sortOptions.sortDirection}
                                columnToSort={sortOptions.columnToSort}
                                noItemsMessage='There are no door releases to display'
                            />
                    }
                </>
            </PageComponent>
        </Authorize>
    );
};

export default DoorReleaseList;
