import { Button, Grid, Link, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Authorize from "components/Authorize";
import { permissionProfiles } from "components/Authorize/permissionProfiles";
import CopyToClipboard from "components/CopyToClipboard";
import ExportButton from "components/ExportButton/ExportButton";
import LoadingSpinner from "components/LoadingSpinner";
import { useState } from "react";
import { useGetVehicleByReservationIdQuery } from "../../../reservationSlice";
import { AddVehicle } from "./AddVehicle";
import { ReservationVehicleList } from "./ReservationVehicleList";
import { ReservationVehicleStyles } from "./ReservationVehicleStyles";
import { IconCopy, IconDownload } from "icons";
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import { setSearchVal, setFiltersSetFromOtherScreen, setFilters } from "../../../../../features/vehicles/vehicleFiltersSlice"
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";

const ReservationVehicle = ({ reservation }) => {
  const classes = ReservationVehicleStyles();
  const [add, setAdd] = useState(false);

  let {
    data: vehicles,
    error: vehiclesError,
    isLoading: isLoadingvehicles,
  } = useGetVehicleByReservationIdQuery(reservation?.id);

  const history = useHistory();
  const mapExportData = (row) => {
    const { vin, assetId, ...others } = row;
    return {
      vin,
      assetId
    }
  }

  const dispatch = useDispatch();

  const linkVehicleTable = async () => {
    dispatch(setFilters(true));
    dispatch(setFiltersSetFromOtherScreen(true));
    dispatch(setSearchVal(`${reservation.resId} `));//space for exact search
    history.push('/assets')
  }

  return (
    <Grid container direction="column" spacing={2}>
      <LoadingSpinner loading={isLoadingvehicles} />
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="center" justifyContent="end">
          {/* <Grid item container display="felx" alignItems="center" xs={10}>
            <Grid item className={classes.title}>
              Reservation Vehicles
            </Grid>
          </Grid> */}
          <Authorize profile={permissionProfiles.RESERVATIONS.EDIT}>
            <Grid item container justifyContent="flex-end" alignItems="center" spacing={1}>
              <Grid item>
                <Tooltip title='Add vehicle'><Button variant="outlined"
                  onClick={() => setAdd(true)}
                >
                  <AddIcon style={{ fontSize: '20px' }} />
                </Button></Tooltip>
              </Grid>
              {!!vehicles?.length && <Grid item>
                <CopyToClipboard buttonComponent={<Tooltip title='Copy VINs'><Button variant="outlined"
                >
                  <IconCopy width="20" height="20" fill="#0090FE" />
                </Button></Tooltip>} text={vehicles?.map(v => v.vin)?.join(", ")} title="Copy VINs to Clipboard" />
              </Grid>}
              {!!vehicles?.length && <Grid item>
                <Tooltip title='Go to assets list'><Button variant="outlined"
                  onClick={() => linkVehicleTable(true)}
                >
                  <StorageOutlinedIcon style={{ fontSize: '20px' }} />
                </Button></Tooltip>
              </Grid>}

              <Grid item>
                <Tooltip title='Export Data'><div>
                  <ExportButton btnStyle={"outlined"} color="default" label=" " icon={<IconDownload />} mapExportData={mapExportData} data={vehicles} />
                </div>
                </Tooltip>
              </Grid>
            </Grid>
          </Authorize>
        </Grid>
      </Grid>
      {add ? (
        <AddVehicle
          setAdd={setAdd}
          client={reservation?.clientId}
          reservation={reservation}
          reservationId={reservation?.id}
          vehiclesChosen={vehicles}
        ></AddVehicle>
      ) : (
        <></>
      )}

      <ReservationVehicleList
        vehiclesList={vehicles}
        reservationId={reservation?.id}
      ></ReservationVehicleList>

    </Grid>
  );
};

export default ReservationVehicle;
