import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { isAuthorized } from "components/Authorize/authorizeUtil";
import LoadingSpinner from "components/LoadingSpinner";
import NotificationProvider from "components/NotificationProvider";
import { userPreferencesComponentIds } from "components/UserPreferences/userPreferencesConsts";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { FlagEntityTypes } from "features/flag/flagsConsts";
import { useGetLotsQuery } from "features/lots/lotSlice";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Authorize from "../../components/Authorize";
import { permissionProfiles } from "../../components/Authorize/permissionProfiles";
import BasicModal from "../../components/BasicModal";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import DataTable from "../../components/DataTable/DataTable";
import PageComponent from "../../components/Page/PageComponent";
import { IconAdd, IconDeleteBlue, IconEdit, IconMap } from "../../icons";
import FilterService, { fieldTypes } from "../../services/filterService";
import mobileBreakpoint from "../../utils/mobileBreakpoint";
import { useGetSubMarketsQuery } from "../lots/components/LotSubMarketsPage/lotSubMarketsSlice";
import { selectIsAccountManager, selectIsAdmin, selectIsOwner, selectIsSupervisor, selectUserClientId } from "../user/userSlice";
import DetailsButton from "./DetailsButton";
import EditAssetPopup from "./EditAssetPopup/EditAssetPopup";
import { FlagAsset } from "./Flags/FlagAsset";
import ImportAssets from './ImportAssets/ImportAssets';
import ParentEditAssetModal from "./ParentEditAssetModal";
import MyReportsGrid from "./Reports/MyReportsGrid";
import VehiclesMap from "./VehicleMap/VehiclesMap";
import VehiclesMapButton from "./VehicleMap/VehiclesMapButton";
import { mergeSavedFilter, setFilters, setFiltersSetFromOtherScreen } from "./vehicleFiltersSlice";
import { vehiclePageStyles } from "./vehiclePageStyle";
import { useDeleteAssetMutation, useGetVehicleTypesQuery } from "./vehicleSlice";
import { useGetStatusesForClientQuery } from "features/vehicles/VehicleStatus/vehicleStatusSlice";
import { useGetVendorsByClaimsQuery } from "features/vendors/vendorSlice";

const VehicleTable = ({
    vehiclesData,
    columns,
    isLoading,
    assetToEdit,
    setAssetToEdit,
    assetToAdd,
    setAssetToAdd,
    updateAsset,
    addAsset,
    includeHistory,
    setIncludeHistory,
    onAdd,
    onEdit,
    mapExportData,
    savedFilters,
    page,
    setPage,
    limit,
    setLimit,
    onDropFilter,
    totalRows,
    searchVehicles,
    searchVal,
    filters,
    sortData,
    sortOptions,
    updateAllFilters,
    customExportChild = null, //getDataForExport,
    canSeeFlags
}) => {

    const isAccountManager = useSelector(selectIsAccountManager);
    const isAdmin = useSelector(selectIsAdmin);
    const userClientId = useSelector(selectUserClientId);

    let { data: subMarkets, error: smError, isLoading: isLoadingSm } = useGetSubMarketsQuery();
    let { data: lots, error: lotError, isLoading: isLoadingLots } = useGetLotsQuery();
    let { data: vehicleTypes, error: vehicleTypesError, isLoading: isLoadingVehicleTypes } = useGetVehicleTypesQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let [deleteAsset, { isLoading: isDeleting }] = useDeleteAssetMutation();
    let { data: clientVehicleStatuses, error: clientVehicleStatusesError, loading: clientVehicleStatusesLoading } = useGetStatusesForClientQuery({ clientId: filters['clientId']?.value ?? 0 })
    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetVendorsByClaimsQuery();
    vendors = vendors || [];

    const history = useHistory();
    const classes = vehiclePageStyles();
    const isMobile = useMediaQuery(mobileBreakpoint);
    const dispatch = useDispatch();
    const [showReportGrid, setShowReportGrid] = useState(false)
    const [showMap, setShowMap] = useState(true)
    const hasShowMap = isAuthorized(permissionProfiles.ASSETS.ASSETS_PAGE_SHOW_MAP);

    const { filtersSetFromOtherScreen } = useSelector(state => state.vehicleFilters);
    const [assetToDelete, setAssetToDelete] = useState(null);

    const filterConfig = [
        { type: fieldTypes.VALUE_LIST, size: 2, title: 'Submarket', key: 'subMarketIds', values: subMarkets, multiple: true },
        { type: fieldTypes.LOT, size: 2, title: 'Current Location', key: 'lotId', values: lots },
        { type: fieldTypes.LOT, size: 2, title: 'Domicile Lot', key: 'domicileLot', values: lots },
        { type: fieldTypes.DATERANGE, size: 3, title: 'Last In', key: 'formattedDateIn', values: [] },
        { type: fieldTypes.DATERANGE, size: 2.5, title: 'Last Out', key: 'formattedDateOut', values: [] },
        { type: fieldTypes.VEHICLE_TYPE, size: 2, title: 'Vehicle Type', key: 'vehicleTypeId', values: vehicleTypes },
        { type: fieldTypes.ASSET_STATUS, size: 2, title: 'Status', key: 'vehicleStatusId', values: clientVehicleStatuses, multiple: true },
        { type: fieldTypes.TEXT, title: 'Driver Carrier', key: 'driverCarrierId', values: vendors }
    ];

    if (isAdmin) {
        filterConfig.unshift({
            type: fieldTypes.TEXT,
            size: 2,
            title: 'Client',
            key: 'clientId',
            values: clients?.map(c => ({ id: c.id, name: c.name }))
        });
    }

    if (filters?.clientId?.value || userClientId) {
        let subClients = clients?.filter(c => ((Array.isArray(filters?.clientId?.value) ? filters?.clientId?.value : [filters?.clientId?.value]).includes(c.id)) || c.id == userClientId)?.filter(a => a.clientAccounts?.length > 1).flatMap(c => c.clientAccounts);
        if (subClients?.length == 1 && subClients?.[0]?.isDefault == true) {
            //do nothing
        }
        else if (subClients?.length > 0) {
            filterConfig.splice(1, 0, {
                type: fieldTypes.TEXT,
                size: 2,
                title: 'Sub Client',
                key: 'subClient',
                values: subClients
            });
        }
    }

    if (canSeeFlags) {
        filterConfig.push({
            type: fieldTypes.TEXT,
            size: 2,
            title: 'Flagged',
            key: 'flaggedStr',
            values: [{ id: 'Yes', name: 'Yes' }, { id: 'No', name: 'No' }]
        });
    }

    let filteredVehicles = FilterService.filter(filters, searchVal, vehiclesData, filterConfig); //vehiclesData

    useEffect(() => {
        if (savedFilters) {
            dispatch(mergeSavedFilter(savedFilters));
        }
    }, [savedFilters])

    useEffect(() => {
        let includeOffLot = undefined;
        filters?.vehicleStatusId?.value?.map(filterStatus => {
            let status = clientVehicleStatuses?.find(s => s.id == filterStatus);
            includeOffLot = status?.onLot;
        })
        if (includeOffLot != undefined) {
            setIncludeHistory(includeOffLot);
        }
    }, [filters])

    const confirmDelete = () => {
        setAssetToDelete(null);
        onDelete(assetToDelete);
    };

    const showDeletePopup = assetId => {
        let vehicle = vehiclesData.find(v => v.id === assetId);
        setAssetToDelete(vehicle)
    }

    const onDelete = async asset => {
        let result = await deleteAsset({ id: asset.id })

        if (result?.error) {
            NotificationProvider.error(`Asset VIN: ${asset.descriptor} cannot be deleted.`) //result.error.data.messages
        } else {
            NotificationProvider.success(`Successfully deleted Asset: ${asset.descriptor}`)
        }
    }

    const rowActions = (rowId) => ([
        {
            label: 'Details',
            component: <DetailsButton id={rowId} />
        },
        {
            icon: <IconEdit />,
            label: 'Edit',
            onClick: () => onEdit(rowId),
            profile: permissionProfiles.ASSETS.ASSETS_PAGE_EDIT
        },
        {
            icon: <IconDeleteBlue width={15} />,
            label: 'Delete',
            onClick: () => showDeletePopup(rowId),
            profile: permissionProfiles.ASSETS.ASSETS_PAGE_DELETE
        }
    ]);

    const onResetFilters = () => {
        dispatch(setFilters({
            clientId: { value: null },
            subClient: { value: null },
            lotId: { value: null },
            vehicleTypeId: { value: null },
            dateIn: { value: null },
            dateOut: { value: null },
            vehicleStatusId: { value: null },
        }));
    }

    const setFiltersSetFromOtherScreenFunc = (val) => {
        dispatch(setFiltersSetFromOtherScreen(val));
    }

    const onChangePagination = async (page, limit) => {
        setPage(page);
        setLimit(limit);
    }

    const totalPages = Math.ceil(totalRows / limit);

    const getRowClassName = (rowIndex) => {
        if (canSeeFlags && filteredVehicles?.[rowIndex]?.latestFlag) {
            switch (filteredVehicles?.[rowIndex]?.latestFlag?.sourceType) {
                case FlagEntityTypes.ACTIVITY:
                    return `${classes.flaggedRow} ${classes.flaggedRowActivity}`
                case FlagEntityTypes.TRANSPORT:
                    return `${classes.flaggedRow} ${classes.flaggedRowTransport}`
                default:
                    return `${classes.flaggedRow} ${classes.flaggedRowAsset}`
            }
        }
        return '';
    }

    return (
        <>
            <BasicModal
                open={assetToEdit}
                header={`Editing ${assetToEdit?.descriptor}`}
                wide={true}
                handleClose={() => setAssetToEdit(null)}
                component={<ParentEditAssetModal asset={assetToEdit} handleClose={() => setAssetToEdit(null)}
                    updateAsset={updateAsset} />}
            />
            <BasicModal
                open={assetToAdd}
                header={`Adding Asset`}
                wide={true}
                handleClose={() => setAssetToAdd(null)}
                component={<EditAssetPopup asset={assetToAdd} handleClose={() => setAssetToAdd(null)}
                    updateAsset={addAsset} />}
            />
            <BasicModal
                open={showReportGrid}
                header={`My reports`}
                wide={true}
                handleClose={() => setShowReportGrid(false)}
                component={<MyReportsGrid></MyReportsGrid>}
            />
            <ConfirmationPopup
                title={'Delete Asset'}
                onCancel={() => setAssetToDelete(null)}
                onConfirm={confirmDelete}
                open={!!assetToDelete}
                loading={isDeleting}
            >
                <div>{`Are you sure you want to delete Asset: ${assetToDelete?.descriptor}?`}</div>
            </ConfirmationPopup>
            <PageComponent>
                <Authorize profile={permissionProfiles.ASSETS.ASSETS_PAGE_VIEW_ASSET_TABLE}>
                    {isLoading && <LoadingSpinner loading className={classes.spinner} />}

                    <div className={classes.table}>
                        {
                            showMap && (
                                <VehiclesMap showMap={showMap}
                                    setShowMap={setShowMap}
                                    vehiclesData={filteredVehicles}
                                    onSearch={searchVehicles}
                                    searchVal={searchVal}
                                />
                            )
                        }

                        <DataTable
                            mapExportData={mapExportData}
                            listContainerStyle={showMap ? classes.withMarginTop : ""}
                            allowColumnCustomization
                            columns={columns}
                            customExportChild={customExportChild}
                            rows={orderBy(
                                filteredVehicles,
                                sortOptions.columnToSort,
                                sortOptions.sortDirection)
                            }
                            //rows={filteredVehicles}
                            //getDataForExport={getDataForExport}
                            getRowClassName={getRowClassName}
                            rowIdentifier='id'
                            onSort={sortData}
                            sortDirection={sortOptions.sortDirection}
                            columnToSort={sortOptions.columnToSort}
                            noItemsMessage={isLoading ? 'Loading vehicles...' : 'There are no vehicles matching this criteria'}
                            actions={rowActions}
                            showActionsInMenu
                            tableId={userPreferencesComponentIds.VEHICLES_PAGE}
                            dropDownsConfig={filterConfig}
                            filters={filters}
                            onDropFilter={onDropFilter}
                            updateAllFilters={updateAllFilters}
                            withFilterPanel={true}
                            onResetFilters={onResetFilters}
                            title={`Assets (${filteredVehicles?.length})`} //totalRows
                            actionButtons={(
                                <>
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <FormControlLabel
                                                control={<Checkbox checked={includeHistory}
                                                    onChange={(e, value) => setIncludeHistory(value)} />}
                                                label="Include Off Lot" />
                                        </Grid>
                                        <Authorize profile={permissionProfiles.ASSETS.ASSETS_PAGE_ADD_ASSET}>
                                            <Grid item>
                                                <Button variant="contained" color="primary"
                                                    className={classes.addAssetButton}
                                                    startIcon={<IconAdd />} onClick={onAdd}>Add Asset</Button>
                                            </Grid>
                                        </Authorize>
                                        <Authorize profile={permissionProfiles.ASSETS.ASSETS_PAGE_IMPORT_ASSETS}>
                                            <Grid item>
                                                <ImportAssets clients={clients} reloadAssets={updateAsset} />
                                            </Grid>
                                        </Authorize>
                                        {/* <Grid item>
                                                        <ExportButton fileLabel="inventory" data={filteredVehicles}
                                                            mapExportData={mapExportData} />
                                                    </Grid> */}
                                        <Grid item>
                                            <Authorize profile={permissionProfiles.ASSETS.ASSETS_PAGE_AUDIT}>
                                                <Button variant="contained" color="primary"
                                                    className={classes.addAssetButton}
                                                    onClick={() => history.push("/assets/audit")}>Audit</Button>
                                            </Authorize>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" color="primary"
                                                className={classes.addAssetButton}
                                                onClick={() => setShowReportGrid(true)}>My Reports</Button>
                                        </Grid>
                                        {
                                            (!showMap && hasShowMap) && (
                                                <VehiclesMapButton showMap={showMap} setShowMap={setShowMap}
                                                    style={classes.addAssetButton} />
                                            )
                                        }
                                    </Grid>
                                </>
                            )}
                            onSearch={showMap ? null : searchVehicles}
                            searchVal={showMap ? null : searchVal}
                            data={vehiclesData}
                            page={page}
                            onChangePagination={onChangePagination}
                            totalPages={totalPages}
                            limit={limit}
                            searchButton={false} //true
                            filtersSetFromOtherScreen={filtersSetFromOtherScreen}
                            setFiltersSetFromOtherScreen={setFiltersSetFromOtherScreenFunc}
                            bulkActions={isAuthorized(permissionProfiles.ASSETS.ASSETS_PAGE_FLAG_ADD_REMOVE) ? [
                                <FlagAsset
                                    addFlag={true}
                                    getFlagFromApi={false}
                                    allData={vehiclesData}
                                    entityType={FlagEntityTypes.ASSET}
                                />
                            ] : undefined}
                        />

                    </div>
                </Authorize>
            </PageComponent>
        </>
    );
};
//export default withUserPreferences(VehicleTable, userPreferencesComponentIds.VEHICLES_PAGE, userPreferencesTypes.FILTERS);
export default VehicleTable;
