import { baseApi } from 'app/baseApi';
import { baseQuery } from 'app/baseQuery';
import { apiTags } from 'app/store/apiTags';
import { getNoteInvalidateTag } from 'features/notes/notesSlice';
import { arrayToObject } from 'utils/object-util';
import { FlagEntityTypes, NoteTypeByFlagType } from './flagsConsts';

const apiPrefix = 'flag';

const getCacheKey = (args) => {
    return `${args.entityType}_${args.entityIds?.join(',')}`
}

/**
 * This is used to refresh the notes api for a specific entity
 * Provide the entity id and type (based on the NoteEntityType const) and get the cache key that needs to be invalidated using the invalidateTags
 * @param {*} entityId 
 * @param {*} entityType 
 * @returns 
 */

export const flagsApi = baseApi.injectEndpoints({
    reducerPath: 'flagsApi',
    baseQuery: baseQuery,
    tagTypes: [apiTags.FLAGS],
    endpoints: (builder) => ({
        getLatestFlag: builder.query({
            query: ({ entityIds, entityType }) => `${apiPrefix}/latest?${entityIds?.map(e => `entityIds=${e}`).join('&')}&entityType=${entityType}`,
            providesTags: (result, error, arg) => [apiTags.VEHICLE, { type: apiTags.FLAGS, id: getCacheKey(arg) }],
        }),
        getNoteForFlag: builder.query({
            query: (flagId) => ({
                url: `${apiPrefix}/note/${flagId}`
            }),
            providesTags: (result, error, arg) => [{ type: apiTags.FLAGS, id: getCacheKey(arg) }],
        }),
        flagAssets: builder.mutation({
            query: entities => ({
                url: `${apiPrefix}/flag`,
                method: "POST",
                body: {
                    entities
                }
            }),
            async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    baseApi.util.updateQueryData('getVehicles', { includeHistory: false }, (vehicles) => {
                        updateLocalCache(id, patch, FlagEntityTypes.ASSET, vehicles);
                    })
                )
                const patchResultActivity = dispatch(
                    baseApi.util.updateQueryData('getMechanicalActivities', undefined, (activities) => {
                        updateLocalCache(id, patch, FlagEntityTypes.ACTIVITY, activities);
                    })
                )
                const patchResultLoads = dispatch(
                    baseApi.util.updateQueryData('getLoadsGrouped', { lmStatus: null, archived: false }, (groups) => {
                        updateLocalCache(id, patch, FlagEntityTypes.TRANSPORT, groups, 'groupId');
                    })
                )
                const patchResultReservation = dispatch(
                    baseApi.util.updateQueryData('getAllReservations', undefined, (reservation) => {
                        updateLocalCache(id, patch, FlagEntityTypes.RESERVATION, reservation);
                    })
                )
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                    patchResultActivity.undo();
                    patchResultLoads.undo();
                    patchResultReservation.undo();
                }
            },
            invalidatesTags: (result, error, arg) => [
                ...(arg?.map(a => getNoteInvalidateTag(a.id, NoteTypeByFlagType[a.entityType])) ?? []),
                apiTags.VEHICLE,
                { type: apiTags.FLAGS, id: getCacheKey(arg) }
            ],
        }),
    })

});

const updateLocalCache = (id, patch, entityType, entities, identifierField = 'id') => {
    const updatedIds = Object.values(patch ?? {})?.filter(v => v.entityType == entityType)?.map(v => v[identifierField]);
    if (!!updatedIds?.length) {
        const patchedLookup = arrayToObject(Object.values(patch ?? {}));
        entities.map(v => {
            if (updatedIds.includes(v.id)) {
                 v.flaggedStr=(v.latestFlag && !patchedLookup?.[v.id]?.addFlag) ? 'No':'Yes' 
                v.latestFlag = (v.latestFlag && !patchedLookup?.[v.id]?.addFlag) ? null :
                    {
                        id: 0,
                        entityType: patchedLookup?.[v.id]?.entityType,
                        noteLocal: `Flag Added: ${patchedLookup?.[v.id]?.note}`
                    }
            }
        })
    }
}
export const {
    useGetLatestFlagQuery,
    useGetNoteForFlagQuery,
    useFlagAssetsMutation,
} = flagsApi;

