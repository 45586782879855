import { Button } from '@material-ui/core';
import { useGetreservationByIdQuery } from 'features/Reservation/reservationSlice';
import { ReservationView } from 'features/Reservation/ReservationView/ReservationView';
import { IconReservations } from 'icons';
import { useState } from 'react';
import { reservationButtonStyle } from './ReservationButtonStyle';

export default function ReservationButton({ reservationId }) {
    const classes = reservationButtonStyle();

    const [open, setOpen] = useState(false);

    let { data: reservations, error: reservationError, isLoading: isLoadingReservation } = useGetreservationByIdQuery(reservationId, { skip: !reservationId || !open });
    reservations = reservations || [];
    var reservation = reservations?.[0];

    return (
        <div>
            {reservationId && <Button onClick={() => setOpen(true)}>
                <IconReservations />
            </Button>}
            {open && reservation && <ReservationView
                reservation={reservation}
                onClose={() => setOpen(false)}
            />}
        </div >
    );
}
