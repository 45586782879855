import { Grid } from '@material-ui/core';
import { CardsStyle } from './CardsStyle';
import LoadingSpinner from 'components/LoadingSpinner';
import SvgIconQRCode from 'icons/IconQRCode';
import { useGetClientsQuery } from 'features/clients/clientSlice';
import ListComponent from './Lists/ListComponent';
import { IconDriver, IconLot, IconVehicle } from 'icons';
import PocInfo from './POC/PocInfo';
import EditSites from './Lists/EditListsComponents/EditSites';
import EditVehicles from './Lists/EditListsComponents/EditVehicles';
import EditDrivers from './Lists/EditListsComponents/EditDrivers';
import SiteListItem from './Lists/ListItems/SiteListItem';
import VehicleListItem from './Lists/ListItems/VehicleListItem';
import { useGetDriversQuery } from 'features/drivers/driversSlice';
import Authorize from 'components/Authorize';
import { permissionProfiles } from 'components/Authorize/permissionProfiles';
import { useGetAllVendorsQuery } from 'features/vendors/vendorSlice';

const CarrierAccessCard = ({ access }) => {

    const classes = CardsStyle()
    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetAllVendorsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    let { data: drivers, error: driverError, isLoading: isLoadingDrivers } = useGetDriversQuery();

    drivers = drivers?.filter(d => d.vendorId == access.carrierId);

    const vendorsLookup = vendors?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    const clientLookup = clients?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    access = {
        ...access,
        clientName: clientLookup?.[access?.clientId]?.name,
        carrierName: vendorsLookup?.[access?.carrierId]?.name,
        scac: vendorsLookup?.[access?.carrierId]?.scac,
        drivers: drivers?.filter(l => l?.vendorId == access?.carrierId),
    }

    return (
        <>
            <div className={classes.loadBox}>
                <LoadingSpinner loading={isLoadingVendors || isLoadingClients || isLoadingDrivers} />
                <Grid container direction='row' className={classes.detailsWrapper}>
                    <Grid item container direction='row' className={classes.cardItemIcon}>
                        <div item className={classes.title}><SvgIconQRCode /></div>
                    </Grid>
                    <Grid item container direction='column' className={classes.cardItem} >
                        <Authorize profile={permissionProfiles.ACCESS.CLIENT_FIELDS}>
                            <Grid item className={classes.data}>
                                <label className={classes.title}>Client: </label>
                                <label className={classes.data}>{access?.clientName}</label>
                            </Grid>
                        </Authorize>
                        <Grid item className={classes.data}>
                            <label className={classes.title}>Carrier: </label>
                            <label className={classes.data}>{access?.carrierName}</label>
                        </Grid>
                        <Grid item className={classes.data}>
                            <label className={classes.title}>SCAC: </label>
                            <label className={classes.data}>{access?.scac}</label>
                        </Grid>
                    </Grid>

                    <PocInfo access={access} />

                </Grid>
                <Grid container direction='row' spacing={2} className={classes.lists}>
                    <Grid item>
                        <ListComponent
                            access={access}
                            list={access?.lots}
                            listTitle='Sites'
                            listItem={(lot) => <SiteListItem access={access} lot={lot} />}
                            addComponent={(access, open, onClose) => <EditSites access={access} open={open} onClose={onClose} />}
                        />
                    </Grid>
                    <Grid item>
                        <ListComponent
                            access={access}
                            list={access?.vehicles}
                            listTitle='Vehicles'
                            listItem={(vehicle) => <VehicleListItem access={access} vehicle={vehicle} />}
                            addComponent={(access, open, onClose) => <EditVehicles access={access} open={open} onClose={onClose} />}
                        />
                    </Grid>
                    <Grid item>
                        <ListComponent
                            access={access}
                            list={access?.drivers}
                            listTitle='Drivers'
                            buttonText={'Add'}
                            listItem={(d) => <><IconDriver className={classes.listItemIcon} />{`${d?.firstName} ${d?.lastName}`}</>}
                            addComponent={(access, open, onClose) => <EditDrivers access={access} open={open} onClose={onClose} />}
                        />
                    </Grid>
                </Grid>

            </div >
        </>
    );
}

export default CarrierAccessCard



