import { apiTags } from 'app/store/apiTags';
import { baseQuery } from "../../app/baseQuery";
import { baseApi } from 'app/baseApi';

const apiPrefix = 'taxrate';

export const taxRatesApi = baseApi.injectEndpoints({
    reducerPath: 'taxRatesApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getTaxRates: builder.query({
            query: () => `${apiPrefix}`,
            providesTags: [apiTags.TAX_RATE]
        }),
        addTaxRate: builder.mutation({
            query: (taxRate) => ({
                url: `${apiPrefix}`,
                method: "POST",
                body: taxRate
            }),
            invalidatesTags: [apiTags.TAX_RATE],
        }),
        updateTaxRate: builder.mutation({
            query: (taxRate) => ({
                url: `${apiPrefix}`,
                method: "PUT",
                body: taxRate
            }),
            invalidatesTags: [apiTags.TAX_RATE]
        })
    }),
});

export const {
    useGetTaxRatesQuery,
    useAddTaxRateMutation,
    useUpdateTaxRateMutation,
} = taxRatesApi;

