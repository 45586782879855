import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { CallbackComponent } from "redux-oidc";
import { useSessionstorageState } from "rooks";
import { history } from "../../app/store/store";
import userManager from "../../app/userManager";
import LoginService from "../../services/loginService";

const useStyles = makeStyles({
    spinner: {
        position: 'absolute',
        top: '50%',
        left: 'calc(50% + 100px)',
        zIndex: '100'
    }
});

function CallbackPage() {
    const classes = useStyles();
    const [redirectPath, setRedirectPath, removeRedirectPath] = useSessionstorageState("redirectPath", "/lots")

    return (
        <CallbackComponent
            userManager={userManager}
            successCallback={user => {
                let actualRedirectPath = redirectPath ?? "/"; 
                console.log('redirecting...', actualRedirectPath);

                if (actualRedirectPath === "/") {
                    actualRedirectPath = "/lots";
                }

                removeRedirectPath();

                try {
                    LoginService.logLogin();
                } catch (err) {
                }

                history.push(actualRedirectPath);
            }}
            errorCallback={error => {
                history.push("/");
            }}
        >
            <CircularProgress className={classes.spinner} />
        </CallbackComponent>
    );
}

export default CallbackPage;
