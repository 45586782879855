import { modes } from "../modes";
import { roles } from "../roles";
import { permissions } from "../permissions/permissions";

export const taxRatePermissionProfile = {
    TAX_VIEW: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.TAX_RATE.FULL, permissions.TAX_RATE.VIEW]
    },
    TAX_FULL: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.TAX_RATE.FULL]
    }
}