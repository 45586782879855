import { IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React from 'react';
import DropdownFilter from './DropdownFilter';

const useStyles = makeStyles((theme) => ({
    input: {
        color: theme.palette.common.darkText,
        backgroundColor: theme.palette.background.default,
        borderRadius: '5px',
        '&.MuiOutlinedInput-adornedEnd': {
            backgroundColor: theme.palette.background.default,
        },
        '&.Mui-disabled': {
            opacity: 0.5,
        },
        '& fieldset': {
            border: 'none'
        }
    },
    invalidInput: {
        borderBlockColor: theme.palette.error.dark
    },
    colorInput: {
        backgroundColor: theme.palette.action.focus,
    },
    inputIcon: {
        cursor: 'pointer'
    },
    label: {
        color: theme.palette.text.secondary,
        '&.Mui-disabled': {
            color: theme.palette.text.secondary,
            opacity: 0.7,
        }
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: 'green !important'
    }
}))

const CustomInput = ({
    id,
    label,
    value,
    onChange,
    elementType,
    values,
    icon,
    iconAction,
    valid = true,
    touched,
    disabled,
    showEmpty,
    required,
    classStyles,
    className,
    colorInput = false,
    numberOnly,
    shrink,
    step,
    minValue = null,
    onClick = () => { },
    renderOption,
    placeholder,
    variant = 'outlined',
    combinedData,
    inputType = undefined
}) => {

    const classes = useStyles();

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleChange = (event) => {
        onChange(event.target.value, id);
    };

    const onIconClick = () => {
        iconAction(id);
    };

    let inputElement = null;

    let shrinkLabel = shrink || value || (numberOnly && value !== null)

    switch (elementType) {
        case ('input'):
            inputElement = <TextField inputProps={{ className: `${className} ${classes.input} ${!valid && classes.invalidInput} ${colorInput ? classes.colorInput : ''}`, type: numberOnly ? 'number' : inputType == 'password' ? showPassword ? 'text' : 'password' : undefined, step: step, min: minValue }}
                fullWidth variant={variant} InputLabelProps={{ className: classes.label, shrink: shrinkLabel }}
                error={!valid && touched} disabled={disabled} value={value}
                id={id} label={label} required={required} onChange={(event) => handleChange(event)}
                InputProps={{
                    notchedOutline: classes.notchedOutline,
                    className: classes.input,
                    endAdornment: (
                        icon ?
                            <InputAdornment position="end" className={classes.input}
                                onClick={() => onIconClick()}>
                                {icon}
                            </InputAdornment> :
                            inputType == 'password' && <IconButton
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                    ),
                }}
                placeholder={placeholder}
            />
            break;
        case ('textarea'):
            inputElement = <TextField required={required} inputProps={{ className: classes.input }} InputLabelProps={{ className: classes.label, shrink: shrinkLabel }}
                className={classes.input} fullWidth variant={variant} id={id} label={label}
                value={value} onChange={(event) => handleChange(event)} multiline rows={4}
                error={!valid && touched}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" className={classes.inputIcon}
                            onClick={() => onIconClick()}>
                            {icon ?
                                icon
                                : null
                            }
                        </InputAdornment>
                    ),
                }} />
            break;
        case ('dropdown'):
            inputElement = <DropdownFilter title={label} identifier={id} value={value} values={values} required={required} classStyles={classStyles} combinedData={combinedData}
                onSelect={onChange} valid={valid} shrink={shrinkLabel} touched={touched} disabled={disabled} variant={variant} showEmpty={showEmpty} colorInput={colorInput} renderOption={renderOption} />
            break;
        default:
            inputElement = <TextField className={`${classes.input} ${className}`} fullWidth variant={variant} InputLabelProps={{ className: classes.label, shrink: shrinkLabel }}
                id={id} label={label} disabled={disabled} defaultValue={value} value={value} required={required} onChange={(event) => handleChange(event)} placeholder={placeholder} />
    }

    return (
        <div style={{ width: '100%' }} onClick={() => onClick()}>
            {inputElement}
        </div>

    )
}

export default CustomInput
