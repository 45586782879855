import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper } from "@material-ui/core"
import { IconAccessCode } from "icons";
import { VehicleAccessStyle } from "./VehicleAccessStyle";
import { useGetAccessesByGroupTypeQuery, useUpdateVehiclesMutation } from "features/access/accessSlice";
import { useGetClientsQuery } from "features/clients/clientSlice";
import { AccessGroupTypes } from "features/access/accessConsts";
import NotificationProvider from "components/NotificationProvider";
import { useState } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetAllVendorsQuery } from "features/vendors/vendorSlice";

export const AccessList = ({ vehicleId, openDialog, onCloseDialog, clientId }) => {
    const classes = VehicleAccessStyle();

    let { data: clientAccessData, error: ClientAccessError, isLoading: isLoadingClientAccess } = useGetAccessesByGroupTypeQuery(AccessGroupTypes.CLIENT);
    let { data: carrierAccessData, error: CarrierAccessError, isLoading: isLoadingCarrierAccess } = useGetAccessesByGroupTypeQuery(AccessGroupTypes.CARRIER);
    let { data: vendors, error: vendorsError, isLoading: isLoadingVendors } = useGetAllVendorsQuery();
    let { data: clients, error: clientError, isLoading: isLoadingClients } = useGetClientsQuery(null);
    
    const [updateVehicles, { isLoading }] = useUpdateVehiclesMutation();
    clientAccessData = clientAccessData || [];
    carrierAccessData = carrierAccessData || [];

    const [selectedAccess, setSelectedAccess] = useState(null)

    let allAccessData = [...clientAccessData, ...carrierAccessData]?.filter(a => a.clientId == clientId);

    const vendorsLookup = vendors?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});


    const clientLookup = clients?.reduce(function (map, obj) {
        map[obj.id] = obj;
        return map;
    }, {});

    allAccessData = allAccessData?.map(access => ({
        ...access,
        clientName: clientLookup?.[access?.clientId]?.name,
        carrierName: vendorsLookup?.[access?.carrierId]?.name,
    }))?.filter(x => !x?.vehicleIds?.includes(+vehicleId));

    const onSave = async () => {
        let body = { accessGroupId: selectedAccess?.id, vehicleIds: [...selectedAccess?.vehicleIds, +vehicleId] };
        let res = await updateVehicles(body);
        if (res && !res.error) {
            NotificationProvider.success("Asset updated successfully");
        } else {
            NotificationProvider.error("Failed to save the access group assets, please try again");
        }
        onClose();
    }

    const onClose = () => {
        setSelectedAccess(null);
        onCloseDialog();
    }


    return (
        <Dialog maxWidth='md' fullWidth open={openDialog} onClose={onClose} aria-labelledby='dialog-title'>
            <DialogTitle id={'dialog-title'} >
                Add Asset to Access Group
            </DialogTitle>
            <DialogContent>
                {
                    allAccessData?.map((item, index) => {
                        return <div key={item}>
                            <Paper className={`${classes.listItem} ${item?.id == selectedAccess?.id ? classes.listItemSelected : ""}`} onClick={() => setSelectedAccess(item)}>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item xs={1}>
                                        <IconAccessCode className={classes.listItemIcon} />
                                    </Grid>
                                    <Grid xs={3} item container spacing={1} >
                                        <Grid item><b>Client:</b></Grid>
                                        <Grid item>{item?.clientName}</Grid>
                                    </Grid>
                                    <Grid xs={3} item container spacing={1}>
                                        <Grid item><b>POC Name:</b></Grid>
                                        <Grid item>{item?.pocName}</Grid>
                                    </Grid>
                                    <Grid xs={3} item container spacing={1} >
                                        {item?.groupType === AccessGroupTypes.CARRIER &&
                                            <>
                                                <Grid item><b>Carrier:</b></Grid>
                                                <Grid item>{item?.carrierName}</Grid>
                                            </>}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </div>
                    })
                }
                <LoadingSpinner loading={isLoading} />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button className={classes.button} variant='outlined' color='primary' onClick={onClose}>
                    Cancel
                </Button>
                <Button className={classes.button} variant='contained' color='primary' onClick={onSave} disabled={isLoading || !selectedAccess}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}