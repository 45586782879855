import { modes } from "../modes";
import { permissions } from "../permissions/permissions";
import { roles } from "../roles";

export const dashboardPermissionProfiles = {
    WASH_SLA: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.WASH_DASHBOARD_VIEW, permissions.ISSUE_TRACKER.ISSUE_TRACKER_EDIT],
    },
    KPI_DASHBOARD_VIEW: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.KPI_DASHBOARD_VIEW],
    },
    KPI_DASHBOARD_EDIT_SIZING: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.KPI_DASHBOARD_EDIT_SIZING],
    },
    KPI_DASHBOARD_VIEW_TAB_OVERVIEW: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.KPI_DASHBOARD_VIEW_TAB_OVERVIEW],
    },
    KPI_DASHBOARD_VIEW_TAB_INSPECTION: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.KPI_DASHBOARD_VIEW_TAB_INSPECTION],
    },
    DISPATCH_DASHBOARD_VIEW: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.DISPATCH_DASHBOARD_VIEW],
    },
    WASH_DASHBOARD_VIEW: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.WASH_DASHBOARD_VIEW],
    },
    WASH_DASHBOARD_COMPLIANCE_GRAPH: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.WASH_DASHBOARD_COMPLIANCE_GRAPH],
    },
    MECHANICAL_DASHBOARD_VIEW: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_VIEW],
    },
    VIEW_REPORTS_SUB_MENU: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN, roles.MECHANIC, roles.ACCOUNT_MANAGER]
    },
    ONE_ON_ONE: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER]
    },
    CREATE_INSPECTION: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN]
    },
    MECHANICAL_VIEW: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.ADMIN],
        [modes.READONLY]: [roles.CLIENT, roles.MECHANIC]
    },
    WORKORDER_SLA: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.ACCOUNT_MANAGER]
    },
    WORKORDER_CLIENT_FILTER: {
        [modes.EDIT]: [roles.OWNER, roles.ADMIN, roles.MECHANIC, roles.ACCOUNT_MANAGER]
    },
    CREATE_WORK_ORDER: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_CREATE_WO],
    },
    MECHANICAL_DASHBOARD_IMPORT_ACTIVITIES: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.MECHANIC],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_IMPORT_ACTIVITIES],
    },
    MECHANICAL_DASHBOARD_SUB_TABLES_CARDS: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_SUB_TABLES_CARDS],
    },
    MECHANICAL_DASHBOARD_CAN_SEE_FLAG: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_CAN_SEE_FLAG],
    },
    MECHANICAL_DASHBOARD_EDIT_FLAG: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_EDIT_FLAG],
    },
    MECHANICAL_DASHBOARD_VIEW_NOTE: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_VIEW_NOTE],
    },
    MECHANICAL_DASHBOARD_EDIT_NOTE: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_EDIT_NOTE],
    },
    MECHANICAL_DASHBOARD_EDIT_ACTIVITIES: {
        [modes.EDIT]: [roles.OWNER],
        [modes.EDIT_PERMISSION]: [permissions.DASHBOARD.MECHANICAL_DASHBOARD_EDIT_ACTIVITIES],
    },
    SHOW_REPORT_SUB_MENU: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER, roles.SUPERVISOR],
        [modes.EDIT_PERMISSION]: [
            permissions.ISSUE_TRACKER.ISSUE_TRACKER_AVAILABLE,
            permissions.ISSUE_TRACKER.ISSUE_TRACKER_EDIT],
    },
    SHOW_SLA_SUB_MENU: {
        [modes.EDIT]: [roles.OWNER, roles.ACCOUNT_MANAGER],
        [modes.EDIT_PERMISSION]: [permissions.REPORTS.STORAGE, permissions.REPORTING.VIEW_COST_CODES]
    }
}