import { createSlice } from "@reduxjs/toolkit";

export const taxRateFilterSlice = createSlice({
    name: 'taxRateFilters',
    initialState: {
        filters: {
            lotId: { value: null },
        },
    },
    reducers: {
        setFilters: (state, action) => {
            state.filters = action.payload;
        }
    },
});

export const { setFilters } = taxRateFilterSlice.actions;
export default taxRateFilterSlice.reducer;
