import { makeStyles } from "@material-ui/core";

export const reservationTableStyle = makeStyles((theme) => ({
    flaggedRow: {
        position: 'realtive',
        "&::before": {
            content: "''",
            position: "absolute",
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            background: '#ff940033',
        },
    }
}))