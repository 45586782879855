import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import EmptyPlaceholder from 'components/EmptyPlaceholder/EmptyPlaceholder';
import { useState } from 'react';
import { ImportResultsDialogStyle } from './ImportResultsDialogStyle';
import ImportItem from './ImportItem';

function ImportResultsDialog({ importedRows, failedRows, skippedRows, accessCodeGenerated, error, open, setOpen, loading, reloadData, dataType, getDisplayFields  }) {
    const classes = ImportResultsDialogStyle();
    const [showErrors, setShowErrors] = useState(true);
    const [showSkipped, setShowSkipped] = useState(true);
    const [showSuccess, setShowSuccess] = useState(true);

    const handleClose = () => {
        reloadData();
        setOpen && setOpen(false);
    };

    const handleToggle = (toggleSetter) => {
        toggleSetter(prev => !prev);
    };

    // Dynamically get data type-specific results
    const importedItems = error ? [] : importedRows;
    const failedItems = error ? [] : failedRows;
    const skippedItems = error ? [] : skippedRows;
    const accessCodeGeneratedItems = error ? [] : accessCodeGenerated;

    const stats = [
        { title: `Imported ${dataType}`, count: importedItems?.length, onClick: () => handleToggle(setShowSuccess), selected: showSuccess },
        { title: `Skipped ${dataType}`, count: skippedItems?.length, onClick: () => handleToggle(setShowSkipped), selected: showSkipped },
        { title: `Failed ${dataType}`, count: failedItems?.length, onClick: () => handleToggle(setShowErrors), selected: showErrors }
    ];

    const resultsToShow = [
        ...(showSuccess ? importedItems ?? [] : []),
        ...(showSkipped ? skippedItems ?? [] : []),
        ...(showErrors ? failedItems ?? [] : [])
    ];

    return (
        <Dialog open={open} onClose={() => { }} aria-labelledby="form-dialog-title" maxWidth={'md'} fullWidth>
            <DialogTitle id="import-dialog-title">{`Import Results`}</DialogTitle>
            <DialogContent className={classes.content}>
                {loading ? (
                    <div>
                        <div className={classes.progressText}>Processing {dataType.toLowerCase()}, please wait</div>
                        <LinearProgress />
                    </div>
                ) : (
                    <>
                        <div className={classes.stats}>
                            {stats.map((stat, index) => (
                                <div
                                    key={index}
                                    onClick={stat.onClick}
                                    className={`${classes.importCountWrapper} ${stat.selected ? classes.selected : ''}`}
                                >
                                    <div className={classes.count}>{stat.count}</div>
                                    <div className={classes.text}>{stat.title}</div>
                                </div>
                            ))}
                        </div>
                        {error?.data && Array.isArray(error?.data) ? (
                            <div>
                                {error.data.map((err, i) => (
                                    <div className={classes.error} key={i}>
                                        <WarningIcon />{err}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className={classes.items}>
                                {resultsToShow.length ? (
                                    resultsToShow.map((item, i) => (
                                        <ImportItem
                                            item={item}
                                            accessCodeGeneratedItems={accessCodeGeneratedItems}
                                            key={i}
                                            displayFields={getDisplayFields(item)}
                                        />
                                    ))
                                ) : (
                                    <EmptyPlaceholder />
                                )}
                            </div>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                {!loading && <Button onClick={handleClose} color="primary">Done</Button>}
            </DialogActions>
        </Dialog>
    );
}


export default ImportResultsDialog;