import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { AccessDialogStyle } from './AccessDialogStyle';
import { AccessDialogSteps } from '../accessConsts';
import { useEffect, useState } from 'react';
import TimelineComponent from 'components/Timeline/Timeline';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToEdit } from '../accessStateSlice'
import { useUpdateAccessGroupMutation } from '../accessSlice';
import LoadingSpinner from 'components/LoadingSpinner';
import NotificationProvider from 'components/NotificationProvider';
import usePrompt from 'hooks/usePrompt';
import { selectIsClient } from 'features/user/userSlice';
import SendQRCodeTo from './SendQRCodeTo/SendQRCodeTo';

const AccessDialog = ({ open, handleClose, access, isEdit = false }) => {
    const classes = AccessDialogStyle()

    const [updateAccessGroup, { isLoading }] = useUpdateAccessGroupMutation();
    const { triggerPrompt } = usePrompt();

    const { accessToEdit } = useSelector(state => state.accessState);
    const dispatch = useDispatch();
    const isClient = useSelector(selectIsClient);

    const [step, setStep] = useState(isEdit ? 2 : 1);
    const [openSendQR, setOpenSendQR] = useState(false);

    useEffect(() => {
        setStep(isEdit ? 2 : 1)
        dispatch(setAccessToEdit(access));
    }, [open])

    const onCloseDialog = () => {
        handleClose();
        setStep(isEdit ? 2 : 1);
        dispatch(setAccessToEdit({}));
    }

    const onConfirm = async () => {
        let access = accessToEdit;
        access = { ...access, ["lotIds"]: access?.lots?.map(l => l.id), ["driverIds"]: access?.drivers?.map(d => d.id), ["vehicleIds"]: access?.vehicles?.map(v => v.id) };
        let res = await updateAccessGroup(access);
        if (res.error) {
            NotificationProvider.error("Failed to save the access group");
        } else {
            triggerPrompt({
                title: "Create Access Group",
                content: <div>
                    <div><b>The access group was created successfully.</b></div><br />
                    <div>{res?.data?.isSuccessGenerate ? 'Successfully generated new access codes.' : 'Access codes were not generated'}</div>
                    {res.data?.errorMessages?.map(msg => <div>{msg}</div>)}
                </div>,
                onCancel: async () => {
                    onCloseDialog();
                },
            });
        }
    }

    const onClickSave = () => {
        if (accessToEdit?.generateCode) {
            dispatch(setAccessToEdit({...accessToEdit, 
                                        "sendEmail": accessToEdit?.pocEmail ? true : false, 
                                        "sendSMS": accessToEdit?.pocPhone ? true : false}))
            setOpenSendQR(true);
        } else {
            onConfirm();
        }
    }

    return (
        <>
            <Dialog maxWidth='md' fullWidth open={open} onClose={onCloseDialog} aria-labelledby='dialog-title'>
                <DialogTitle id={'dialog-title'} >
                    {AccessDialogSteps[step]?.title(accessToEdit?.groupType)}
                </DialogTitle>
                <DialogContent>
                    {AccessDialogSteps[step]?.component()}
                    <div className={classes.timeline}><TimelineComponent numberOfSteps={isEdit ? 4 : 5} currentStep={isEdit ? step - 1 : step} /></div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button disabled={step === (isEdit ? 2 : 1)} className={classes.button} variant='contained' color='primary' onClick={() => setStep(step - 1)}>
                        Previous Step
                    </Button>
                    <Button className={classes.button} variant='outlined' color='primary' onClick={onCloseDialog}>
                        Cancel
                    </Button>
                    {step !== 5 ?
                        <Button className={classes.button} variant='contained' color='primary'
                            onClick={() => setStep(step + 1)}
                            disabled={!AccessDialogSteps[step]?.isValid(accessToEdit, isClient)}>
                            Next Step
                        </Button> :
                        <Button className={classes.button} variant='contained' color='primary' onClick={onClickSave} disabled={isLoading}>
                            {accessToEdit?.generateCode ? `Save and Generate` : `Save`}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
            <SendQRCodeTo
                open={openSendQR}
                handleClose={() => setOpenSendQR(false)}
                accessToEdit={accessToEdit} 
                setAccessToEdit={(access) => dispatch(setAccessToEdit(access))}
                onConfirm={onConfirm}
            />
            <LoadingSpinner loading={isLoading} />
        </>
    );
}

export default AccessDialog;