import { makeStyles } from "@material-ui/core";

export const ImportResultsDialogStyle = makeStyles((theme) => ({
    stats: {
        display: 'flex',
        marginBottom: '1em',
        justifyContent: 'center',
        gap:'10px'
    },

    importCountWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1px solid lightgrey',
        padding: '0.5em',
        borderRadius: '5px',
        cursor: 'pointer',
        minWidth: '150px'
    },
    selected: {
        background: theme.palette.primary.main,
        color: 'white'
    },
    count: {
        fontSize: '2em'
    },
    itemWrapper: {
        paddingBottom: '0.5em 0',
        border: '1px solid lightgrey',
        marginBottom: '1em'
    },
    content: {
        display: 'flex',
        flexDirection: 'column'
    },
    items: {
        flex: '1',
        overflowY: 'auto',
    },

    itemHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        background: 'lightgrey',
        padding: '0.5em',
        cursor: 'pointer',
        alignItems: 'center'
    },
    itemTitle: {
        fontWeight: 'bold'
    },
    collapseWrapper: {
        padding: '0.5em'
    },
    error: {
        background: 'rgba(255, 0, 0, 0.2)',
        padding: '0.5em',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0.5em',
        '& path': {
            'fill': 'rgba(255, 0, 0, 0.6);'
        }
    },
    progressText: {
        marginBottom: '0.5em'
    }
}));